import React, {Component} from 'react';
import {Container, Row, Col, Collapse} from 'reactstrap'
import Calendar from 'components/Calendar'
import moment from 'moment'
import {graphql, StaticQuery} from "gatsby";

class Aarhus extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showCalendar: true,
            collapses: {
                collapseA: true,
                collapseB: false,
                collapseC: false,
                collapseD: false,
            },
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({showCalendar: true})
        }, 1000)
    }

    loadEvents = (events) => {
        let futureEvents = [];

        for (var key in events) {
            if (events[key].node.siteId === 'aarhus') {
                for (var eventKey in events[key].node.items) {
                    if (moment().diff(events[key].node.items[eventKey].start, 'hours') <= 0 && events[key].node.items[eventKey].active) {
                        futureEvents.push(events[key].node.items[eventKey]);
                    }
                }
            }
        }

        return (
            <Calendar
                defaultDate={moment('2019-11-01', 'YYYY-MM-DD')}
                visibleRange={{
                    start: moment('2019-11-01', 'YYYY-MM-DD'),
                    end: moment('2019-12-07', 'YYYY-MM-DD')
                }}
                validRange={{
                    start: moment('2019-11-01', 'YYYY-MM-DD'),
                    end: moment('2019-12-07', 'YYYY-MM-DD')
                }}
                events={futureEvents}
            />
        )

    };

    toggleAccordion = (id, e) => {
        let collapses = {...this.state.collapses};
        const collapse = id;

        for (var key in collapses) {
            if (collapses.hasOwnProperty(key) && collapse !== key) {
                collapses[key] = false;
            }
        }

        collapses[collapse] = true;

        this.setState({collapses});
    }

    eventClick = (e) => {
        if (e.url) {
            window.open(e.url, "_blank");
            return false;
        }
    }

    onEntered = () => {
        this.setState({
            status: "Entered"
        })
    }

    eventRender = (event, element, view) => {
        if (event.className.includes('sold-out')) {
            element.addClass('isDisabled')
            return element;
        }
    }

    render() {
        const {phase, language} = this.props;
        const {showCalendar} = this.state;

        const collapses = {...this.state.collapses};

        return (
            <>
                <div className="section-intro">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                {phase === 'registration' && language === 'en' &&
                                <h1 className="text-center">THE MORMONS ARE COMING TO AARHUS</h1>
                                }

                                {phase === 'registration' && language === 'da' &&
                                <h1 className="text-center">MORMONERNE KOMMER TIL AARHUS</h1>
                                }

                                {phase === 'open' && language === 'en' &&
                                <h1>HOW TO BUY YOUR TICKETS FOR AARHUS</h1>
                                }
                                {phase === 'open' && language === 'da' &&
                                <h1>Hvordan køber jeg billetter til Aarhus</h1>
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>

                {phase === 'open' &&
                <>
                    <div className="section-intro section-sign-up">
                        <Container>
                            <Row>
                                <Col xs={12}>

                                    <div className="section-intro__wrapper">
                                        <div className="section-intro__wrapper-header">
                                            {language === 'en' &&
                                            <h2>TICKET TIPS</h2>
                                            }

                                            {language === 'da' &&
                                            <h2>Tips og tricks om billetter</h2>
                                            }
                                        </div>
                                        {language === 'en' &&
                                        <div className="section-intro__wrapper-body">
                                            <h3>WE KNOW IT CAN BE HARD SOMETIMES FINDING THE RIGHT TICKET SO HERE ARE A
                                                FEW HANDY TIPS:</h3>

                                            <ul>
                                                <li>EXTRA WEEK now on sale</li>

                                                <li>SIGN-UP <a href="#footer">HERE</a> to receive latest news including
                                                    priority seat
                                                    releases
                                                </li>
                                                <li>Can’t find the right ticket. Check out the performances on weekdays,
                                                    you might be able to find them there.
                                                </li>
                                            </ul>
                                        </div>
                                        }
                                        {language === 'da' &&
                                        <div className="section-intro__wrapper-body">
                                            <h3>Vi ved, det kan være svært nogen gange at finde de helt rigtige
                                                billetter, så her kommer der et par tricks:</h3>

                                            <ul>
                                                <li>Ekstra forestillinger i salg nu</li>
                                                <li>SKRIV DIG OP på mailinglisten, og få de seneste nyheder om
                                                    forestillingen, samt hvis vi frigiver billetter til udsolgte
                                                    forestillinger
                                                </li>
                                                <li>Kan du ikke finde den rigtige plads i salen? Tjek forestillingerne,
                                                    der spiller på hverdage, her er det typisk nemmere at finde
                                                    billetter
                                                </li>

                                            </ul>
                                        </div>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="section-accordion" id="section-accordion">
                        <Container>
                            <Row>
                                <Col xs={12}>
                                    <div className="section-accordion__wrap">
                                        <button
                                            className={`big section-accordion__wrap-btn accordion-btn ${collapses.collapseA === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseA', e)}>
                                            ONLINE BOOKING
                                        </button>
                                        <Collapse
                                            isOpen={collapses.collapseA}
                                            onEntering={this.onEntering}
                                            onEntered={this.onEntered}
                                            onExiting={this.onExiting}
                                            onExited={this.onExited}
                                            className={`section-accordion__wrap-content accordion-content has-inner a ${collapses.collapseA === true ? 'active' : ''} manchester`}>

                                            {showCalendar &&
                                            <>
                                                <ul className="keyList">
                                                    <li>
                                                        <div className="keyList__key">KEY:</div>
                                                    </li>
                                                    <li>
                                                        <div className="keyList__sold-out">SOLD OUT</div>
                                                    </li>
                                                    <li>
                                                        <div className="keyList__limited">LIMITED</div>
                                                    </li>
                                                    <li>
                                                        <div className="keyList__available">AVAILABLE</div>
                                                    </li>
                                                </ul>

                                                {this.loadEvents(this.props.events.allEventsJson.edges)}
                                            </>

                                            }
                                        </Collapse>

                                        <button
                                            className={`big section-accordion__wrap-btn accordion-btn ${collapses.collapseD === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseD', e)}>
                                            {language === 'en' ? 'PHONE BOOKINGS' : 'Telefonbestilling'}
                                        </button>
                                        <Collapse isOpen={collapses.collapseD}
                                                  className={`section-accordion__wrap-content accordion-content d ${collapses.collapseD === true ? 'active' : ''}`}>
                                            {language === 'en' &&
                                            <>
                                                <p><strong>BOOK BY PHONE</strong></p>
                                                <p>Monday to Saturday from 13:00 – 17:00. Please be aware that we are
                                                    often busy when telephones open at 13:00. It may be easier to get
                                                    through to us if you wait until 14.00 or 15.00 before calling.
                                                    Sunday the telephones are closed.</p>
                                                <p>&nbsp;</p>
                                                <p>T: <a href="tel:+4589404040">+45 89 40 40 40</a></p>
                                                <p>&nbsp;</p>
                                                <p>If you have any questions regarding our events and performances or
                                                    booking of tickets, you are welcome to contact us on <a
                                                        href="mailto:billetsalg@musikhusetaarhus.dk">billetsalg@musikhusetaarhus.dk</a>
                                                </p>
                                            </>
                                            }

                                            {language === 'da' &&
                                            <>
                                                <p><strong>TELEFONBESTILLING</strong></p>
                                                <p>Mandag til lørdag fra kl. 13 - 17.</p>
                                                <p>&nbsp;</p>
                                                <p>Vær opmærksom på, at der ofte er travlhed, når vi åbner telefonerne
                                                    kl. 13. Bedste tid at ringe derfor kl. 14 eller 15. Telefonen er
                                                    lukket om søndagen.</p>
                                                <p>&nbsp;</p>
                                                <p>Billettelefon: <a href="tel:+4589404040">89 40 40 40</a></p>
                                                <p>&nbsp;</p>
                                                <p>Har du spørgsmål vedrørende vores forestillinger eller
                                                    billetbestilling, er du velkommen til at skrive til os på <a
                                                        href="mailto:billetsalg@musikhusetaarhus.dk">billetsalg@musikhusetaarhus.dk</a>
                                                </p>
                                            </>
                                            }

                                        </Collapse>

                                        <button
                                            className={`big section-accordion__wrap-btn accordion-btn ${collapses.collapseB === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseB', e)}>
                                            {language === 'en' ? 'ACCESS FACILITIES' : 'Tilgængelighed'}
                                        </button>
                                        <Collapse isOpen={collapses.collapseB}
                                                  className={`section-accordion__wrap-content accordion-content has-inner b ${collapses.collapseB === true ? 'active' : ''}`}>
                                            <div className="section-accordion__wrap-content-inner">

                                                {language === 'en' &&
                                                <>
                                                    <p>At the Concert Hall both wheelchair users and visually-and
                                                        hearing impaired can have great cultural experiences. The
                                                        Concert Hall’s guests with companion cards can bring a helper
                                                        free of charge.</p>
                                                    <p>Please contact the box office on 0045 89 40 40 40 or
                                                        billetsalg.mus@mkb.aarhus.dk if you wish to use this agreement
                                                        and to buy accessible seating.</p>
                                                    <p>Toilets</p>
                                                    <p>There are accessible toilets on every floor in the Foyer and at
                                                        the passage between the new and old building.</p>
                                                </>
                                                }

                                                {language === 'da' &&
                                                <>
                                                    <p>I Musikhuset Aarhus kan både kørestolsbrugere og syns- og
                                                        hørehæmmede få gode kulturelle oplevelser. Alle husets gæster
                                                        med ledsagerkort har mulighed for gratis at medbringe en
                                                        hjælper. Kontakt billetsalget på 89 40 40 40, hvis du ønsker at
                                                        bruge ordningen.</p>
                                                    <p>Toiletter</p>
                                                    <p>Der er toiletter for mennesker med handicap på alle etager i
                                                        Foyeren samt på Torvet.</p>

                                                </>
                                                }
                                            </div>
                                        </Collapse>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </>
                }

                {phase === 'registration' &&
                <div className="section-sign-up">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-sign-up__wrap">
                                    {phase === 'registration' && language === 'en' &&
                                    <>
                                        <h3>PERFORMANCES BEGIN 20 NOVEMBER<br/><br/><span>SIGN UP NOW AND GET ACCESS TO THE PRESALE</span>
                                        </h3>
                                        <a href="https://musikhusetaarhus.dk/en/campaigns/book-of-mormon/"
                                           rel="noreferrer noopener"
                                           target="_blank" className="btn registerNow-btn">
                                            <span className="sr-only">Register Now</span>
                                        </a>
                                    </>
                                    }
                                    {phase === 'registration' && language === 'da' &&
                                    <>
                                        <h3>PREMIERE DEN 20. NOVEMBER<br/><br/><span>SKRIV DIG PÅ MAILINGLISTEN OG FÅ ADGANG TIL ET EKSKLUSIVT PRESALE</span>
                                        </h3>
                                        <a href="https://musikhusetaarhus.dk/kampagner/book-of-mormon/"
                                           rel="noreferrer noopener"
                                           target="_blank" className="btn registerNow-btn">
                                            <span className="sr-only">Register Now</span>
                                        </a>
                                    </>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }

                {phase === 'presale' &&
                <div className="section-sign-up">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-sign-up__wrap">
                                    {language === 'en' &&
                                    <>
                                        <h2>THE BOOK OF MORMON IS COMING TO MUSIKHUSET AARHUS</h2>
                                        <br/>
                                        <h3 className="h2-presale">PUBLIC BOOKING</h3>
                                        <h3 className="highlight">OPENS 10:00AM<br/>
                                            MONDAY 1 APRIL</h3>
                                    </>
                                    }
                                    {language === 'da' &&
                                    <>
                                        <h2 className="highlight">MORMONERNE KOMMER TIL AARHUS</h2>
                                        <h3 className="h2-presale">THE BOOK OF MORMON KOMMER TIL MUSIKHUSET AARHUS</h3>
                                        <h3 className="h2-presale">FORSALGET STARTER</h3>
                                        <h3 className="highlight">MANDAG 1. APRIL KL. 10</h3>
                                    </>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }
                {phase === 'open' &&
                <div className="section-show-info">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-show-info__wrap">
                                    <div className="row">
                                        <div className="section-show-info__wrap-map">
                                            <h2 className="d-lg-none">Show Info</h2>
                                            <div className={`map aarhus`}>
                                            </div>
                                        </div>

                                        <div className="section-show-info__wrap-content dont-break-out">
                                            <div className="content">
                                                <h2 className="d-none d-lg-block">Show Info</h2>
                                                {language === 'en' &&
                                                <>
                                                    <h3>FROM 20 NOVEMBER 2019</h3>
                                                    <p>Musikhuset Aarhus<br/>
                                                        Thomas Jensens Allé 2, 8000 Aarhus, Denmark
                                                    </p>

                                                    <a className="btn btn-google-maps"
                                                       href="https://goo.gl/maps/gqZjCSoKmW42"
                                                       target="_blank"
                                                       rel="noreferrer noopener">
                                                        <span className="sr-only">Musikhuset Aarhus</span>
                                                    </a>

                                                    <h3>PERFORMANCE TIMES</h3>
                                                    <p>Tuesday to Sunday 8 pm</p>
                                                    <p>Saturday and Sunday 3 pm</p>

                                                    <h3 className="mt-2">Running Time</h3>
                                                    <p>2 hours 20 minutes, including interval</p>
                                                    <p className="med">Parental Advisory: Explicit Language</p>
                                                <p className="med">Please note: This production contains strobe lighting, flashing lights and gunshots.</p>
                                                </>
                                                }
                                                {language === 'da' &&
                                                <>
                                                    <h3>Fra 20. november 2019</h3>
                                                    <p>Musikhuset Aarhus<br/>
                                                        Thomas Jensens Allé 2, 8000 Aarhus, Denmark
                                                    </p>

                                                    {/* <a className="btn btn-google-maps"
                                                       href="https://goo.gl/maps/gqZjCSoKmW42"
                                                       target="_blank"
                                                       rel="noreferrer noopener">
                                                        <span className="sr-only">Musikhuset Aarhus</span>
                                                    </a> */}

                                                    <a className="btn btn-orange-img btn-auto"
                                                       href="https://goo.gl/maps/gqZjCSoKmW42"
                                                       target="_blank"
                                                       rel="noreferrer noopener">
                                                        Åbn Google Maps
                                                    </a>

                                                    <h3>Forestillingstidspunkter</h3>
                                                    <p>Tirsdag til søndag kl 20</p>
                                                    <p>Lørdag og søndag kl 15</p>

                                                    <h3 className="mt-2">Varighed</h3>
                                                    <p>2 timer 20 minutter inkl. pause</p>
                                                    <p className="med">Bemærk: Forestillingen spiller på engelsk</p>
                                                </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }
            </>
        );
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
        query {
            allEventsJson {
                edges {
                    node {
                        siteId
                        items {
                            url
                            start
                            title
                            className
                            active
                            type
                        }
                    }
                }
            }
        }`}
        render={data => (
            <Aarhus events={data}
                    multiLang={props.multiLang}
                    phase={props.phase}
                    siteId={props.siteId}
                    language={props.language}
            />
        )}
    />
)