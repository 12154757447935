import React, {Component} from 'react'
import {Row, Col} from 'reactstrap'

class Cast extends Component {
    renderOrder = (key) => {
        const cast = this.props.casts[key];

        if (!cast) return null;

        if (!cast.active) return null;

        return (
            <div key={key} className="cast__row">
                <Row className="d-none d-md-flex">
                    <Col xs={12} sm={3} lg={3}>
                        <img src={`/images/headshots/${cast.tag}.jpg`} alt={cast.tag} className="img-fluid"/>
                    </Col>
                    <Col xs={12} sm={9} lg={9}>
                        <h2>{cast.name}</h2>
                        <h3>{cast.role}</h3>
                        {
                            cast.pronouns ? <p className="pronouns">{cast.pronouns}</p> : ''
                        }
                        <div className="bio">
                            <div dangerouslySetInnerHTML={{__html: cast.bio}}/>
                        </div>
                    </Col>
                </Row>

                <div className="d-block d-md-none cast__row-tablet">
                    <img src={`/images/headshots/${cast.tag}.jpg`} alt={cast.tag} className="img-fluid"/>
                    <h2>{cast.name}</h2>
                    <h3>({cast.role})</h3>
                    <div className="bio">
                        <div dangerouslySetInnerHTML={{__html: cast.bio}}/>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const castIds = Object.keys(this.props.casts);
        return (
            <div className="cast">
                {castIds.map(this.renderOrder)}
            </div>
        )
    }
}

export default Cast;