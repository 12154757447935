import React, {Component} from 'react'
import {Container, TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap'
import newsImg from 'images/hero/news.jpg'
import reviewsImg from 'images/hero/reviews.jpg'
import SpinnerHeader from 'components/SpinnerHeader'
import Article from 'components/Pages/PressPage/Article'
import Review from 'components/Pages/PressPage/Review'
import classnames from 'classnames';

import articles from 'data/articles'
import reviews from 'data/reviews'
import {Link} from "gatsby";

class PressPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            articles: [],
            reviews: [],
            activeTab: 'article-tab'
        }
    }

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    };

    loadCasts = () => {
        this.setState({
            articles: articles
        });
    };


    loadReviews = () => {
        this.setState({
            reviews: reviews
        });
    };

    componentWillMount() {
        this.loadReviews();
        this.loadCasts();
    };

    componentWillUnmount() {
        this.setState({
            articles: [],
            reviews: []
        });
    };

    render() {
        const {reviews, articles} = this.state;
        const {siteId, language, multiLang} = this.props;

        if (articles.length === 0 || reviews.length === 0)
            return true;

        const articlesByLocation = typeof articles[0][siteId] === 'undefined' ? articles[0]['london'].items : articles[0][siteId].items;
        const reviewsByLocation = typeof reviews[0][siteId] === 'undefined' ? reviews[0]['london'].items : reviews[0][siteId].items;

        let rootUrl = `/${siteId}/`;

        if (multiLang) {
            rootUrl = `/${siteId}/${language}/`;
        }

        return (
            <>
                <div className="subPages">
                    <Container>
                        {/* {siteId === 'london' ?
                            <SpinnerHeader/>
                            : */}
                            <Link to={rootUrl} className={'subPages__no-link'}>
                                <div className="section-intro">

                                </div>
                            </Link>
                        {/* } */}

                        <div className="subPages__topper">
                            <h1>Press</h1>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({active: this.state.activeTab === 'article-tab'})}
                                        onClick={() => {
                                            this.toggleTab('article-tab');
                                        }}
                                    >
                                        News
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({active: this.state.activeTab === 'review-tab'})}
                                        onClick={() => {
                                            this.toggleTab('review-tab');
                                        }}
                                    >
                                        Reviews
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                    </Container>

                    <div className="subPages__hero pressPage">
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="article-tab">
                                <img id="pic1" src={newsImg} alt="The Show"/>
                            </TabPane>
                            <TabPane tabId="review-tab">
                                <img id="pic2" src={reviewsImg} alt="The Show"/>
                            </TabPane>
                        </TabContent>

                    </div>

                    <Container>
                        <div className="subPages__content">
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="article-tab">
                                    <Article
                                        articles={articlesByLocation}
                                    />
                                </TabPane>
                                <TabPane tabId="review-tab">
                                    <h2 className="subtitle">WHAT THE CRITICS SAID ABOUT THE SHOW</h2>
                                    <Review
                                        reviews={reviewsByLocation}
                                    />

                                    <h2 className="subtitle">WHAT THE CRITICS SAID ABOUT THE BROADWAY SHOW</h2>
                                    <Review
                                        reviews={reviews[0]['broadway'].items}
                                    />
                                </TabPane>
                            </TabContent>
                        </div>
                    </Container>
                </div>
            </>
        )
    }
}

export default PressPage
