import React, {Component} from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe"
import {FormGroup, Input} from 'reactstrap'
import {navigate} from 'gatsby'

const url = "https://gmail.us7.list-manage.com/subscribe/post?u=b3418623fb6b0271c9d11f231&amp;id=a0adbeceac";

class SignUpForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            emailAddress: '',
            name: ''
        };
    }

    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;

        // Update value in state
        this.setState({
            [name]: value,
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        return {
            EMAIL: this.state.emailAddress,
            NAME: this.state.name
        };
    };

    formatMessage = (message) => {
        if (message.includes("0 - Please enter a value")) {
            return "Please enter your email address."
        }

        if (message.includes("1 - Please enter a value")) {
            return "Please enter your name."
        }

        if (message.includes("An email address must contain")) {
            return "Please enter a valid email address."
        }

        if (message.includes("The domain portion of the email address is invalid")) {
            return "Please enter a valid email address."
        }

        if (message.includes("This email cannot be added to this list")) {
            return "This email address is already registered. Please enter a different email address."
        }

        return message
    };

    render() {
        const SubmitButton = ({onValidated, submitBtnCopy}) => {
            const submit = () => {
                onValidated({
                    EMAIL: this.state.emailAddress,
                    NAME: this.state.name
                });
            };
            return (
                <>
                    {submitBtnCopy === '' ?
                        <Input
                            className={`btn ${this.props.className}__submit`}
                            type="submit"
                            value={submitBtnCopy}
                            onClick={submit}
                        />
                        :
                        <Input
                            className={`btn btn-${this.props.className}__submit`}
                            type="submit"
                            value={submitBtnCopy}
                            onClick={submit}
                        />
                    }
                </>
            )
        };

        const submitBtnCopy = this.props.submitBtnCopy ? this.props.submitBtnCopy : '';

        let color = "white";

        if ((this.props.siteId === 'sunderland' || this.props.siteId === '/') && this.props.phase === 'registration') {
            color = "black"
        }

        return (
            <MailchimpSubscribe
                url={url}
                render={({subscribe, status, message}) => (
                    <>
                        <FormGroup>
                            <Input
                                type="text"
                                name="name"
                                id="name"
                                className={`${this.props.className}__input`}
                                placeholder="ENTER NAME"
                                value={this.state.name}
                                onChange={e => {
                                    this.handleChange(e)
                                }}
                            />
                            <Input
                                type="email"
                                name="emailAddress"
                                id="emailAddress"
                                className={`${this.props.className}__input`}
                                placeholder="ENTER EMAIL ADDRESS"
                                value={this.state.emailAddress}
                                onChange={e => {
                                    this.handleChange(e)
                                }}
                            />

                            <SubmitButton
                                onValidated={formData => subscribe(formData)}
                                submitBtnCopy={submitBtnCopy}
                            />

                        </FormGroup>
                        {status === "sending" && <div style={{color: color}}>sending...</div>}
                        {status === "error" && (
                            <div
                                style={{color: "red"}}
                                dangerouslySetInnerHTML={{__html: this.formatMessage(message)}}
                            />
                        )}
                        {status === "success" && (
                            this.props.thankYouPage
                                ?
                                navigate(this.props.location + 'thank-you/')
                                :
                                <div
                                    style={{color: "green"}}
                                    dangerouslySetInnerHTML={{__html: message}}
                                />
                        )
                        }
                    </>
                )}
            />
        );
    }
}

export default SignUpForm;