import React, {Component} from 'react'
import {Modal, ModalBody} from 'reactstrap';

class CompareAvailabilityModal extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    render() {
        return (
            /* eslint-disable */
            <Modal isOpen={this.props.isOpen} className="compareAvailabilityModal" toggle={this.props.toggleModal}
                   centered={true}>
                <button className="compareAvailabilityModal__close" onClick={this.props.toggleModal}>
                    <div>Close X</div>
                </button>
                <ModalBody>
                    <h2>OFFICIAL TICKET PARTNERS</h2>

                    <p>Approximate availability of tickets from each of our partners is indicated
                        below.</p>

                    <p className="legend">
                        <span><a href="#" className="red">&nbsp;</a> Very Limited Availability</span>
                        <span><a href="#" className="amber">&nbsp;</a> Some Availability</span>
                        <span><a href="#" className="green">&nbsp;</a> Good Availability</span>
                    </p>

                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <thead>
                        <tr>
                            <td className="col1">&nbsp;</td>
                            <td>MAR<span>CH</span></td>
                            <td>APR<span>IL</span></td>
                            <td>MAY</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="col1"><img src="/images/logos/encore-min.jpg" alt="encore"/></td>
                            <td><a href="https://www.encoretickets.co.uk/london-theatre/musicals/the-book-of-mormon"
                                   target="_blank" rel="noopener noreferrer" className="green"
                                   onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'Theatre People - Mar']);">Buy<span> Now</span></a>
                            </td>
                            <td><a href="https://www.encoretickets.co.uk/london-theatre/musicals/the-book-of-mormon"
                                   target="_blank" rel="noopener noreferrer" className="green"
                                   onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'Theatre People - Apr']);">Buy<span> Now</span></a>
                            </td>
                            <td><a href="https://www.encoretickets.co.uk/london-theatre/musicals/the-book-of-mormon"
                                   target="_blank" rel="noopener noreferrer" className="green"
                                   onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'Theatre People - May']);">Buy<span> Now</span></a>
                            </td>
                        </tr>
                        <tr>
                            <td className="col1"><img src="/images/logos/ticket-tm.gif" alt="ticketmaster"/></td>
                            <td><a
                                href="https://www.ticketmaster.co.uk/The-Book-of-Mormon-London-tickets/artist/1747137?camefrom=CFC_UK_DWY_OfficialWebOrganic"
                                target="_blank" rel="noopener noreferrer" className="amber"
                                onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'Ticketmaster - Mar']);">Buy<span> Now</span></a>
                            </td>
                            <td><a
                                href="https://www.ticketmaster.co.uk/The-Book-of-Mormon-London-tickets/artist/1747137?camefrom=CFC_UK_DWY_OfficialWebOrganic"
                                target="_blank" rel="noopener noreferrer" className="green"
                                onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'Ticketmaster - Apr']);">Buy<span> Now</span></a>
                            </td>
                            <td><a
                                href="https://www.ticketmaster.co.uk/The-Book-of-Mormon-London-tickets/artist/1747137?camefrom=CFC_UK_DWY_OfficialWebOrganic"
                                target="_blank" rel="noopener noreferrer" className="green"
                                onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'Ticketmaster - May']);">Buy<span> Now</span></a>
                            </td>

                        </tr>
                        <tr>
                            <td className="col1"><img src="/images/logos/ticket-lt.gif" alt="LOVETHEATRE"/></td>
                            <td><a href="https://www.lovetheatre.com/tickets/3047/The-Book-Of-Mormon?sid=BOMLDN" target="_blank"
                                   className="green"
                                   onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'LOVETHEATRE - Mar']);">Buy<span> Now</span></a>
                            </td>
                            <td><a href="https://www.lovetheatre.com/tickets/3047/The-Book-Of-Mormon?sid=BOMLDN" target="_blank"
                                   className="green"
                                   onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'LOVETHEATRE - Apr']);">Buy<span> Now</span></a>
                            </td>
                            <td><a href="https://www.lovetheatre.com/tickets/3047/The-Book-Of-Mormon?sid=BOMLDN" target="_blank"
                                   className="green"
                                   onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'LOVETHEATRE - May']);">Buy<span> Now</span></a>
                            </td>
                        </tr>
                        <tr>
                            <td className="col1"><img src="/images/logos/ticket-lm.gif" alt="Lastminute"/></td>
                            <td><a
                                href="https://www.lastminute.com/site/entertainment/theatre/event-product.html?&eventID=919154253-1"
                                target="_blank" rel="noopener noreferrer" className="amber"
                                onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'Lastminute - Mar']);">Buy<span> Now</span></a>
                            </td>
                            <td><a
                                href="https://www.lastminute.com/site/entertainment/theatre/event-product.html?&eventID=919154253-1"
                                target="_blank" rel="noopener noreferrer" className="green"
                                onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'Lastminute - Apr']);">Buy<span> Now</span></a>
                            </td>
                            <td><a
                                href="https://www.lastminute.com/site/entertainment/theatre/event-product.html?&eventID=919154253-1"
                                target="_blank" rel="noopener noreferrer" className="green"
                                onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'Lastminute - May']);">Buy<span> Now</span></a>
                            </td>
                        </tr>
                        <tr>
                            <td className="col1"><img src="/images/logos/ticket-al.gif" alt="Albemarle of London"/>
                            </td>
                            <td><a href="https://www.albemarle-london.com/ShowInfo.php?Show_No=16839" target="_blank"
                                   className="amber"
                                   onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'Albemarle - Mar']);">Buy<span> Now</span></a>
                            </td>
                            <td><a href="https://www.albemarle-london.com/ShowInfo.php?Show_No=16839" target="_blank"
                                   className="amber"
                                   onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'Albemarle - Apr']);">Buy<span> Now</span></a>
                            </td>
                            <td><a href="https://www.albemarle-london.com/ShowInfo.php?Show_No=16839" target="_blank"
                                   className="green"
                                   onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'Albemarle - May']);">Buy<span> Now</span></a>
                            </td>
                        </tr>
                        <tr>
                            <td className="col1"><img src="/images/logos/ticket-ls.gif"
                                                      alt="LEICESTER SQUARE BOX OFFICE"/></td>
                            <td><a href="https://www.lsbo.co.uk/Musical/the-book-of-mormon.htm" target="_blank" rel="noopener noreferrer" className="green"
                                   onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'LSBO - Mar']);">Buy<span> Now</span></a>
                            </td>
                            <td><a href="https://www.lsbo.co.uk/Musical/the-book-of-mormon.htm" target="_blank" rel="noopener noreferrer" className="green"
                                   onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'LSBO - Apr']);">Buy<span> Now</span></a>
                            </td>
                            <td><a href="https://www.lsbo.co.uk/Musical/the-book-of-mormon.htm" target="_blank" rel="noopener noreferrer" className="green"
                                   onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'LSBO - May']);">Buy<span> Now</span></a>
                            </td>
                        </tr>
                        <tr>
                            <td className="col1"><img src="/images/logos/ticket-ltb.gif"
                                                      alt="London Theatre Bookings"/></td>
                            <td><a href="https://www.londontheatrebookings.com/show/the-book-of-mormon/" target="_blank"
                                   className="amber"
                                   onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'LTB - Mar']);">Buy<span> Now</span></a>
                            </td>
                            <td><a href="https://www.londontheatrebookings.com/show/the-book-of-mormon/" target="_blank"
                                   className="green"
                                   onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'LTB - Apr']);">Buy<span> Now</span></a>
                            </td>
                            <td><a href="https://www.londontheatrebookings.com/show/the-book-of-mormon/" target="_blank"
                                   className="green"
                                   onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'LTB - May']);">Buy<span> Now</span></a>
                            </td>
                        </tr>
                        <tr>
                            <td className="col1"><img src="/images/logos/ticket-ltd.gif" alt="LTD"/></td>
                            <td><a href="https://www.londontheatredirect.com/musical/990/The-Book-of-Mormon-tickets.aspx"
                                   target="_blank" rel="noopener noreferrer" className="amber"
                                   onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'LTD - Mar']);">Buy<span> Now</span></a>
                            </td>
                            <td><a href="https://www.londontheatredirect.com/musical/990/The-Book-of-Mormon-tickets.aspx"
                                   target="_blank" rel="noopener noreferrer" className="green"
                                   onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'LTD - Apr']);">Buy<span> Now</span></a>
                            </td>
                            <td><a href="https://www.londontheatredirect.com/musical/990/The-Book-of-Mormon-tickets.aspx"
                                   target="_blank" rel="noopener noreferrer" className="green"
                                   onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'LTD - May']);">Buy<span> Now</span></a>
                            </td>
                        </tr>
                        <tr>
                            <td className="col1"><img src="/images/logos/ticket-st.gif" alt="See Tickets"/></td>
                            <td><a href="https://www.seetickets.com/tour/the-book-of-mormon" target="_blank" rel="noopener noreferrer" className="amber"
                                   onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'See - Mar']);">Buy<span> Now</span></a>
                            </td>
                            <td><a href="https://www.seetickets.com/tour/the-book-of-mormon" target="_blank" rel="noopener noreferrer" className="green"
                                   onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'See - Apr']);">Buy<span> Now</span></a>
                            </td>
                            <td><a href="https://www.seetickets.com/tour/the-book-of-mormon" target="_blank" rel="noopener noreferrer" className="green"
                                   onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'See - May']);">Buy<span> Now</span></a>
                            </td>
                        </tr>
                        <tr>
                            <td className="col1"><img src="/images/logos/ticket-sil.gif" alt="Showsinlondon"/></td>
                            <td><a href="https://www.showsinlondon.co.uk/show/the-book-of-mormon" target="_blank"
                                   className="amber"
                                   onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'Showsinlondon - Mar']);">Buy<span> Now</span></a>
                            </td>
                            <td><a href="https://www.showsinlondon.co.uk/show/the-book-of-mormon" target="_blank"
                                   className="amber"
                                   onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'Showsinlondon - Apr']);">Buy<span> Now</span></a>
                            </td>
                            <td><a href="https://www.showsinlondon.co.uk/show/the-book-of-mormon" target="_blank"
                                   className="amber"
                                   onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'Showsinlondon - May']);">Buy<span> Now</span></a>
                            </td>
                        </tr>
                        <tr>
                            <td className="col1"><img src="/images/logos/ticket-ab.gif" alt="Abbey"/></td>
                            <td><a href="https://www.abbeyboxoffice.co.uk/book/the-book-of-mormon" target="_blank"
                                   className="amber"
                                   onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'Abbey - Mar']);">Buy<span> Now</span></a>
                            </td>
                            <td><a href="https://www.abbeyboxoffice.co.uk/book/the-book-of-mormon" target="_blank"
                                   className="amber"
                                   onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'Abbey - Apr']);">Buy<span> Now</span></a>
                            </td>
                            <td><a href="https://www.abbeyboxoffice.co.uk/book/the-book-of-mormon" target="_blank"
                                   className="amber"
                                   onClick="_gaq.push(['_trackEvent', 'Tickets page availability chart', 'Outbound link', 'Abbey - May']);">Buy<span> Now</span></a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <p className="legend">Please note: Partners may not have seats at all price bands and booking fees
                        apply.</p>
                </ModalBody>
            </Modal>
            /* eslint-enable */
        )
    }
}

export default CompareAvailabilityModal