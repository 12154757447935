import React, {Component} from 'react'
import {Row, Col} from 'reactstrap'

class Review extends Component {
    renderOrder = (key) => {
        const review = this.props.reviews[key];

        if (!review) return null;
        
        return (
            <Row key={key} className="article__row">
                <Col xs={12} sm={12} md={9} className="article__cols">
                    {review.attr !== '' &&
                    <h3>{review.attr}</h3>
                    }
                    <h2><div dangerouslySetInnerHTML={{__html: review.title}}/></h2>
                    <div className="bio">
                        <div dangerouslySetInnerHTML={{__html: review.post}}/>
                    </div>
                    {review.url ?
                        <a href={review.url} target="_blank" rel="noreferrer noopener" className="btn btn-orange">Read more</a>
                        : ""
                    }

                </Col>
                <Col xs={12} sm={12} md={3}>
                    {review.url ?
                        <ul className="social">
                            <li>
                                <a href={`https://www.facebook.com/sharer.php?u=${review.url}`}
                                   target="_blank" rel="noreferrer noopener">
                                    <div className="share fb"></div>
                                    <span className="d-inline">Share</span>

                                </a>
                            </li>
                            <li>
                                <a href={`https://twitter.com/home?status=${review.url}`}
                                   target="_blank" rel="noreferrer noopener">
                                    <div className="share tw"></div>
                                    <span className="d-inline">Tweet</span>
                                </a>
                            </li>
                        </ul>
                        : ""
                    }
                </Col>
            </Row>
        );
    };

    render() {
        const reviewsIds = Object.keys(this.props.reviews);
        return (
            <div className="article">
                {reviewsIds.map(this.renderOrder)}
            </div>
        )
    }
}

export default Review;