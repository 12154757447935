import React, {Component} from 'react'
import {Row, Col} from 'reactstrap'

class Creative extends Component {
    renderOrder = (key) => {
        const creative = this.props.creatives[key];

        if (!creative) return null;

        if (!creative.active) return null;

        return (

            <Col key={key} xs={12} md={6} className="creative__cols">
                <h2>{creative.name}</h2>
                <h3>{creative.role}</h3>
                <div className="bio">
                    <div dangerouslySetInnerHTML={{__html: creative.bio}}/>
                </div>
            </Col>
        );
    };

    render() {
        const creativeIds = Object.keys(this.props.creatives);
        return (
            <div className="creative">
                <Row className="creative__row row-eq-height">
                    {creativeIds.map(this.renderOrder)}
                </Row>
            </div>
        )
    }
}

export default Creative;