import React from 'react'
import { Container, Row, Col } from 'reactstrap'

const Privacy = (props) => (
    <>
        <br />
        <br />
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <h1 class="h2 orange">EXTRA PREVIEW PERFORMANCE ADDED ON <br />5 JUNE 2019 WITH 1500 SEATS AT £15</h1>
                    <br />
                    <p>
                        An additional preview is announced today for the upcoming Manchester season
                of <strong><em>The Book of Mormon</em></strong>, on    <strong>Wednesday 5 June</strong> at 7:30pm at the Palace Theatre. This
                            will be the first chance for fans to see the much-anticipated Broadway
                            smash&nbsp;hit.
                </p>
                    <br />
                    <p>
                        <strong>1500 </strong>
                        tickets for the first Manchester preview will cost only<strong> £15</strong> and go on sale at <strong>10am</strong> on    <strong>Thursday 30 May</strong> only at the Palace Theatre Box Office.
                </p>
                    <br />
                    <p>
                        Tickets for this preview performance are only available in person via the
                        Palace Theatre Box Office which will open at 10am on Thursday 30 May 2019.
                        Up to two tickets may be purchased per customer on a strictly ‘first
                        come-first served’ basis with coffee available at the Palace Theatre for
                        those who get there early. Valid ID must be shown during the transaction
                        (this ID will be used for identification on the day of the performance).
                        All tickets are subject to availability and cannot be re-sold.
                </p>
                    <br />
                <p align="center">
                    <em>“The best musical of this century.”</em>
                </p>
                <p align="center">
                    Ben Brantley, The New York Times
                </p>
                    <br />
                <p>
                    The Tony®, Olivier® and Grammy® award-winning show, has extended
                    performances at the Palace Theatre, Manchester through to Saturday 10
                    August via <a href="https://thebookofmormonmusical.com">thebookofmormonmusical.com</a> and at the Palace Theatre Box Office.
                </p>
                    <br />
                    <p>
                        <strong>Palace Theatre, Oxford Street, Manchester</strong>
                    </p>
                    <p>
                        Box Office: 0844 871 3019*
                </p>
                    <p>
                        <a href="https://www.atgtickets.com/venues/palace-theatre-manchester">
                            www.atgtickets.com/venues/palace-theatre-manchester
                    </a>
                    </p>
                </Col>
            </Row>
        </Container>
    </>
)
export default Privacy