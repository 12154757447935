import React from "react";
import {Link} from "gatsby";
import subImg from 'images/logos/sub.png'
import subMobileImg from 'images/logos/sub-mob.png'
import starburstImg from 'images/buttons/starburst.png'
import clickHereImg from 'images/buttons/click-here.png'

const SpinnerHeader = (props) => (
    <div className="spinnerHeader">
        <Link className="logo" to="/">
            <img src={subImg} alt="The Book Of Mormon"
                 className="land img-fluid d-none d-sm-block"/>
            <img src={subMobileImg} alt="The Book Of Mormon"
                 className="port img-fluid d-block d-sm-none" />
        </Link>
        <Link className="spinner" to="/">
            <img id="starBurst" className="spinIt img-fluid" src={starburstImg} alt=""/>
            {props.clickHereImg ?
                <img src={props.clickHereImg} className="clickHere img-fluid" alt="Book Tickets"/>
                :
                <img src={clickHereImg} className="clickHere img-fluid" alt="Book Tickets"/>
            }
        </Link>
    </div>
);

export default SpinnerHeader