import React, {Component} from 'react'
import {Container, Row, Col} from 'reactstrap'
import {Link} from "gatsby";
import styled from "styled-components";

const Content = styled.div`
  padding: 3rem 0;
`

class ThankYouTemplate extends Component {
    render() {
        return (
            <Container fluid={true}>
                <Container>
                    <Content>
                        <h1 className="mb-5">Thank you for signing up!</h1>
                        <p className="small-copy">By submitting your email address, you are agreeing to
                            receive marketing emails about The Book of Mormon. You can opt out of receiving
                            these emails at any time.</p>
                        <p className="small-copy">To see full details visit our&nbsp;
                            {this.props.data.multiLang ?
                                <Link to={`/${this.props.data.siteId}/${this.props.data.language}/privacy-policy/`}>Privacy
                                    Policy</Link>
                                :
                                <Link to={`/${this.props.data.siteId}/privacy-policy/`}>Privacy Policy</Link>
                            }
                        </p>
                    </Content>
                </Container>
            </Container>

        )
    }
}

export default ThankYouTemplate