import React, {Component} from 'react'
import {Container, Row, Col} from 'reactstrap'
import Privacy from 'components/Pages/PrivacyPage/Privacy'

class PrivacyPage extends Component {
    render() {
        const {siteId} = this.props

        return (
            <>
                <div className="subPages">
                    <div className="section-intro">
                        <Container>
                            <Row>
                                <Col xs={12}>
                                    <h1>PRIVACY POLICY</h1>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="subPages__hero">
                    </div>

                    <Container>
                        <div className="subPages__content">
                            <Privacy audience={siteId}/>
                        </div>
                    </Container>
                </div>
            </>
        )
    }
}

export default PrivacyPage
