import React, {Component} from "react"
import SignUpPagePlymouth from 'components/Pages/SignUpPagePlymouth'

class SignUpPlymouthTemplate extends Component {
    render() {
        const data = this.props.data
        const {siteversion, languageCode} = this.props
        return (
            <SignUpPagePlymouth
                title={data.title}
                phase={data.phase}
                city={data.city}
                thankYouPageSlug={`/${data.siteId}/thank-you/`}
                siteversion={siteversion}
                siteId={data.siteId}
                language={data.language}
                multiLang={data.multiLang}
            />
        )
    }
}

export default SignUpPlymouthTemplate