import React, {Component} from 'react';
import {Container, Row, Col, Collapse} from 'reactstrap'
import SignUpForm from 'components/SignUpForm'
import {Link} from "gatsby";
import Calendar from 'components/Calendar'
import {StaticQuery, graphql} from "gatsby"
import moment from 'moment'
import star from "images/star.svg"

class Southampton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showCalendar: true,
            collapses: {
                collapseA: true,
                collapseB: false,
                collapseC: false,
                collapseD: false,
            },
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({showCalendar: true})
        }, 1000)
    }

    loadEvents = (events) => {
        let futureEvents = [];
        for (var key in events) {
            if (events[key].node.siteId === 'amsterdam') {
                for (var eventKey in events[key].node.items) {
                    if (moment().diff(events[key].node.items[eventKey].start, 'hours') <= 0 && events[key].node.items[eventKey].active) {
                        futureEvents.push(events[key].node.items[eventKey]);
                    }
                }
            }
        }

        return (
            <Calendar
                events={futureEvents}
                defaultDate={moment('2022-03-15', 'YYYY-MM-DD')}
                visibleRange={{
                    start: moment('2022-03-15', 'YYYY-MM-DD'),
                    end: moment('2022-04-04', 'YYYY-MM-DD')
                }}
                validRange={{
                    start: moment('2022-03-15', 'YYYY-MM-DD'),
                    end: moment('2022-04-04', 'YYYY-MM-DD')
                }}
            />
        )

    };

    toggleAccordion = (id, e) => {
        let collapses = {...this.state.collapses};
        const collapse = id;

        for (var key in collapses) {
            if (collapses.hasOwnProperty(key) && collapse !== key) {
                collapses[key] = false;
            }
        }

        collapses[collapse] = true;

        this.setState({collapses});
    };

    render() {
        const {phase} = this.props;
        const {showCalendar} = this.state;

        const collapses = {...this.state.collapses};

        return (
            <>
                <div className="section-intro">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                {phase === 'registration' &&
                                <h1 className="text-center">THE MORMONS ARE COMING TO AMSTERDAM! <br/> TUE 15 - SUN 27 MARCH 2022</h1>
                                }

                                {phase === 'open' &&
                                <h1>How to buy your tickets for AMSTERDAM</h1>
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>

                {phase === 'open' &&
                <>
                    <div className="section-intro section-sign-up">
                        <Container>
                            <Row>
                                <Col xs={12}>

                                    <div className="section-intro__wrapper">
                                        <div className="section-intro__wrapper-header">
                                            <h2>TICKET TIPS</h2>
                                        </div>
                                        <div className="section-intro__wrapper-body">
                                            <h3>WE KNOW IT CAN BE HARD SOMETIMES FINDING THE RIGHT TICKET SO HERE ARE A
                                                FEW HANDY TIPS:</h3>

                                            <ul>
                                                {/*<li>FINAL WEEKS now on sale</li>*/}
                                                <li>SIGN-UP <a href="#footer">HERE</a> to receive latest news including
                                                    priority seat
                                                    releases
                                                </li>
                                                <li>For a wider range of prices and seats, try midweek performances</li>
                                                {/*<li>For access performances <a href="#section-accordion" onClick={(e) => this.toggleAccordion('collapseB', e)}>click here</a></li>*/}
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="section-accordion" id="section-accordion">
                        <Container>
                            <Row>
                                <Col xs={12}>
                                    <div className="section-accordion__wrap">
                                        <button
                                            className={`section-accordion__wrap-btn accordion-btn ${collapses.collapseA === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseA', e)}>
                                            ONLINE BOOKINGS
                                        </button>
                                        <Collapse
                                            isOpen={collapses.collapseA}
                                            onEntering={this.onEntering}
                                            onEntered={this.onEntered}
                                            onExiting={this.onExiting}
                                            onExited={this.onExited}
                                            className={`section-accordion__wrap-content accordion-content has-inner a ${collapses.collapseA === true ? 'active' : ''} sunderland`}>

                                            {showCalendar &&
                                            <>
                                                <ul className="keyList">
                                                    <li>
                                                        <div className="keyList__key">KEY:</div>
                                                    </li>
                                                    <li>
                                                        <div className="keyList__sold-out">SOLD OUT</div>
                                                    </li>
                                                    <li>
                                                        <div className="keyList__limited">LIMITED</div>
                                                    </li>
                                                    <li>
                                                        <div className="keyList__available">AVAILABLE</div>
                                                    </li>
                                                </ul>

                                                {this.loadEvents(this.props.events.allEventsJson.edges)}
                                            </>

                                            }
                                        </Collapse>

                                        <button
                                            className={`section-accordion__wrap-btn accordion-btn ${collapses.collapseD === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseD', e)}>
                                            PHONE BOOKINGS
                                        </button>
                                        <Collapse isOpen={collapses.collapseD}
                                                  className={`section-accordion__wrap-content accordion-content d ${collapses.collapseD === true ? 'active' : ''}`}>
                                            <p><strong>BOOK BY PHONE</strong></p>
                                            <p>&nbsp;</p>
                                            <p>By phone <a href="tel:+31205249453">+31 20 - 52 49 453</a></p>
                                            <p>Phone lines are open Mon-Fri 2-4pm</p>
                                        </Collapse>

                                        <button
                                            className={`section-accordion__wrap-btn accordion-btn ${collapses.collapseC === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseC', e)}>
                                            Group bookings
                                        </button>
                                        <Collapse isOpen={collapses.collapseC}
                                                  className={`section-accordion__wrap-content accordion-content has-inner c ${collapses.collapseC === true ? 'active' : ''}`}>
                                            <div className="section-accordion__wrap-content-inner">
                                                <p>&nbsp;</p>
                                                <p>By phone <a href="tel:+31205249453">+31 20 - 52 49 453</a></p>
                                                <p>Phone lines are open Mon-Fri 2-4pm</p>
                                            </div>
                                        </Collapse>

                                        <button
                                            className={`section-accordion__wrap-btn accordion-btn ${collapses.collapseB === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseB', e)}>
                                            ACCESS Information
                                        </button>
                                        <Collapse isOpen={collapses.collapseB}
                                                  className={`section-accordion__wrap-content accordion-content has-inner b ${collapses.collapseB === true ? 'active' : ''}`}>
                                            <div className="section-accordion__wrap-content-inner">
                                                <p>&nbsp;</p>
                                                <p>Please call the box office for access information <a href="tel:+31205249453">+31 20 - 52 49 453</a></p>
                                            </div>
                                        </Collapse>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </>
                }

                {phase === 'registration' &&
                <div className="section-sign-up">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-sign-up__wrap">
                                    <h2 className={`h3`}>ON SALE 5 JULY – <a href="https://carre.nl/en/page/subscribe-book-of-mormon" target="_blank" rel="noopener noreferrer">SIGN UP HERE</a> FOR NEWS AND TICKET INFO </h2>
                                    {/* <SignUpForm
                                        className="sign-up-form"
                                        location="/amsterdam/"
                                        locationName="amsterdam"
                                        phase={phase}
                                        thankYouPage={true}
                                    /> */}
                                    {/* <p className="small-copy">
                                        By submitting your email address, you are agreeing to receive marketing
                                        emails about The Book of Mormon. You can opt out of receiving
                                        these emails at any time.
                                        <br/>To see full details of our privacy policy <Link
                                        to={`/amsterdam/privacy-policy/`}>click here</Link>.
                                    </p> */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }
                {phase === 'presale' &&
                <div className="section-sign-up space-below">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-sign-up__wrap orange-border">
                                    <h3>Mayflower Theatre, Southampton<br/>
                                        <img src={star} className="star" alt="" title=""/> <span className="orange">DATE CHANGE</span> <img src={star} className="star" alt="" title=""/></h3>
                                    <div className="narrow">
                                        <p>&nbsp;</p>
                                        <p>Due to the ongoing Covid-19 restrictions, all performances at Mayflower Theatre, Southampton (27 May - 13 June 2020) have been rescheduled to</p>
                                        <p>&nbsp;</p>
                                        <p className="big orange text-uppercase"><strong>WED 14 APRIL – Saturday 1 May 2021</strong></p>
                                        <p>&nbsp;</p>
                                        <p>Ticketholders for the 2020 run do not need to do anything. The theatre will contact you regarding the next steps.</p>
                                        <p>&nbsp;</p>
                                        <p>Non-ticket holders wishing to book for the 2021 run are advised to <br className="d-none d-md-inline-block"/><strong>sign up for priority booking</strong></p>
                                    </div>
                                    <SignUpForm
                                        className="sign-up-form"
                                        location="/southampton/"
                                        locationName="southampton"
                                        phase={phase}
                                        thankYouPage={true}
                                    />
                                    <p className="small-copy">
                                        By submitting your email address, you are agreeing to receive marketing
                                        emails about The Book of Mormon. You can opt out of receiving
                                        these emails at any time.
                                        <br/>To see full details of our privacy policy <Link
                                        to={`/southampton/privacy-policy/`}>click here</Link>.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }
                {phase === 'open' &&
                <div className="section-show-info">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-show-info__wrap">
                                    <div className="row">
                                        <div className="section-show-info__wrap-map">
                                            <h2 className="d-lg-none">Show Info</h2>
                                            <div className={`map amsterdam`}>
                                            </div>
                                        </div>

                                        <div className="section-show-info__wrap-content">
                                            <div className="content">
                                                <h2 className="d-none d-lg-block">Show Info</h2>
                                                <h3>FROM 15 MARCH 2022</h3>
                                                <p>Royal Theater Carré<br/>
                                                    Amstel 115 - 125, 1018 EM Amsterdam
                                                </p>

                                                <a className="btn btn-google-maps"
                                                   href="https://goo.gl/maps/rKE4dmRunjrprTk89"
                                                   target="_blank"
                                                   rel="noreferrer noopener">
                                                    <span className="sr-only">Mayflower Theatre Map</span>
                                                </a>

                                                <h3 className="mt-2">Running Time</h3>
                                                <p>2 hours 20 minutes, including interval</p>
                                                <p className="med">Parental Advisory:</p>
                                                <p className="med">Age Guidance: 14+ (No Under 2s). Contains strong language, adult themes, strobe lighting and bangs.</p>
                                                <p className="med">Under 16s must be accompanied by an adult.</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }
            </>
        );
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
        query {
            allEventsJson {
                edges {
                    node {
                        siteId
                        items {
                            url
                            start
                            title
                            className
                            active
                            type
                        }
                    }
                }
            }
        }`}
        render={data => (
            <Southampton events={data}
                        multiLang={props.multiLang}
                        phase={props.phase}
                        siteId={props.siteId}
                        language={props.language}
            />
        )}
    />
)