import React, {Component} from 'react';
import {Container, Row, Col, Collapse} from 'reactstrap'
import SignUpForm from 'components/SignUpForm'
import {Link} from "gatsby";
import {StaticQuery, graphql} from "gatsby"
import tickX from 'data/tickx'
import booknowSml from 'images/booknow-sml.jpg'
// import lottery from 'images/bradfordticketlottery.svg'

class Bradford extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showCalendar: true,
            collapses: {
                collapseA: true,
                collapseB: false,
                collapseC: false,
                collapseD: false,
            },
            coronaMessageOpen: false,
        }
    }

    componentDidMount() {
        // setTimeout(() => {
        //     this.setState({showCalendar: true})
        // }, 1000)

        // if (window.tx) {
        //     window.tx({
        //         widgetId: "BoMbradford",
        //         configId: "9P0NyDt1",
        //         selector: "#tickx-widget"
        //     });
        // }
    }

    closeCoronaMessage = () => {
        this.setState({
            coronaMessageOpen: false
        })
    }

    // loadEvents = (events) => {
    //     let futureEvents = [];

    //     for (var key in events) {
    //         if (events[key].node.siteId === 'bradford') {
    //             for (var eventKey in events[key].node.items) {
    //                 if (moment().diff(events[key].node.items[eventKey].start, 'hours') <= 0 && events[key].node.items[eventKey].active) {
    //                     futureEvents.push(events[key].node.items[eventKey]);
    //                 }
    //             }
    //         }
    //     }

    //     return (
    //         <Calendar
    //             events={futureEvents}
    //             defaultDate={moment('2024-11-01', 'YYYY-MM-DD')}
    //             visibleRange={{
    //                 start: moment('2024-11-01', 'YYYY-MM-DD'),
    //                 end: moment('2024-12-31', 'YYYY-MM-DD')
    //             }}
    //             validRange={{
    //                 start: moment('2024-11-01', 'YYYY-MM-DD'),
    //                 end: moment('2024-12-01', 'YYYY-MM-DD')
    //             }}
    //         />
    //     )
    // };

    toggleAccordion = (id, e) => {
        let collapses = {...this.state.collapses};
        const collapse = id;

        for (var key in collapses) {
            if (collapses.hasOwnProperty(key) && collapse !== key) {
                collapses[key] = false;
            }
        }

        collapses[collapse] = true;

        this.setState({collapses});
    };

    render() {
        const {phase, siteId } = this.props;
        const {showCalendar} = this.state;

        const collapses = {...this.state.collapses};
        return (
            <React.Fragment>
                <div className="section-intro">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                {phase === 'registration' &&
                                <h1 className="text-center">THE MORMONS ARE COMING TO BRADFORD</h1>
                                }

                                {phase === 'open' &&
                                <h1>How to buy your tickets for BRADFORD</h1>
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>

                {phase === 'open' &&
                <>
                    <div className="section-intro section-sign-up">
                        <Container>
                            <Row>
                                <Col xs={12}>

                                    <div className="section-intro__wrapper">
                                        <div className="section-intro__wrapper-header">
                                            <h2>TICKET TIPS</h2>
                                        </div>
                                        <div className="section-intro__wrapper-body">
                                            <h3>WE KNOW IT CAN BE HARD SOMETIMES FINDING THE RIGHT TICKET SO HERE ARE A FEW HANDY TIPS:</h3>

                                            <ul>
                                                <li>SIGN-UP <a href="#footer">HERE</a> to receive latest news including
                                                    priority seat
                                                    releases
                                                </li>
                                                <li>For a wider range of prices and seats, try midweek performances</li>
                                                {/* <li><a href="https://www.bradfordhippodrome.com/update-on-coronavirus/" target="_blank" rel="noreferrer noopener">Advice regarding Coronavirus / COVID-19</a></li> */}
                                                {/* <li>Online ticket lottery <a href="https://thebookofmormonmusical.com/bradfordlottery/">NOW OPEN!</a></li> */}
                                                {/*<li>For access performances <a href="#section-accordion" onClick={(e) => this.toggleAccordion('collapseB', e)}>click here</a></li>*/}
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="section-accordion" id="section-accordion">
                        <Container>
                            <Row>
                                <Col xs={12}>
                                {/* {showCalendar &&
                                        <>
                                            <h2 className='py-3'>ONLINE BOOKINGS</h2>
                                            <ul className="keyList">
                                                <li>
                                                    <div className="keyList__key">KEY:</div>
                                                </li>
                                                <li>
                                                    <div className="keyList__sold-out">SOLD OUT</div>
                                                </li>
                                                <li>
                                                    <div className="keyList__limited">LIMITED</div>
                                                </li>
                                                <li>
                                                    <div className="keyList__available">AVAILABLE</div>
                                                </li>
                                            </ul>

                                            {this.loadEvents(this.props.events.allEventsJson.edges)}
                                        </>
                                    } */}
                                    <div className="section-accordion__wrap">
                                    <button id="collapseA"
                                                className={`section-accordion__wrap-btn accordion-btn ${collapses.collapseA === true ? 'active' : ''}`}
                                                onClick={(e) => this.toggleAccordion('collapseA', e)}>
                                                
                                            Official Box Office
                                        </button>
                                        <Collapse
                                            isOpen={collapses.collapseA}
                                            onEntering={this.onEntering}
                                            onEntered={this.onEntered}
                                            onExiting={this.onExiting}
                                            onExited={this.onExited}
                                            className={`section-accordion__wrap-content accordion-content tickX a ${collapses.collapseA === true ? 'active' : ''} ${siteId}`}>

                                            <div className="table">
                                            {tickX[6].bradford.map((button, i ) => {
                                                    return (
                                                        <a className="table-row"
                                                        href={`/bradford/tickets/?flow=${button.configId}`}>
                                                         <div className="table-cell">{i + 1}</div>
                                                         <div className="table-cell">
                                                             <div
                                                                 dangerouslySetInnerHTML={{__html: button.copy }}/>
                                                                        <span
                                                            dangerouslySetInnerHTML={{__html: button.sub }}></span>
                                                          </div>
                                                         <div className="table-cell">
                                                             <div className={`btn-buy GTM-BOMLondonIncAvail`}
                                                                  id="versaID-London-IncAvail" alt="" src={booknowSml}/>
                                                         </div>
                                                     </a>
                                                    )
                                                })}  
                                            </div>
                                        </Collapse>
                                        {/* <button
                                            className={`section-accordion__wrap-btn accordion-btn ${collapses.collapseA === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseA', e)}>
                                            ONLINE BOOKINGS
                                        </button>
                                        <Collapse
                                            isOpen={collapses.collapseA}
                                            onEntering={this.onEntering}
                                            onEntered={this.onEntered}
                                            onExiting={this.onExiting}
                                            onExited={this.onExited}
                                            className={`section-accordion__wrap-content accordion-content has-inner a ${collapses.collapseA === true ? 'active' : ''} sunderland`}>

                                            {showCalendar &&
                                            <>
                                                <ul className="keyList pricesKeyList">
                                                    <li>
                                                        <div className="keyList__key">SEATS FROM:</div>
                                                    </li>
                                                    <li>
                                                        <div className="keyList__available">£15</div>
                                                    </li>
                                                    <li>
                                                        <div className="keyList__limited">£17.75</div>
                                                    </li>
                                                    <li>
                                                        <div className="keyList__sold-out">£22.75</div>
                                                    </li>
                                                </ul>

                                                {this.loadEvents(this.props.events.allEventsJson.edges)}
                                            </>

                                            }
                                        </Collapse> */}

                                        <button
                                            className={`section-accordion__wrap-btn accordion-btn ${collapses.collapseD === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseD', e)}>
                                            PHONE BOOKINGS
                                        </button>
                                        <Collapse isOpen={collapses.collapseD}
                                                  className={`section-accordion__wrap-content accordion-content d ${collapses.collapseD === true ? 'active' : ''}`}>
                                            <p>Box Office: <a href="tel:01274 432000">01274 432000</a></p>
                                            <p>&nbsp;</p>
                                            <p>Group and Schools Bookings: <a href="tel:01274 437788">01274 437788</a></p>
                                        </Collapse>

                    

        
                                        <button
                                            className={`section-accordion__wrap-btn accordion-btn  ${collapses.collapseB === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseB', e)}
                                            >
                                            ACCESS PERFORMANCES
                                        </button>
                                        <Collapse isOpen={collapses.collapseB}
                                                  className={`section-accordion__wrap-content accordion-content has-inner b ${collapses.collapseB === true ? 'active' : ''}`}>
                                            <div className="section-accordion__wrap-content-inner">
                                                <p><strong>Access Performances</strong></p>
                                                <p>For information on access, <a href="https://www.bradford-theatres.co.uk/alhambra-theatre/seating-accessibility" target="_blank" rel="noopener noreferrer"> click here</a> </p>
                                            </div>
                                        </Collapse>

                                        {/* <button
                                            className={`section-accordion__wrap-btn accordion-btn ${collapses.collapseE === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseE', e)}>
                                            ONLINE LOTTERY
                                        </button>
                                        <Collapse isOpen={collapses.collapseE}
                                                  className={`section-accordion__wrap-content accordion-content has-inner b ${collapses.collapseE === true ? 'active' : ''}`}>
                                            <div className="section-accordion__wrap-content-inner">
                                               <img src={lottery} alt="" title="" className="bradford-lottery"/>
                                                <p>&nbsp;</p>
                                                <p>A limited number of tickets for each performance from 9 March are available at £15 per ticket via the online ticket lottery. Each winner drawn at random will be allowed to purchase one pair of seats.</p>
                                                <p>&nbsp;</p>
                                                <p>Entries accepted from 12 noon (GMT/BST) on Wednesday 4 March to 12 noon (GMT/BST) on Thursday 5 March for all performances. Terms apply</p>
                                                <p>&nbsp;</p>
                                                <a className="btn btn-orange-img full-width" href="https://thebookofmormonmusical.com/bradfordlottery/">
                                                   Click to enter
                                                </a>
                                            </div>
                                        </Collapse> */}
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </>
                }

                {phase === 'registration' &&
                <div className="section-sign-up">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-sign-up__wrap">
                                    <h2 className={`h3`}>SIGN UP NOW AND GET ACCESS TO THE PRESALE <br/>REGISTER NOW</h2>
                                    <SignUpForm
                                        className="sign-up-form"
                                        location="/bradford/"
                                        locationName="bradford"
                                        phase={phase}
                                        thankYouPage={true}
                                    />
                                    <p className="small-copy">
                                        By submitting your email address, you are agreeing to receive marketing
                                        emails about The Book of Mormon. You can opt out of receiving
                                        these emails at any time.
                                        <br/>To see full details of our privacy policy <Link
                                        to={`/bradford/privacy-policy/`}>click here</Link>.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }
                {phase === 'presale' &&
                <div className="section-sign-up">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-sign-up__wrap">
                                    <h2>THE BOOK OF MORMON IS COMING TO BRADFORD
                                    </h2>
                                    <br/>
                                    <h3 className="h2-presale">PUBLIC BOOKING</h3>
                                    <h3 className="highlight">OPENS 10:00AM<br/>
                                        TUESDAY 22 OCTOBER</h3>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }
                {phase === 'open' &&
                <div className="section-show-info">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-show-info__wrap">
                                    <div className="row">
                                        <div className="section-show-info__wrap-map">
                                            <h2 className="d-lg-none">Show Info</h2>
                                            <div className={`map bradford-map  `}>
                                            </div>
                                        </div>

                                        <div className="section-show-info__wrap-content">
                                            <div className="content">
                                                <h2 className="d-none d-lg-block">Show Info</h2>
                                                <h3>5 - 23 November 2024</h3>
                                                <p>ALHAMBRA THEATRE<br/>
                                                Morley Street, Bradford, BD7 1AJ</p>

                                                <a className="btn btn-google-maps"
                                                   href="https://www.google.com/maps/place/Alhambra+Theatre,+Bradford/@53.7918098,-1.7593418,17z/data=!3m2!4b1!5s0x487be14a4dd609cf:0xeeb107d6482024b3!4m6!3m5!1s0x487be14a4c859ef5:0x17e3c9c24d4026e2!8m2!3d53.7918098!4d-1.7567669!16zL20vMGYycF9y?entry=ttu"
                                                   target="_blank"
                                                   rel="noreferrer noopener">
                                                    <span className="sr-only">ALHAMBRA THEATRE Map</span>
                                                </a>

                                                <h3>PERFORMANCE TIMES</h3>
                                                <p>Monday - Saturday 7.30pm</p>
                                                <p>Friday and Saturday 2.30pm</p>

                                                <h3 className="mt-2">Running Time</h3>
                                                <p>2 hours 20 minutes, including interval</p>
                                                <br />
                                                <p><strong>Parental advisory:</strong> Explicit language.</p>
<p><strong>Please note:</strong>&nbsp;This production contains strobe lighting, flashing lights and gunshots.&nbsp;</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }
            </React.Fragment>
        );
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
        query {
            allEventsJson {
                edges {
                    node {
                        siteId
                        items {
                            url
                            start
                            title
                            className
                            active
                            type
                        }
                    }
                }
            }
        }`}
        render={data => (
            <Bradford events={data}
                        multiLang={props.multiLang}
                        phase={props.phase}
                        siteId={props.siteId}
                        language={props.language}
            />
        )}
    />
)