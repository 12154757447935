import React, { useEffect, useState } from "react";
import Layout from "components/Layout/Layout";
import { Container } from "reactstrap";
import NewSignUpForm from "../NewSignUpForm";
import styled from "styled-components";
import signUpHeader from "images/headers/header-signup.png";
import signUpHeaderMobile from "images/headers/header-signup-mobile.jpg";

import Calendar from "components/Calendar";

import moment from "moment";
import { graphql, useStaticQuery } from "gatsby";

const StyledTitle = styled.div`
  width: 100%;
  text-align: center;
  span {
    display: block;
    color: #000000;
    font-size: 16px;
    padding: 5px 0;
    @media (min-width: 768px) {
      font-size: 22px;
    }
    @media (min-width: 1280px) {
      font-size: 25px;
    }
  }
  h1 {
    @media (min-width: 768px) {
      font-size: 35px;
    }
    @media (min-width: 1280px) {
      font-size: 55px;
    }
  }
  h2 {
    color: #de691c;
    font-weight: 900;
    font-family: proxima-nova, sans-serif;
    margin-bottom: 0.35rem;
    font-size: 18px;
    @media (min-width: 768px) {
      font-size: 22px;
    }
    @media (min-width: 1280px) {
      font-size: 25px;
    }
  }
  h3 {
    color: black;
    font-weight: 500;
    /* font-family: proxima-nova, sans-serif; */
    margin-top: 1rem;
    font-size: 18px;
    @media (min-width: 768px) {
      font-size: 20px;
    }
    /* @media (min-width: 1280px){
            font-size: 20px;
        } */
  }
  h4 {
    font-size: 18px;
    @media (min-width: 768px) {
      font-size: 22px;
    }
    @media (min-width: 1280px) {
      font-size: 25px;
    }
  }
`;

const Content = styled.div`
  padding-top: 3rem;
  width: 100%;

  h3 {
    text-transform: none;
  }
`;

const SignUpPage = ({ city }) => {
  
  const { dates } = useStaticQuery(graphql`
    query {
      dates: allEventsJson {
        edges {
          node {
            siteId
            items {
              url
              start
              title
              className
              active
              type
            }
          }
        }
      }
    }
  `);

  const [load, setLoad] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoad(true);
    }, 1000);
  }, []);

  const data = {
    title: `The Book Of Mormon Musical | Official Website | Bradford Signup`,
  };

  const loadEvents = (events) => {
    let futureEvents = [];

    for (var key in events) {
      if (events[key].node.siteId === city) {
        for (var eventKey in events[key].node.items) {
          if (
            moment().diff(events[key].node.items[eventKey].start, "hours") <=
              0 &&
            events[key].node.items[eventKey].active
          ) {
            futureEvents.push(events[key].node.items[eventKey]);
          }
        }
      }
    }

    return (
      <>
      {city === 'plymouth' && (
        <Calendar
        noArrows
        events={futureEvents}
        defaultDate={moment("2025-1-1", "YYYY-MM-DD")}
        visibleRange={{
          start: moment("2025-5-1", "YYYY-MM-DD"),
          end: moment("2025-5-1", "YYYY-MM-DD"),
        }}
        validRange={{
          start: moment("2025-1-1", "YYYY-MM-DD"),
          end: moment("2025-2-1", "YYYY-MM-DD"),
        }}
      />
      )}
      {city === 'hull' && (
        <Calendar
        noArrows
        events={futureEvents}
        defaultDate={moment("2025-2-1", "YYYY-MM-DD")}
        visibleRange={{
          start: moment("2025-3-1", "YYYY-MM-DD"),
          end: moment("2025-3-30", "YYYY-MM-DD"),
        }}
        validRange={{
          start: moment("2025-3-1", "YYYY-MM-DD"),
          end: moment("2025-3-30", "YYYY-MM-DD"),
        }}
      />
      )}
      </>
    );
  };

  return (
    <Layout data={data}>
      <div className="performance__header">
        <div className="images">
          <div className="mobile">
            <img src={signUpHeaderMobile} alt="" />
          </div>
          <div className="desktop">
            <img src={signUpHeader} alt="" />
          </div>
        </div>
      </div>
      <Container fluid={true}>
        <Container className="">
          <Content>
            <StyledTitle>
              <h1>{`THE MORMANS ARE COMING TO ${city}`}</h1>
            </StyledTitle>
          </Content>
          <h2 className="py-3 text-center">BOOK YOUR PRIORITY TICKETS TODAY</h2>
          <ul className="keyList">
            <li>
              <div className="keyList__key">KEY:</div>
            </li>
            <li>
              <div className="keyList__sold-out">SOLD OUT</div>
            </li>
            <li>
              <div className="keyList__limited">LIMITED</div>
            </li>
            <li>
              <div className="keyList__available">AVAILABLE</div>
            </li>
          </ul>
          {loadEvents(dates.edges)}
          <Content>
            {city === 'plymouth' && (

              <StyledTitle>
              <h2 style={{ color: "black" }}>
                COMING TO THEATRE ROYAL, PLYMOUTH FROM 14 - 25 JANUARY 2025
                <br />
                GENERAL ON SALE FROM MONDAY 1 MARCH
              </h2>
            </StyledTitle>
              )}
            {city === 'hull' && (

              <StyledTitle>
              <h2 style={{ color: "black" }}>
              COMING TO HULL NEW THEATRE FROM 4 - 22 MARCH 2025

                <br />
                GENERAL ON SALE FROM THURSDAY 7 MARCH 
              </h2>
            </StyledTitle>
              )}
          </Content>
        </Container>
      </Container>
    </Layout>
  );
};

export default SignUpPage;
