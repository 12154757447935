import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Amplify, { API, Auth } from 'aws-amplify'
import awsExports from "../aws-exports";
import Layout from "components/Layout/Layout";
import DesktopHeaderImage from "../images/BOM_TOUR_MAY22_NEWCASTLE_Desktop_1200x450_30213SA.jpg";
import MobileHeaderImage from "../images/BOM_TOUR_MAY22_NEWCASTLE_Mobile_400x400_30213SA.jpg";

Amplify.configure(awsExports);

const FanPerformanceBookingTemplate = (props) => {
  const { data } = props;
  return (
    <Layout data={data} displayFooter={false}>
      <div className="performance__header">
        <div className="images">
          <div className="mobile">
            <img src={MobileHeaderImage} alt="" />
          </div>
          <div className="desktop">
            <img src={DesktopHeaderImage} alt="" />
          </div>
        </div>
        <div className="wrapper">
          <h1>NEWCASTLE THEATRE ROYAL</h1>
          <h2>EXTRA FAN PREVIEW ADDED – 14 JUNE 2022 @ 7.30pm</h2>
        </div>
      </div>
      <Form />
      {/* <div className="performance__legal">
          <h5>Terms &amp; conditions</h5>
          <p>Registration alone does not guarantee entrants a ticket to the £15 fan preview. Registration provides access to a personal non-transferable priority booking link. Any remaining seats will be made available to the general public from 10am Friday 10 June.  Maximum 2 tickets per booking. </p>
          <p>Fan preview tickets non-exchangeable/refundable on existing tickets</p>
      </div> */}
    </Layout>
  );
};

export default FanPerformanceBookingTemplate;

const Form = () => {
  const [success, setSuccess] = useState(false);
  const [error, hasErrors] = useState(false);
  const [isRegistered, setIsRegistered] = useState("ENTER YOUR EMAIL ADRESS TO PROCEED TO BOOKING");
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onSubmit",
  });

  const onSubmit = (data, e) => {
    e.preventDefault()

    let apiName = 'bomMailchimpUserCheckAPI';
    let path = "/user"
    let myInit = { // OPTIONAL
        headers: {}, // OPTIONAL
        response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
        queryStringParameters: data
    }

    return API.get(apiName, path, myInit).then(response => {
        if (response.status === 200 && response.data.data !== undefined) {
          // user
          window.location.href = "https://tnew.theatreroyal.co.uk/overview/20626?promo=FANS";
        } else {
          // no user
          setIsRegistered('SORRY THIS EMAIL HAS NOT BEEN REGISTERED')
        }

    })
    // .catch(error => {
    //     console.log(error)
    //     return error.response
    // })
  }

  return (
    <div className="performance__form">
      <h3>{isRegistered}</h3>
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        className="form-check-email"
      >
        <input
          type="email"
          placeholder="Email"
          id="email"
          name="email"
          className={errors.email ? "error" : ""}
          {...register("email", {
            required: "An email is required",
            pattern: {
              value:
                /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              message: "Invalid email address",
            },
          })}
        />
        <div className="button">
          <button
            type="submit"
            className="btn btn-footer-form__submit"
          ></button>
        </div>
      </form>
      {Object.keys(errors).length === 0 ? (
        ""
      ) : (
        <div className="performance__error">
          {errors.email && <ErrorMessage message={errors.email.message} />}
        </div>
      )}
      {/* {error ? (
          <div className="performance__error">
            <ErrorMessage message="This email is already in use" />
          </div>
        ) : (
          ''
        )} */}
    </div>
  );
};

const ErrorMessage = ({ message }) => (
  <div className="errors">
    {/* <ErrorIcon /> */}
    <p>{message}</p>
  </div>
);
