import React, {Component} from 'react'
import {Container} from 'reactstrap'
import SpinnerHeader from 'components/SpinnerHeader'
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import {navigate, Link, StaticQuery, graphql} from 'gatsby'
import styled from "styled-components";
import {media} from "utils/Media"

const ImageWrapper = styled.div`
  position: relative;
  display: block;
  margin: 0.5rem;
  border: 1px solid #000;
  cursor: pointer;
`

class GalleryPage extends Component {
    constructor(props) {

        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false,
            modalOpen: false,
        }

    }

    toggleModal = (e) => {
        e.preventDefault();

        this.setState({
            modalOpen: !this.state.modalOpen
        });
    };

    render() {
        const {isOpen, photoIndex} = this.state;
        const {siteId, language, multiLang, data} = this.props;

        let images = [];

        let photosByLocation;

        if (siteId !== 'london') {
            photosByLocation = data.photos.edges[0].node.tour.items
        } else {
            photosByLocation = data.photos.edges[0].node.london.items
        }

        let rootUrl = `/${siteId}/`;

        if (multiLang) {
            rootUrl = `/${siteId}/${language}/`;
        }

        const showGallery = photosByLocation.map((item, i) => {
            console.log(item)
            let obj = {}
            obj.src = item.src.childImageSharp.gatsbyImageData.images.fallback.src
            images.push(obj)
            return (
                <li
                    key={i}
                    tabIndex={i} role="button" aria-pressed="false"
                    onClick={() => this.setState({photoIndex: i, isOpen: true})}
                    onKeyDown={() => this.setState({photoIndex: i, isOpen: true})}
                >
                    <ImageWrapper>
                        <GatsbyImage image={getImage(item.thumbnail)}
                                     alt=""
                                     onClick={() => this.setState({photoIndex: i, isOpen: true})}
                                     className="img-fluid"
                        />
                        <div className="overlay"></div>
                    </ImageWrapper>
                </li>
            )
        })

        return (
            <>
                <div className="subPages">
                    <Container>
                        {/* {siteId === 'london' ?
                            <SpinnerHeader/>
                            : */}
                            <Link to={rootUrl} className={'subPages__no-link'}>
                                <div className="section-intro">
                                </div>
                            </Link>
                        {/* } */}
                        <div className="subPages__topper">
                            <h1>Gallery</h1>
                        </div>
                    </Container>

                    {/* <div className="subPages__hero">
                    </div> */}

                    <Container>
                        <div className="subPages__content">
                            <ul className="gallery">
                                {showGallery}
                            </ul>
                            {/*/>*/}
                            <p>&nbsp;</p>
                            {siteId !== 'london' ?
                                <p className="small-copy">Photos are of the current tour cast.</p>
                                :
                                ''
                            }
                        </div>
                    </Container>
                </div>

                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex].src}
                        nextSrc={images[(photoIndex + 1) % images.length].src}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
                        imageCaption=""
                        onCloseRequest={() => this.setState({isOpen: false})}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length,
                            })
                        }
                    />
                )}
            </>
        )
    }
}

const Query = (props) => (
    <StaticQuery
        query={graphql`
            query {
                photos:allPhotosJson {
                    edges {
                      node {
                        london {
                          items {
                            src {
                                childImageSharp {
                                    gatsbyImageData(width: 1200)
                                }
                            }
                            thumbnail {
                                childImageSharp {
                                    gatsbyImageData(width: 189)
                                }
                            }
                          }
                        }
                        tour {
                          items {
                            src {
                                childImageSharp {
                                    gatsbyImageData(width: 1200)
                                }
                            }
                            thumbnail {
                                childImageSharp {
                                    gatsbyImageData(width: 189)
                                }
                            }
                          }
                        }
                      }
                    }
                }
            }
		`}
        render={data => (
            <GalleryPage {...props} data={data}/>
        )}
    />
)

export default Query
