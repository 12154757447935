import React, {Component} from 'react';
import FullCalendar from 'fullcalendar-reactwrapper';
import 'fullcalendar-reactwrapper/dist/css/fullcalendar.min.css';

class Calendar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            status: 'Opened',
        }
    }

    eventClick = (e) => {
        if (e.url) {
            window.open(e.url, "_blank");
            return false;
        }
    }

    onEntered = () => {
        this.setState({
            status: "Entered"
        })
    }

    eventRender = (event, element, view) => {
        // element.addClass(event.start._i)
        element.attr('id', event.start._i);
        if (event.className.includes('sold-out')) {
            element.addClass('isDisabled')
            return element;
        }
    }

    render() {
        if (typeof window === "undefined")
            return (<></>)

        return (
            <FullCalendar
                id="fullcalendar"
                header={{
                    left: 'prev',
                    center: 'title',
                    right: 'next'
                }}
                defaultDate={this.props.defaultDate}
                displayEventTime={false}
                contentHeight="auto"
                navLinks={false} // can click day/week names to navigate views
                editable={false}
                eventLimit={false} // allow "more" link when too many events
                eventClick={e => this.eventClick(e)}
                events={this.props.events}
                eventRender={(event, element, view) => this.eventRender(event, element, view)}
                visibleRange={this.props.visibleRange}
                validRange={this.props.validRange}
            />
        );
    }
}

export default Calendar;