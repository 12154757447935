import React, {Component} from "react";
import {graphql, Link} from "gatsby";
import Layout from "../components/Layout/Layout";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import CleanFooter from "../components/Footer/CleanFooter";
import HomeTemplate from "./HomeTemplate";
import TicketsTemplate from "./TicketsTemplate";
import ThankYouTemplate from "./ThankYouTemplate";
import ShowTemplate from "./ShowTemplate";
import PressTemplate from "./PressTemplate";
import ArticleTemplate from "./ArticleTemplate";
import GalleryTemplate from "./GalleryTemplate";
import PrivacyTemplate from "./PrivacyTemplate";
import CookieTemplate from "./CookieTemplate";
import SignUpTemplate from "./SignUpTemplate";
import FanPerformanceTemplate from "./FanPerformanceTemplate";
import FanPerformanceBookingTemplate from "./FanPerformanceBookingTemplate";
import TourHomeTemplate from "./TourHomeTemplate";
import CleanHeader from "../components/Header/CleanHeader";
import Helmet from "react-helmet";
import SignUpPlymouthTemplate from "./SignUpPlymouthTemplate";

const date = new Date();

class TemplateSelector extends Component {
    getTemplate = (data) => {
        const template = data.template;
        switch (template) {
            case "home-template":
                return <HomeTemplate data={data}/>;
            case "newcastleFanPerformance-template":
                return <FanPerformanceTemplate data={data}/>;
            case "newcastleFanPerformanceBooking-template":
                return <FanPerformanceBookingTemplate data={data}/>;
            case "tickets-template":
                return <TicketsTemplate data={data}/>;
            case "thank-you-template":
                return <ThankYouTemplate data={data}/>;
            case "show-template":
                return <ShowTemplate data={data}/>;
            case "press-template":
                return <PressTemplate data={data}/>;
            case "article-template":
                return <ArticleTemplate data={data}/>;
            case "gallery-template":
                return <GalleryTemplate data={data}/>;
            case "cookie-template":
                return <CookieTemplate data={data}/>;
            case "privacy-template":
                return <PrivacyTemplate data={data}/>;
            case "signup-template":
                return <SignUpTemplate data={data}/>;
            case "signup-template-plymouth":
                return <SignUpPlymouthTemplate data={data}/>;
            case "tour-home-template":
                return <TourHomeTemplate data={data}/>;
            default:
                return <HomeTemplate data={data}/>;
        }
    };

    render() {
        const data = this.props.data.pagesJson;
        return (
            <Layout data={data}>
                {/* <Helmet>
                    <link rel="alternate" hrefLang={this.props.languageCode} href={`https://thebookofmormonmusical.com/${data.siteId}`}/>
                </Helmet> */}
                <Header data={data}/>
                {/* {data.displayHeader ? (
                    <Header data={data}/>
                ) : (
                    <CleanHeader
                        displaySocials={data.displaySocials}
                    />
                )} */}
                {this.getTemplate(data)}
                <Footer data={data}/>
                {/* <>
                    {data.displayHeader ? (
                        <Footer data={data}/>
                    ) : (
                        <CleanFooter data={data}/>
                    )}
                </> */}
            </Layout>
        );
    }
}

export default TemplateSelector;

export const query = graphql`
  query ($id: String!) {
    pagesJson(id: { eq: $id }) {
      id
      siteId
      page
      language
      languageCode
      phase
      city
      template
      metaTitle
      metaDescription
      path
      multiLang
      region
      displayHeader
      displaySocials
    }
  }
`;