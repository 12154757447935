import React, {Component} from "react"
import SignUpPage from 'components/Pages/SignUpPage'

class SignUpTemplate extends Component {
    render() {
        const data = this.props.data
        const {siteversion, languageCode} = this.props

        return (
            <SignUpPage
                title={data.title}
                phase={data.phase}
                thankYouPageSlug={`/${data.siteId}/thank-you/`}
                siteversion={siteversion}
                siteId={data.siteId}
                language={data.language}
                multiLang={data.multiLang}
            />
        )
    }
}

export default SignUpTemplate