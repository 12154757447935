import React, {Component} from 'react';
import {Container, Row, Col, Collapse} from 'reactstrap'
import CompareAvailabilityModal from 'components/Modals/CompareAvailabilityModal'
import SignUpForm from 'components/SignUpForm'
import {Link} from "gatsby";
import booknowSml from 'images/booknow-sml.jpg'
import encore from 'images/encore.jpg'
import star from "images/star.svg"
import tickX from 'data/tickx'
import closeBtn from "../../../images/close.svg"
import btnImg from '../../../images/performance-btn-large.jpeg'
import TodayTixLogo from '../../../images/logos/todaytix.svg'

class London extends Component {
    constructor(props) {
        super(props);

        this.state = {
            collapses: {
                collapseA: true,
                collapseB: false,
                collapseC: false,
                collapseD: false,
            },
            status: 'Opened',
            coronaMessageOpen: false,
            compareAvailabilityModalOpen: false,
            tickX: []
        };
    }

    componentWillMount() {
        this.loadTickX();
    }

    componentWillUnmount() {
        this.setState({
            tickX: []
        });
    };

    // closeCoronaMessage = () => {
    //     this.setState({
    //         coronaMessageOpen: false
    //     })
    // }

    loadTickX = () => {
        this.setState({
            tickX: tickX[0]
        });
    };

    toggleCompareAvailabilityModal = (e) => {
        e.preventDefault();
        this.setState({
            compareAvailabilityModalOpen: !this.state.compareAvailabilityModalOpen
        });
    };

    toggleAccordion = (e) => {
        e.preventDefault();

        let collapses = {...this.state.collapses};
        const collapse = e.currentTarget.id;

        for (var key in collapses) {
            if (collapses.hasOwnProperty(key) && collapse !== key) {
                collapses[key] = false;
            }
        }

        collapses[collapse] = true;

        this.setState({collapses});
    };

    onEntering = () => {
        this.setState({status: 'Opening...'});
    };

    onEntered = () => {
        this.setState({status: 'Opened'});
    };

    onExiting = () => {
        this.setState({status: 'Closing...'});
    };

    onExited = () => {
        this.setState({status: 'Closed'});
    };

    render() {
        const {siteId, siteversion} = this.props;
        console.log(siteversion)
        const {phase} = this.props;

        const {compareAvailabilityModalOpen, tickX} = this.state;

        const collapses = {...this.state.collapses};

        if (Object.keys(tickX).length === 0)
            return true;

        return (
            <>
                <div className="section-intro">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                {phase === 'registration' &&
                                <h1 className="text-center">THE BOOK OF MORMON WILL RETURN<br/>
                                    TO THE PRINCE OF WALES THEATRE THIS AUTUMN</h1>
                                }

                                {phase === 'open' &&
                                <React.Fragment>
                                    <h4 className="mb-4 mt-4">PERFORMANCE UPDATE: For the latest information about performances please
                                        visit<br/><a className="text--orange" href="https://www.facebook.com/bookofmormonUK/" target="_blank"
                                                 rel="noreferrer noopener">The Book of Mormon Facebook page</a>.</h4>
                                    <h1>How to buy your tickets for London</h1>
                                </React.Fragment>
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>
                {phase === 'open' &&
                <>
                    <div className="section-intro section-sign-up">
                        <Container>
                            <Row>
                                <Col xs={12}>

                                    <div className="section-intro__wrapper">
                                        <div className="section-intro__wrapper-header">
                                            <h2>Our top ticket tip</h2>
                                        </div>
                                        <div className="section-intro__wrapper-body">
                                            <h3>Book Early and Enjoy...</h3>

                                            <ul>
                                                <li>TICKET EXCHANGE UP TO 48 HOURS BEFORE THE PERFORMANCE*</li>
                                                <li>NO BOOKING FEES ON SEATS BOOKED 8 WEEKS IN ADVANCE. <a
                                                    href="https://thebookofmormonmusical.com/london/tickets/?flow=70efdf2e"
                                                     rel="noopener noreferrer">BOOK HERE</a></li>
                                            </ul>

                                            <p className="sml">Valid for bookings made online, by phone or in person through official Prince of Wales Theatre Box Office. *£2 per ticket.</p>

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>


                    <div className="section-accordion">
                        <Container>
                            <Row>
                                <Col xs={12}>
                                    <div className="section-accordion__wrap">
                                        <button id="collapseA"
                                                className={`section-accordion__wrap-btn accordion-btn ${collapses.collapseA === true ? 'active' : ''}`}
                                                onClick={this.toggleAccordion}>
                                            Official Box Office
                                        </button>
                                        <Collapse
                                            isOpen={collapses.collapseA}
                                            onEntering={this.onEntering}
                                            onEntered={this.onEntered}
                                            onExiting={this.onExiting}
                                            onExited={this.onExited}
                                            className={`section-accordion__wrap-content accordion-content tickX a ${collapses.collapseA === true ? 'active' : ''} ${siteId}`}>

                                            <div className="table">
                                                <a className="table-row"
                                                   href={`/london/tickets/?flow=${tickX['london'][siteversion][0]['configId']}`}>
                                                    <div className="table-cell">1</div>
                                                    <div className="table-cell">
                                                        <div
                                                            dangerouslySetInnerHTML={{__html: tickX['london'][siteversion][0]['copy']}}/>
                                                        <span
                                                            dangerouslySetInnerHTML={{__html: tickX['london'][siteversion][0]['sub']}}></span>
                                                    </div>
                                                    <div className="table-cell">
                                                        <div className={`btn-buy GTM-BOMLondonLastMinAvail`}
                                                             id="versaID-London-LastMinAvail" alt="" src={booknowSml}/>
                                                    </div>
                                                </a>
                                                <a className="table-row"
                                                   href={`/london/tickets/?flow=${tickX['london'][siteversion][1]['configId']}`}>
                                                    <div className="table-cell">2</div>
                                                    <div className="table-cell">
                                                        <div
                                                            dangerouslySetInnerHTML={{__html: tickX['london'][siteversion][1]['copy']}}/>
                                                        <span
                                                            dangerouslySetInnerHTML={{__html: tickX['london'][siteversion][1]['sub']}}></span>
                                                    </div>
                                                    <div className="table-cell">
                                                        <div className={`btn-buy GTM-BOMLondonLastMinAvail`}
                                                             id="versaID-London-LastMinAvail" alt="" src={booknowSml}/>
                                                    </div>
                                                </a>
                                                <a className="table-row"
                                                   href={`/london/tickets/?flow=${tickX['london'][siteversion][2]['configId']}`}>
                                                    <div className="table-cell">3</div>
                                                    <div className="table-cell">
                                                        <div
                                                            dangerouslySetInnerHTML={{__html: tickX['london'][siteversion][2]['copy']}}/>
                                                        <span
                                                            dangerouslySetInnerHTML={{__html: tickX['london'][siteversion][2]['sub']}}></span>
                                                    </div>
                                                    <div className="table-cell">
                                                        <div className={`btn-buy GTM-BOMLondonGoodAvail`}
                                                             id="versaID-London-GoodAvail" alt="" src={booknowSml}/>
                                                    </div>
                                                </a>
                                                <a className="table-row"
                                                   href={`/london/tickets/?flow=${tickX['london'][siteversion][3]['configId']}`}>
                                                    <div className="table-cell">4</div>
                                                    <div className="table-cell">
                                                        <div
                                                            dangerouslySetInnerHTML={{__html: tickX['london'][siteversion][3]['copy']}}/>
                                                        <span
                                                            dangerouslySetInnerHTML={{__html: tickX['london'][siteversion][3]['sub']}}></span>
                                                    </div>
                                                    <div className="table-cell">
                                                        <div className={`btn-buy GTM-BOMLondonIncAvail`}
                                                             id="versaID-London-IncAvail" alt="" src={booknowSml}/>
                                                    </div>
                                                </a>
                                            </div>
                                        </Collapse>

                                        <button id="collapseC"
                                                className={`section-accordion__wrap-btn accordion-btn ${collapses.collapseC === true ? 'active' : ''}`}
                                                onClick={this.toggleAccordion}>
                                            Hotel Packages
                                        </button>
                                        <Collapse isOpen={collapses.collapseC}
                                                  className={`section-accordion__wrap-content accordion-content tickX c ${collapses.collapseC === true ? 'active' : ''}`}>
                                            <p className="alt">Book your dream theatre break today with Holiday Extras, the Official Short Breaks Provider for The Book of Mormon Musical! Enhance your experience by combining show tickets with a stay at a top London hotel nearby!</p>
                                            <p className='alt'> What's included with your short breaks package?</p>
                                            <ul style={{listStyle: "none"}}>
                                                <li style={{fontSize: '13px'}}>&#10003; Band A Tickets in the Stalls or the Dress Circle</li>
                                                <li style={{fontSize: '13px'}}>&#10003; London hotel stay nearby</li>
                                                <li style={{fontSize: '13px'}}>&#10003; Breakfast</li>
                                            </ul>
                                            <a className="btn btn-book-now" href="https://www.holidayextras.com/uk-short-breaks/theatre-breaks/the-book-of-mormon-musical-short-break.html?utm_source=ww_bookofmormon&utm_medium=referral&utm_term=clean_white_label"
                                               target="_blank"
                                               rel="noreferrer noopener">
                                                <span className="sr-only">Book Now</span>
                                            </a>
                                        </Collapse>

                                        <button id="collapseD"
                                                className={`section-accordion__wrap-btn accordion-btn ${collapses.collapseD === true ? 'active' : ''}`}
                                                onClick={this.toggleAccordion}>
                                            RUSH TICKETS
                                        </button>
                                        <Collapse isOpen={collapses.collapseD}
                                                  className={`section-accordion__wrap-content accordion-content tickX d ${collapses.collapseD === true ? 'active' : ''}`}>
                                            {/*<p className="alt">A limited number of tickets for each performance will be sold*/}
                                            {/*    through an on-the-day lottery at the theatre for £20 each. Entries accepted*/}
                                            {/*    2½ hours prior to each performance. Names drawn at random with a limit of*/}
                                            {/*    one entry per person and up to two tickets per winner. Tickets subject to*/}
                                            {/*    availability. All entries checked for duplication. Winners must be present*/}
                                            {/*    at the time of the draw and have valid ID.</p>*/}

                                            {/*<p className="alt">WEEKLY ONLINE LOTTERY. One additional pair of seats for each*/}
                                            {/*    performance will be made available at £20 per ticket via a weekly online*/}
                                            {/*    lottery. Entries accepted from 12 noon (GMT/BST) each Wednesday for*/}
                                            {/*    performances*/}
                                            {/*    the following week. <a*/}
                                            {/*        href="https://lottery.thebookofmormonmusical.com/terms.php">Terms*/}
                                            {/*        apply</a>*/}

                                            {/*    <a className="btn lotteryBtn"*/}
                                            {/*       href="https://lottery.thebookofmormonmusical.com/">*/}
                                            {/*        <span className="sr-only">Lottery</span>*/}
                                            {/*    </a>*/}
                                            {/*</p>*/}
                                            
                                            <p className="mb-2">For every performance, a limited number of £25 Rush tickets will be available from 10am in the TodayTix app.</p>
                                            <a  href="https://www.todaytix.com/london/shows/287-the-book-of-mormon" target="_blank" rel="noopener noreferrer">
                                                <img src={TodayTixLogo} alt="Today Tix" />
                                            </a>
                                        </Collapse>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </>
                }
                

                {phase === 'registration' &&
                <div className="section-sign-up">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-sign-up__wrap">
                                    <h2 className={`h3`}>SIGN UP TO RECEIVE THE LATEST NEWS AND TO FIND OUT WHEN TICKETS
                                        ARE RELEASED</h2>
                                    <SignUpForm
                                        className="sign-up-form"
                                        location="/london/"
                                        locationName="london"
                                        phase={phase}
                                        thankYouPage={true}
                                    />
                                    <p className="small-copy">
                                        By submitting your email address, you are agreeing to receive marketing
                                        emails about The Book of Mormon. You can opt out of receiving
                                        these emails at any time.
                                        <br/>To see full details of our privacy policy <Link
                                        to={`/london/privacy-policy/`}>click here</Link>.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }
                {phase === 'presale' &&
                <div className="section-sign-up space-below">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-sign-up__wrap orange-border">
                                    <h3>
                                        <img src={star} className="star" alt="" title=""/><br
                                        className="d-inline-block d-sm-none"/>PRINCE OF WALES THEATRE, LONDON<br
                                        className="d-inline-block d-sm-none"/>
                                        <img src={star} className="star" alt="" title=""/></h3>
                                    <div className="narrow">
                                        <p>&nbsp;</p>
                                        <p>Regrettably, due to the continuing uncertainties surrounding the Coronavirus
                                            pandemic all performances of The Book of Mormon at the Prince of Wales
                                            Theatre, London are currently suspended.</p>
                                        <p>&nbsp;</p>
                                        <p>We hope to be able to announce a return date shortly.</p>
                                        <p>&nbsp;</p>
                                        <p><strong>If you wish to be first to receive news of our reopening in the West
                                            End, please sign up for priority booking</strong></p>

                                    </div>
                                    <SignUpForm
                                        className="sign-up-form"
                                        location="/london/"
                                        locationName="london"
                                        phase={phase}
                                        thankYouPage={true}
                                    />
                                    <p className="small-copy">
                                        By submitting your email address, you are agreeing to receive marketing
                                        emails about The Book of Mormon. You can opt out of receiving
                                        these emails at any time.
                                        <br/>To see full details of our privacy policy <Link
                                        to={`/london/privacy-policy/`}>click here</Link>.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }
                {phase === 'open' &&
                <div className="section-show-info">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-show-info__wrap">
                                    <div className="row">
                                        <div className="section-show-info__wrap-map">
                                            <h2 className="d-lg-none">Show Info</h2>
                                            <div className={`map ${siteId}`}>
                                            </div>
                                        </div>

                                        <div className="section-show-info__wrap-content">
                                            <div className="content">
                                                <h2 className="d-none d-lg-block">Show Info</h2>
                                                <p>The Prince of Wales Theatre,<br/>
                                                    Coventry Street, London W1D 6AS</p>
                                                <a className="btn btn-google-maps"
                                                   href="https://www.google.co.uk/maps/place/Prince+of+Wales+Theatre/@51.51048,-0.1321102,17z/data=!4m5!3m4!1s0x487604d3cd2b4d57:0x8989ed2b7c5c37f8!8m2!3d51.5102263!4d-0.1321102"
                                                   target="_blank"
                                                   rel="noreferrer noopener">
                                                    <span className="sr-only">The Prince of Wales Theatre Map</span>
                                                </a>
                                                <p><strong>Running Time</strong></p>
                                                <p>2.5 hrs, one interval</p>
                                                <br />
                                                <p><strong>Parental advisory:</strong> Explicit language.</p>
<p><strong>Please note:</strong>&nbsp;This production contains strobe lighting, flashing lights and gunshots.&nbsp;</p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }

                {/*<div className={`coronaMsgLondon ${!this.state.coronaMessageOpen && 'd-none'}`}>*/}
                {/*    <div className="coronaMsgLondon--content">*/}
                {/*        <span className="coronaMsgLondon--closeBtn" role="button" tabIndex={0}*/}
                {/*              onKeyPress={this.closeCoronaMessage} onClick={this.closeCoronaMessage}>*/}
                {/*            <img src={closeBtn} alt="close" title="close"/>*/}
                {/*        </span>*/}
                {/*        <div className="scroller-helper">*/}
                {/*            <h3 className="text-uppercase">To all The Book of Mormon<br/>*/}
                {/*                <img src={star} className="star" alt="" title=""/> <span*/}
                {/*                    className="orange text-uppercase">Ticket Holders</span> <img src={star}*/}
                {/*                                                                                 className="star" alt=""*/}
                {/*                                                                                 title=""/></h3>*/}
                {/*            <p>&nbsp;</p>*/}
                {/*            <p>We are so sorry that in these testing and difficult times you are not able to attend our*/}
                {/*                show. All performances are suspended until the new year, whilst we wait for further*/}
                {/*                clarity from the government. There is nothing that you need to do if your performance*/}
                {/*                has been suspended.</p>*/}
                {/*            <p>&nbsp;</p>*/}
                {/*            <p>If you have booked directly with Delfont Mackintosh Theatres for an affected performance,*/}
                {/*                please be assured that they will contact you directly regarding next steps.</p>*/}
                {/*            <p>&nbsp;</p>*/}
                {/*            <p>As you can imagine, we have an unprecedented number of ticket orders which we are*/}
                {/*                processing in strict date order of performance. We ask for your patience and*/}
                {/*                understanding as the current circumstances means that it is impossible to process all*/}
                {/*                tickets within our usual 14 days.</p>*/}
                {/*            <p>&nbsp;</p>*/}
                {/*            <p>If you have booked via a ticket agent, they will also be in contact with you directly.*/}
                {/*                Please do not contact your credit card company as that will slow the process down and*/}
                {/*                put an additional burden on our box office and ticket agent teams.</p>*/}
                {/*            <p>&nbsp;</p>*/}
                {/*            <p>In order for us to serve our audiences the best we can, please do not get in touch with*/}
                {/*                your point of sale if you have booked for performances after May 31st, but please be*/}
                {/*                reassured that if we have to suspend future performances you will be directly contacted*/}
                {/*                by your theatre or ticket provider. We will continue to provide updates as additional*/}
                {/*                information becomes available.</p>*/}
                {/*            <p>&nbsp;</p>*/}
                {/*            <p><strong>We look forward to welcoming you back to The Book of Mormon as soon as we are*/}
                {/*                allowed to resume performances. In the meantime, please stay safe and healthy.</strong>*/}
                {/*            </p>*/}

                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <CompareAvailabilityModal
                    isOpen={compareAvailabilityModalOpen} toggleModal={this.toggleCompareAvailabilityModal}
                />
            </>
        );
    }
}

export default London;