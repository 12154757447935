import React, {Component} from 'react';
import {Container, Row, Col, Collapse} from 'reactstrap'
import Calendar from 'components/Calendar'
import moment from 'moment'
import {graphql, StaticQuery} from "gatsby";

class Zurich extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showCalendar: true,
            collapses: {
                collapseA: true,
                collapseB: false,
                collapseC: false,
                collapseD: false,
            },
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({showCalendar: true})
        }, 1000)
    }

    loadEvents = (events) => {
        let futureEvents = [];

        for (var key in events) {
            if (events[key].node.siteId === 'zurich') {
                for (var eventKey in events[key].node.items) {
                    if (moment().diff(events[key].node.items[eventKey].start, 'hours') <= 0 && events[key].node.items[eventKey].active) {
                        futureEvents.push(events[key].node.items[eventKey]);
                    }
                }
            }
        }
        
        return (
            <Calendar
                defaultDate={moment('2025-02-01', 'YYYY-MM-DD')}
                visibleRange={{
                    start: moment('2025-02-01', 'YYYY-MM-DD'),
                    end: moment('2025-03-01', 'YYYY-MM-DD')
                }}
                validRange={{
                    start: moment('2025-02-01', 'YYYY-MM-DD'),
                    end: moment('2025-03-01', 'YYYY-MM-DD')
                }}
                events={futureEvents}
            />
        )

    };

    toggleAccordion = (id, e) => {
        let collapses = {...this.state.collapses};
        const collapse = id;

        for (var key in collapses) {
            if (collapses.hasOwnProperty(key) && collapse !== key) {
                collapses[key] = false;
            }
        }

        collapses[collapse] = true;

        this.setState({collapses});
    }

    eventClick = (e) => {
        if (e.url) {
            window.open(e.url, "_blank");
            return false;
        }
    }

    onEntered = () => {
        this.setState({
            status: "Entered"
        })
    }

    eventRender = (event, element, view) => {
        if (event.className.includes('sold-out')) {
            element.addClass('isDisabled')
            return element;
        }
    }

    render() {
        const {phase, language} = this.props;
        const {showCalendar} = this.state;

        const collapses = {...this.state.collapses};

        return (
            <>
                <div className="section-intro">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                {phase === 'registration' &&
                                <h1 className="text-center">THE MORMONS ARE COMING TO ZURICH</h1>
                                }
                                {phase === 'open' && language === 'en' &&
                                <h1>HOW TO BUY YOUR TICKETS FOR ZURICH</h1>
                                }
                                {phase === 'open' && language === 'de' &&
                                <h1>SO KANNST DU TICKETS FÜR ZÜRICH KAUFEN:</h1>
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>

                {phase === 'registration' &&
                <div className="section-sign-up">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-sign-up__wrap">
                                    {phase === 'registration' && language === 'en' &&
                                    <>
                                        <h3>PERFORMANCES BEGIN 10 DECEMBER 2019<br/><br/><span>SIGN UP NOW AND GET ACCESS TO THE PRESALE</span>
                                        </h3>
                                        <a href="https://www.musical.ch/en/themormonsarecoming"
                                           rel="noreferrer noopener"
                                           target="_blank" className="btn registerNow-btn"><span className="sr-only">Register now</span></a>
                                    </>
                                    }
                                    {phase === 'registration' && language === 'de' &&
                                    <>
                                        <h3>AB 10. DEZEMBER 2019
                                            <br/><br/><span>MELDE DICH JETZT AN, UM ZUGANG ZUM<br/>EXKLUSIVEN PRESALE ZU ERHALTEN</span>
                                        </h3>
                                        <a href="https://www.musical.ch/de/themormonsarecoming"
                                           rel="noreferrer noopener"
                                           target="_blank" className="btn registerNow-btn"><span className="sr-only">Register now</span></a>
                                    </>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }

                {phase === 'presale' &&
                <div className="section-sign-up">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-sign-up__wrap">
                                    {language === 'en' &&
                                    <>
                                        <h2>THE BOOK OF MORMON IS COMING TO Theater 11 Zürich
                                        </h2>
                                        <br/>
                                        <h3 className="h2-presale">PUBLIC BOOKING</h3>
                                        <h3 className="highlight">OPENS 08:00AM<br/>
                                            Wednesday 6th March</h3>
                                    </>
                                    }
                                    {language === 'de' &&
                                    <>
                                        <h2>THE BOOK OF MORMON KOMMT INS THEATER 11 ZÜRICH</h2>
                                        <br/>
                                        <h3 className="h2-presale">DER VORVERKAUF</h3>
                                        <h3 className="highlight">STARTET UM 8.00 UHR<br/>
                                            MITTWOCH 6. MÄRZ</h3>
                                    </>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }
                {phase === 'open' &&
                <>
                    <div className="section-intro section-sign-up">
                        <Container>
                            <Row>
                                <Col xs={12}>

                                    <div className="section-intro__wrapper">
                                        <div className="section-intro__wrapper-header">
                                            {language === 'en' &&
                                            <h2>TICKET TIPS</h2>
                                            }
                                            {language === 'de' &&
                                            <h2>KAUFTIPPS</h2>
                                            }
                                        </div>
                                        {language === 'en' &&
                                        <div className="section-intro__wrapper-body">
                                            <h3>WE KNOW IT CAN BE HARD SOMETIMES FINDING THE RIGHT TICKET SO HERE ARE A
                                                FEW HANDY TIPS:</h3>

                                            <ul>
                                                <li>SIGN-UP <a href="#footer">HERE</a> to receive latest news including
                                                    priority seat
                                                    releases
                                                </li>
                                                <li>For a wider range of prices and seats, try midweek performances</li>
                                            </ul>
                                        </div>
                                        }
                                        {language === 'de' &&
                                        <div className="section-intro__wrapper-body">
                                            <h3>Manchmal kann es schwierig sein, das richtige Ticket zu finden. Deshalb geben wir dir hier ein paar Tipps:</h3>

                                            <ul>
                                                <li>Registriere dich <a href="#footer">HIER</a>, um die neusten News zu erhalten</li>
                                                <li>Für Vorstellungen unter der Woche sind tendenziell attraktivere Preise und eine grösse Platzauswahl verfügbar. </li>
                                            </ul>
                                        </div>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="section-accordion" id="section-accordion">
                        <Container>
                            <Row>
                                <Col xs={12}>
                                {showCalendar &&
                                            <>
                                                <ul className="keyList">
                                                    <li>
                                                        <div className="keyList__key">KEY:</div>
                                                    </li>
                                                    <li>
                                                        <div className="keyList__available">AVAILABLE</div>
                                                    </li>
                                                    <li>
                                                        <div className="keyList__limited">LIMITED</div>
                                                    </li>
                                                    <li>
                                                        <div className="keyList__sold-out">SOLD OUT</div>
                                                    </li>
                                                </ul>

                                                {this.loadEvents(this.props.events.allEventsJson.edges)}
                                            </>

                                            }
                                    <div className="section-accordion__wrap">

                                        <button
                                            className={`section-accordion__wrap-btn accordion-btn tickX ${collapses.collapseA === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseA', e)}>
                                            PHONE BOOKINGS
                                        </button>
                                        <Collapse isOpen={collapses.collapseA}
                                                  className={`section-accordion__wrap-content accordion-content d ${collapses.collapseA === true ? 'active' : ''}`}>
                                                  <p>To order by phone you call 0900 800 800 (CHF 1.19/min.): Monday&ndash;Sunday 8am&ndash;8pm.</p>
                                            {/* <p><strong>BOOK BY PHONE</strong></p>

                                            <p>Telephone Booking* <a href="tel:03330096690">03330 096 690</a></p>
                                            <p className="small-copy">*Calls charged at standard geographic rates and count towards any inclusive minutes in the same way as calls to 01 and 02 numbers.</p> */}

                                        </Collapse>

                                        <button
                                            className={`section-accordion__wrap-btn accordion-btn tickX ${collapses.collapseC === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseC', e)}>
                                            Group Bookings
                                        </button>
                                        <Collapse isOpen={collapses.collapseC}
                                                  className={`section-accordion__wrap-content accordion-content has-inner c ${collapses.collapseC === true ? 'active' : ''}`}>
                                            <div className="section-accordion__wrap-content-inner">
                                            <p>For groups of 10+ please call +41 44 265 56 56 (Mon-Fri, 9-12) or fill out the&nbsp;<a href="https://www.musical.ch/en/contact" target="_blank" rel="noopener noreferrer">contact form</a>.</p>
                                                {/* <p>Group Bookings** <a href="tel:03330095388">0333 009 5388</a></p>
                                                <p>**Calls charged at local rate.</p> */}

                                            </div>
                                        </Collapse>

                                        <button
                                            className={`section-accordion__wrap-btn accordion-btn tickX ${collapses.collapseB === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseB', e)}>
                                            ACCESS PERFORMANCES
                                        </button>
                                        <Collapse isOpen={collapses.collapseB}
                                                  className={`section-accordion__wrap-content accordion-content has-inner b ${collapses.collapseB === true ? 'active' : ''}`}>
                                            <div className="section-accordion__wrap-content-inner">
                                            <p>For access enquiries or wheelchair bookings please call +41 44 265 56 56 (Mon-Fri, 9-12) or fill out the&nbsp;<a href="https://www.musical.ch/en/contact" target="_blank" rel="noopener noreferrer">contact form</a>.</p>

                                            </div>
                                        </Collapse>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </>
                }
                {phase === 'open' &&
                <div className="section-show-info">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-show-info__wrap">
                                    <div className="row">
                                        <div className="section-show-info__wrap-map">
                                            <h2 className="d-lg-none">Show Info</h2>
                                            <div className={`map zurich`}>
                                            </div>
                                        </div>

                                        <div className="section-show-info__wrap-content">
                                            <div className="content">
                                                <h2 className="d-none d-lg-block">Show Info</h2>
                                                {language === 'en' &&
                                                <>
                                                    <h3>4 - 23 FEBRUARY 2025</h3>
                                                    <p>Thurgauerstrasse 7, 8050 Zürich, Switzerland</p>
                                                    <a className="btn btn-google-maps"
                                                       href="https://www.google.com/maps/place/Theater+11/@47.4107022,8.5497464,15z/data=!4m6!3m5!1s0x47900a83d0a835bf:0x5b4c9a62f74490f1!8m2!3d47.4107022!4d8.5497464!16s%2Fg%2F11j0htgd1d?entry=ttu"
                                                       target="_blank"
                                                       rel="noreferrer noopener">
                                                        <span className="sr-only">Thurgauerstrasse 7, 8050 Zürich, Switzerland</span>
                                                    </a>

                                                    <h3>Performance times</h3>
                                                    <p>TUESDAY - SATURDAY 7.30pm</p>
                                                    <p>SATURDAY 2.30pm</p>
                                                    <p>SUNDAY 1.30pm &amp; 6.30PM</p>

                                                    <h3 className="mt-2">RUNNING TIME</h3>
                                                    <p>2 hours 20 minutes, including travel</p>
                                                    <br />
                                                <p><strong>Parental advisory:</strong> Explicit language.</p>
<p><strong>Please note:</strong>&nbsp;This production contains strobe lighting, flashing lights and gunshots.&nbsp;</p>
                                                </>
                                                }
                                                {language === 'de' &&
                                                <>
                                                    <h3>Ab 10. Dezember 2019</h3>
                                                    <p>Theater 11 Zürich, Thurgauerstrasse 7, 8050 Zürich</p>

                                                    <a className="btn btn-google-maps"
                                                       href="https://goo.gl/maps/qmFfcGxRdaA2"
                                                       target="_blank"
                                                       rel="noreferrer noopener">
                                                        <span className="sr-only">Theater 11 Zürich</span>
                                                    </a>

                                                    <h3>Vorstellungen</h3>
                                                    <p>Dienstag, Donnerstag, Freitag, Samstag 19.30 Uhr</p>
                                                    <p>Mittwoch, Sonntag 18.30 Uhr</p>
                                                    <p>Samstag 14.30 Uhr</p>
                                                    <p>Sonntag 13.30 Uhr</p>

                                                    <h3 className="mt-2">Dauer</h3>
                                                    <p>2 Stunden 20 Minuten</p>
                                                    <p className="med">Altersempfehlung: The Book of Mormon enthält Ausdrücke, die für ein jüngeres Publikum nicht angemessen sind. Der Besuch des Musicals liegt im Ermessen der Erziehungsberechtigten. Kinder unter 3 Jahren haben keinen Zutritt ins Theater 11 Zürich.</p>
                                                </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }
            </>
        );
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
        query {
            allEventsJson {
                edges {
                    node {
                        siteId
                          items {
                            url
                            start
                            title
                            className
                            active
                            type
                          }
                    }
                }
            }
        }`}
        render={data => (
            <Zurich events={data}
                    multiLang={props.multiLang}
                    phase={props.phase}
                    siteId={props.siteId}
                    language={props.language}
            />
        )}
    />
)