import React, {Component} from 'react'
import {Row, Col, Container} from 'reactstrap'
import VideoHeader from 'components/VideoHeader'
import SpinnerHeader from 'components/SpinnerHeader'
import clickHereImg from 'images/buttons/click-here-2.png'
import {window} from 'browser-monads'

class TicketsTemplate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            configId: null,
        }
    }

    componentDidMount() {
        const configId = this.urlParam('flow');

        this.setState({configId})

        this.handleScriptLoad();
    }
    
    urlParam = (name) => {
        /* eslint-disable */
        let results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
        /* eslint-enable */

        if (results == null) {
            return null;
        } else {
            return decodeURI(results[1]) || 0;
        }
    };

    handleScriptLoad = () => {
        if (typeof window.tx !== 'undefined') {
            let widgetIdName

            if (this.props.data.siteId === "manchester") {
                widgetIdName = 'BoMmanchester'
            }
            if (this.props.data.siteId === "london") {
                widgetIdName = 'BoMlondon'
            }

            if (this.props.data.siteId === "sunderland") {
                widgetIdName = 'BoMsunderland'
            }

            if (this.props.data.siteId === "bradford") {
                widgetIdName = 'BoMbradford'
            }

            if (this.props.data.siteId === "edinburgh") {
                widgetIdName = 'BoMedinburgh'
            }

            if (this.props.data.siteId === "glasgow") {
                widgetIdName = 'BoMglasgow'
            }

            if (this.props.data.siteId === "sunderland") {
                widgetIdName = 'BoMsunderland'
            }
            if (this.props.data.siteId === "hull") {
                widgetIdName = 'BoMhull'
            }
            if (this.props.data.siteId === "aberdeen") {
                widgetIdName = 'BoMaberdeen' //
            }
            if (this.props.data.siteId === "birmingham") {
                widgetIdName = 'BoMbirmingham' //
            }
            if (this.props.data.siteId === "plymouth") {
                widgetIdName = 'BoMplymouth'
            }
            if (this.props.data.siteId === "dublin") {
                widgetIdName = 'BoMdublin' //
            }
            if (this.props.data.siteId === "nottingham") {
                widgetIdName = 'BoMnottingham' //
            }
            if (this.props.data.siteId === "newcastle") {
                widgetIdName = 'BoMnewcastle' //
            }
            if (this.props.data.siteId === "norwich") {
                widgetIdName = 'BoMnorwich' //
            }
            if (this.props.data.siteId === "liverpool") {
                widgetIdName = 'BoMliverpool' //
            }
            if (this.props.data.siteId === "bristol") {
                widgetIdName = 'BoMbristol' //
            }
            if (this.props.data.siteId === "zurich") {
                widgetIdName = 'BoMzurich' //
            }
            if (this.props.data.siteId === "cardiff") {
                widgetIdName = 'BoMcardiff' //
            }

            let configId = this.urlParam('flow');

            // if (configId === null) {
            //     configId = 'c4bc873b';
            // }

            if (configId === 'JGFbUjjd' && this.props.data.siteId === 'sunderland') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Sunderland_All_Dates'
                });
            } else if (configId === 'vjgVrNIA' && this.props.data.siteId === 'sunderland') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Sunderland_Best_Seats'
                });
            } else if (configId === 'r0EuD2Nf' && this.props.data.siteId === 'sunderland') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Sunderland_Under_30_Pounds'
                });
            } else if (configId === 'bWy5sKuH' && this.props.data.siteId === 'edinburgh') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Edinburgh_Performance_Date'
                });
            } else if (configId === 'vICigbuF' && this.props.data.siteId === 'edinburgh') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Edinburgh_Under_30_Pounds'
                });
            } else if (configId === 'sfi3AjS8' && this.props.data.siteId === 'edinburgh') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Edinburgh_Best_Seats'
                });
            } else if (configId === 'MIddqaVz' && this.props.data.siteId === 'edinburgh') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Edinburgh_15_Pounds'
                });
            } else if (configId === 'zr7gLT_u' && this.props.data.siteId === 'liverpool') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Liverpool_Performance_Date'
                });
            } else if (configId === 'std-BL36' && this.props.data.siteId === 'liverpool') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Liverpool_Under_30_Pounds'
                });
            } else if (configId === '9yMpvODZ' && this.props.data.siteId === 'liverpool') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Liverpool_Best_Seats'
                });
            } else if (configId === '8LUow4Dl' && this.props.data.siteId === 'glasgow') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Glasgow_Performance_Date'
                });
            } else if (configId === 'vAoNFyKu' && this.props.data.siteId === 'glasgow') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Glasgow_Under_30_Pounds'
                });
            } else if (configId === 'f11oNS7d' && this.props.data.siteId === 'glasgow') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Glasgow_Best_Seats'
                });
            } else if (configId === 'TFlUey98i' && this.props.data.siteId === 'london') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'London_By_Performance'
                });
            } else if (configId === 'cg6KQpEs' && this.props.data.siteId === 'london') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'London_No_Fees_8_weeks'
                });
            } else if (configId === 'Grdl7qnq' && this.props.data.siteId === 'london') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'London_No_Fees_12_weeks'
                });
            } else if (configId === 'v7Hr6FdX' && this.props.data.siteId === 'london') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'London_Increased_5_9_weeks'
                });
            } else if (configId === 'FAMWFQ9k' && this.props.data.siteId === 'london') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'London_Increased_5_11_weeks'
                });
            } else if (configId === '27ii-87zo' && this.props.data.siteId === 'london') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'London_Under_30'
                });
            } else if (configId === 'J8MyT3aFQ' && this.props.data.siteId === 'london') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'London_Best_Seat'
                });
            } else if (configId === '9P0NyDt1' && this.props.data.siteId === 'bradford') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Bradford_Best_Prices'
                });
            } else if (configId === 'LTFVlf6b' && this.props.data.siteId === 'bradford') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Bradford_Best_Prices'
                });
            } else if (configId === 'Ph0PfLHK' && this.props.data.siteId === 'bradford') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Bradford_Best_Seats'
                });
            } else if (configId === 'ieIDDCRX' && this.props.data.siteId === 'bradford') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Bradford_Best_Availilbility'
                });
            } else if (configId === 'zlSKE58b' && this.props.data.siteId === 'bradford') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Bradford_Meta_Organic'
                });
            } else if (configId === 'k2dJzMKi' && this.props.data.siteId === 'bradford') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Bradford_Meta_Paid'
                });
            } else if (configId === 'TN3YUpUD' && this.props.data.siteId === 'bradford') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Bradford_Google_Organic'
                });
            } else if (configId === 'vaeqITc2' && this.props.data.siteId === 'bradford') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Bradford_Google_Paid'
                });
            } else if (configId === 'Esumg9y0k9' && this.props.data.siteId === 'manchester') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Manchester_By_Performance'
                });
            } else if (configId === 'qWlDIoVnU8' && this.props.data.siteId === 'manchester') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Manchester_Under_30'
                });
            } else if (configId === 'KSXQhxDP4E' && this.props.data.siteId === 'manchester') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Manchester_Best_Seat'
                });
            }
            else if (configId === 'Tsz24rJX' && this.props.data.siteId === 'hull') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Hull_Best_Prices'
                });
            }
            else if (configId === 'ggwHBDXE' && this.props.data.siteId === 'hull') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Hull_Best_Seats'
                });
            }
            else if (configId === 'giLdvATr' && this.props.data.siteId === 'hull') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Hull_Best_Matinees'
                });
            }
            else if (configId === '5dw6Pp7e' && this.props.data.siteId === 'hull') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Hull_Meta_Organic'
                });
            }
            else if (configId === '9yB9WkQZ' && this.props.data.siteId === 'hull') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Hull_Meta_Paid'
                });
            }
            else if (configId === 'EeeXcqAs' && this.props.data.siteId === 'hull') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Hull_Google_Paid'
                });
            }
            else if (configId === '4MnGUBXX' && this.props.data.siteId === 'hull') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Hull_Google_Organic'
                });
            }
            else if (configId === 'aeQSA1Ce' && this.props.data.siteId === 'plymouth') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Plymouth_Best_Prices'
                });
            }
            else if (configId === 'S21fWVS7' && this.props.data.siteId === 'plymouth') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Plymouth_Best_Seats'
                });
            }
            else if (configId === 'nqzfuOcp' && this.props.data.siteId === 'plymouth') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Plymouth_Best_Matinees'
                });
            }
            else if (configId === 'U8uOheWX' && this.props.data.siteId === 'plymouth') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Plymouth_Meta_Organic'
                });
            }
            else if (configId === 'ZCCM7LeS' && this.props.data.siteId === 'plymouth') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Plymouth_Meta_Paid'
                });
            }
            else if (configId === 'CEql0HWz' && this.props.data.siteId === 'plymouth') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Plymouth_Google_Organic'
                });
            }
            else if (configId === '83kwut4X' && this.props.data.siteId === 'plymouth') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'tickx',
                    'TickXName': 'Plymouth_Google_Paid'
                });
            }
            
           
        

            this.setState({configId})
            console.log(widgetIdName)
            if (widgetIdName) {

                window.tx({
                    widgetId: widgetIdName,
                    configId: configId,
                    selector: "#tickx-widget"
                });
            }
        }
    }

    render() {
        const title = this.props.data.metaTitle
        console.log(title)
        console.log(this.props)
        
        return (
            <>
                <div className="subPages">
                    <Container>
                        {this.state.configId !== null &&
                        <>
                            {console.log(this.state.configId)}
                            {this.state.configId === 'd97f445a' || this.state.configId === 'a54db23f' ?
                                <VideoHeader/>
                                :
                                <SpinnerHeader clickHereImg={clickHereImg}/>
                            }
                        </>
                        }
                        <div className="subPages__topper">
                            <h1>{title}</h1>
                        </div>
                    </Container>

                    <div className="subPages__hero">
                    </div>

                    <Container>
                        <div className="subPages__content">
                            <Row>
                                <Col xs={12}>
                                    <div id="tickx-widget"/>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </>
        )
    }
}

export default TicketsTemplate 