import React, {Component} from "react"
import GalleryPage from 'components/Pages/GalleryPage'

class GalleryTemplate extends Component {
    render() {
        const data = this.props.data
        const {siteversion, languageCode} = this.props

        return (
            <GalleryPage
                title={data.title}
                phase={data.phase}
                siteversion={siteversion}
                siteId={data.siteId}
                language={data.language}
                multiLang={data.multiLang}
            />
        )
    }
}

export default GalleryTemplate