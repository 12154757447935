import React, {Component} from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe"
import {FormGroup, Input} from 'reactstrap'
import {navigate} from 'gatsby'
import styled from "styled-components";
import {media} from "utils/Media"
import rightBracket from "images/right-bracket.png"

const url = "https://thebookofmormonmusical.us7.list-manage.com/subscribe/post?u=b3418623fb6b0271c9d11f231&amp;id=ca0b213bdc&amp;f_id=00b8c1e3f0";

const FormWrapper = styled.div`
  position: relative;

  @media ${media.lg} {
    &::before {
      content: "";
      position: absolute;
      left: -40px;
      top: 0;
      height: 100%;
      width: 40px;
      background: url("${rightBracket}") no-repeat center center;
      background-size: contain;
      transform: rotate(180deg);
    }

    &::after {
      content: "";
      position: absolute;
      right: -40px;
      top: 0;
      height: 100%;
      width: 40px;
      background: url("${rightBracket}") no-repeat center center;
      background-size: contain;
    }
  }

  input, select {
    background: #E1E1E1;
    border: 1px solid #CF7033;
    border-radius: 0;
    color: black;
    height: 50px;
    width: 100%;
    margin-bottom: 0.5rem;

    @media ${media.sm} {
      margin-bottom: 0;
      height: 40px;
    }
    @media ${media.lg} {
      height: 50px;
    }

    &.w-half {
      @media ${media.sm} {
        width: 50%;
        &:nth-of-type(1) {
          margin-right: 5px;
        }

        &:nth-of-type(2) {
          margin-left: 5px;
        }
      }
    }

    &.w-40 {
      @media ${media.sm} {
        width: 40%;
        margin-right: 10px;
      }
    }
  }

  select {
    color: #666;
  }

  .form-group {
    margin-bottom: 0;
    @media ${media.sm} {
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .btn {
    width: 100%;
    height: 40px;
    padding: 11px 0px;
    font-size: 1.125rem;
    @media ${media.sm} {
      width: 140px;
      padding: 9px 10px;
      font-size: 1.5rem;
    }
    @media ${media.lg} {
      width: 233px;
      height: 50px;
      padding: 13px 10px;
    }
  }
`

class NewSignUpForm extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            FNAME: '',
            LNAME: '',
            EMAIL: '',
            region: ''
        };
    }

    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;

        // Update value in state
        this.setState({
            [name]: value,
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        return {
            FNAME: this.state.FNAME,
            LNAME: this.state.LNAME,
            EMAIL: this.state.EMAIL,
            REGION: this.state.region
        };
    };

    formatMessage = (message) => {
        if (message.includes("2 - Please enter a value")) {
            return "Please enter your first name."
        }

        if (message.includes("3 - Please enter a value")) {
            return "Please enter your last name."
        }

        if (message.includes("0 - Please enter a value")) {
            return "Please enter your email address."
        }

        if (message.includes("1 - Please enter a value")) {
            return "Please select your region."
        }

        if (message.includes("An email address must contain")) {
            return "Please enter a valid email address."
        }

        if (message.includes("The domain portion of the email address is invalid")) {
            return "Please enter a valid email address."
        }

        if (message.includes("This email cannot be added to this list")) {
            return "This email address is already registered. Please enter a different email address."
        }

        return message
    };

    render() {
        const SubmitButton = ({onValidated, submitBtnCopy, city}) => {
            const submit = () => {
                onValidated({
                    FNAME: this.state.FNAME,
                    LNAME: this.state.LNAME,
                    EMAIL: this.state.EMAIL,
                    REGION: this.state.region
                });
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  'event': 'sign-up',
                  'enhanced_conversion_data': {
                    "email": this.state.EMAIL,
                    "city": this.props.city
                  }
                });
            };
            return (
                <Input
                    className={`btn btn-orange`}
                    type="submit"
                    value={submitBtnCopy}
                    onClick={submit}
                    id={city === 'dublin' && 'dublin-sign-up'}
                />
            )
        };

        const submitBtnCopy = this.props.submitBtnCopy ? this.props.submitBtnCopy : 'Sign Up';

        let color = "white";

        if ((this.props.siteId === 'sunderland' || this.props.siteId === '/') && this.props.phase === 'registration') {
            color = "black"
        }

        return (
            <MailchimpSubscribe
                url={url}
                render={({subscribe, status, message}) => (
                    <FormWrapper>
                        {this.props.title && (
                            <h2 className="h2 text--orange mb-2">Sign up for the latest news</h2>
                        )}
                        <p className="mb-5 text-center">Yes! I would like to receive updates, special offers, and other information
                            from The Book of Mormon musical.</p>
                        <div className="form-group">
                            <Input
                                type="text"
                                name="FNAME"
                                id="FNAME"
                                className="w-half"
                                placeholder="First Name"
                                value={this.state.FNAME}
                                onChange={e => {
                                    this.handleChange(e)
                                }}
                            />
                            <Input
                                type="text"
                                name="LNAME"
                                id="LNAME"
                                className="w-half"
                                placeholder="Last Name"
                                value={this.state.LNAME}
                                onChange={e => {
                                    this.handleChange(e)
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <Input
                                type="email"
                                name="EMAIL"
                                id="EMAIL"
                                className="w-40"
                                placeholder="E-mail Address"
                                value={this.state.EMAIL}
                                onChange={e => {
                                    this.handleChange(e)
                                }}
                            />

                            <Input
                                type="select"
                                name="region"
                                id="region"
                                className="w-40"
                                onChange={e => {
                                    this.handleChange(e)
                                }}
                            >
                                <option value="">Region</option>
                                <option value="East">East</option>
                                <option value="London">London</option>
                                <option value="Midlands">Midlands</option>
                                <option value="YorkshireHumberside">Yorkshire & Humberside</option>
                                <option value="North East">North East</option>
                                <option value="North West">North West</option>
                                <option value="N. Ireland">N. Ireland</option>
                                <option value="Rep of Ireland">Rep. of Ireland</option>
                                <option value="Scotland">Scotland</option>
                                <option value="South East">South East</option>
                                <option value="South West">South West</option>
                                <option value="Wales">Wales</option>
                                <option value="Zurich">Zurich</option>
                                <option value="Other">Other</option>
                            </Input>

                            <SubmitButton
                                onValidated={formData => subscribe(formData)}
                                submitBtnCopy={submitBtnCopy}
                                city={this.props.city}
                            />

                        </div>
                        {status === "sending" && <div style={{color: color}}>sending...</div>}
                        {status === "error" && (
                            <div
                                style={{color: "red"}}
                                dangerouslySetInnerHTML={{__html: this.formatMessage(message)}}
                            />
                        )}
                        {status === "success" && (
                            this.props.thankYouPageSlug
                                ?
                                navigate(this.props.thankYouPageSlug)
                                :
                                <div
                                    style={{color: "green"}}
                                    dangerouslySetInnerHTML={{__html: message}}
                                />
                        )
                        }
                    </FormWrapper>
                )}
            />
        );
    }
}

export default NewSignUpForm;

NewSignUpForm.defaultProps = {
    title: true
}