import React, { useEffect, useState } from "react";
import { graphql, Link, navigate, useStaticQuery } from "gatsby";
import addToMailchimp from "gatsby-plugin-mailchimp";
import { useForm } from "react-hook-form";

import Layout from "components/Layout/Layout";
import DesktopHeaderImage from "../images/BOM_TOUR_MAY22_NEWCASTLE_Desktop_1200x450_30213SA.jpg";
import MobileHeaderImage from "../images/BOM_TOUR_MAY22_NEWCASTLE_Mobile_400x400_30213SA.jpg";

const FanPerformanceTemplate = (props) => {
  const { data } = props;
  useEffect(() => {

  })
  return (
    <Layout data={data} displayFooter={false}>
      <div className="performance__header">
        <div className="images">
          <div className="mobile">
            <img src={MobileHeaderImage} alt="" />
          </div>
          <div className="desktop">
          <img src={DesktopHeaderImage} alt="" />
          </div>
        </div>
        <div className="wrapper">
          <h1>NEWCASTLE THEATRE ROYAL</h1>
          <h2>EXTRA FAN PREVIEW ADDED – 14 JUNE 2022 @ 7.30pm</h2>
          <p>
            Due to overwhelming demand in Newcastle, an additional
            performance has been added on Tuesday 14 June at 7.30pm. Tickets for
            this performance will be priced at £15 and will be available to fans
            signing up below. Seats go on
            sale on Tuesday 7 June at 10am. Registration closes Monday 6 June at
            10am.
          </p>
        </div>
      </div>
      <Form />
      <div className="performance__legal">
          <h5>Terms &amp; conditions</h5>
          <p>Registration alone does not guarantee entrants a ticket to the £15 fan preview. Registration provides access to a personal non-transferable priority booking link. Any remaining seats will be made available to the general public from 10am Friday 10 June.  Maximum 2 tickets per booking. </p>
          <p>Fan preview tickets non-exchangeable/refundable on existing tickets</p>
      </div>
    </Layout>
  );
};

export default FanPerformanceTemplate;

const Form = () => {
    const [success, setSuccess] = useState(false)
    const [error, hasErrors] = useState(false)
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onSubmit",
  });
  function onSubmit(data, e) {
    e.preventDefault()
    console.log(data)
    addToMailchimp(data.email, {
        FIRSTNAME: data.firstName,
        SECONDNAME: data.lastName,
    }).then(message => {
      // I recommend setting data to React state
      // but you can do whatever you want (including ignoring this `then()` altogether)
      console.log(message.msg)
      if (message.result === 'error') {
        hasErrors(true)
      } else {
        setSuccess(true)
        navigate('/thank-you', {
          state: {
            success,
            name: data.firstname,
          },
        })
      }
    })
  }
  return (
    <div className="performance__form">
      <h3>SIGN UP FOR TICKETS</h3>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="form-group">
          <section>
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              placeholder="First Name"
              id="firstName"
              name="firstName"
              className={errors.firstName ? 'error' : ''}
              {...register("firstName", {
                required: "Your first name is required",
              })}
            />
          </section>
          <section>
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              placeholder="Last Name"
              id="lastName"
              name="lastName"
              className={errors.lastName ? 'error' : ''}
              {...register("lastName", {
                required: "Your last name is required",
              })}
            />
          </section>
        </div>
        <label htmlFor="email">Email</label>
        <input
          type="email"
          placeholder="Email"
          id="email"
          name="email"
          className={errors.email ? 'error' : ''}
          {...register("email", {
            required: "An email is required",
            pattern: {
              value:
                /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              message: "Invalid email address",
            },
          })}
        />
        <div className="button">
          <button
            type="submit"
            className="btn btn-footer-form__submit"
          ></button>
        </div>
      </form>
      {Object.keys(errors).length === 0 ? (
          ''
        ) : (
            <div className="performance__error">
            {errors.firstName && (
              <ErrorMessage message={errors.firstName.message} />
            )}
            {errors.lastName && (
              <ErrorMessage message={errors.lastName.message} />
            )}
            {errors.email && <ErrorMessage message={errors.email.message} />}
            {errors.check && <ErrorMessage message={errors.check.message} />}
          </div>
        )}
        {error ? (
          <div className="performance__error">
            <ErrorMessage message="This email is already in use" />
          </div>
        ) : (
          ''
        )}
    </div>
  );
};

const ErrorMessage = ({ message }) => (
    <div className="errors">
      {/* <ErrorIcon /> */}
      <p>{message}</p>
    </div>
  )