import React, { Component } from "react";
import { Container, Row, Col, Collapse } from "reactstrap";
import SignUpForm from "components/SignUpForm";
import { Link } from "gatsby";
import Calendar from "components/Calendar";
import { StaticQuery, graphql } from "gatsby";
import moment from "moment";
import star from "images/star.svg";

class Glasgow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showCalendar: true,
      coronaMessageOpen: false,
      collapses: {
        collapseA: true,
        collapseB: false,
        collapseC: false,
        collapseD: false,
      },
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ showCalendar: true });
    }, 1000);
  }

  // closeCoronaMessage = () => {
  //     this.setState({
  //         coronaMessageOpen: false
  //     })
  // }

  loadEvents = (events) => {
    let futureEvents = [];

    for (var key in events) {
      if (events[key].node.siteId === "glasgow") {
        for (var eventKey in events[key].node.items) {
          if (
            moment().diff(events[key].node.items[eventKey].start, "hours") <=
              0 &&
            events[key].node.items[eventKey].active
          ) {
            futureEvents.push(events[key].node.items[eventKey]);
          }
        }
      }
    }

    return (
        <Calendar
        events={futureEvents}
        defaultDate={moment("2025-05-13", "YYYY-MM-DD")}
        visibleRange={{
          start: moment("2025-05-01", "YYYY-MM-DD"),
          end: moment("2025-06-30", "YYYY-MM-DD"),
        }}
        validRange={{
          start: moment("2025-05-01", "YYYY-MM-DD"),
          end: moment("2025-06-30", "YYYY-MM-DD"),
        }}
      />
      
    );
  };

  toggleAccordion = (id, e) => {
    let collapses = { ...this.state.collapses };
    const collapse = id;

    for (var key in collapses) {
      if (collapses.hasOwnProperty(key) && collapse !== key) {
        collapses[key] = false;
      }
    }

    collapses[collapse] = true;

    this.setState({ collapses });
  };

  render() {
    const { phase } = this.props;
    const { showCalendar } = this.state;

    const collapses = { ...this.state.collapses };

    return (
      <>
        <div className="section-intro">
          <Container>
            <Row>
              <Col xs={12}>
                {phase === "registration" && (
                  <h1 className="text-center">
                    THE MORMONS ARE COMING TO Glasgow
                  </h1>
                )}

                {phase === "open" && (
                  <h1>How to buy your tickets for Glasgow</h1>
                )}
              </Col>
            </Row>
          </Container>
        </div>

        {phase === "open" && (
          <>
            <div className="section-intro section-sign-up">
              <Container>
                <Row>
                  <Col xs={12}>
                    <div className="section-intro__wrapper">
                      <div className="section-intro__wrapper-header">
                        <h2>TICKET TIPS</h2>
                      </div>
                      <div className="section-intro__wrapper-body brown">
                        {/* <p>Due to the current uncertainties surrounding Covid-19 the 2020 season at the Theatre Royal, Glasgow has had to be postponed.</p>
                                            <p>&nbsp;</p>
                                            <p>Thankfully, we have been able to reschedule for 4 – 28 August 2021 and are delighted to be able to announce that remaining unsold seats for 2021 are now on sale.</p>
                                            <p>&nbsp;</p>
                                            <p>Please note: All existing 2020 ticketholders will be contacted by the theatre and arrangements made to exchange their bookings into 2021.</p> */}
                        <h3>
                          WE KNOW IT CAN BE HARD SOMETIMES FINDING THE RIGHT
                          TICKET SO HERE ARE A FEW HANDY TIPS:
                        </h3>

                        <ul>
                          {/*<li>FINAL WEEKS now on sale</li>*/}
                          <li>
                            SIGN-UP <a href="#footer">HERE</a> to receive latest
                            news including priority seat releases
                          </li>
                          <li>
                            For a wider range of prices and seats, try midweek
                            performances
                          </li>
                          {/*<li>For access performances <a href="#section-accordion" onClick={(e) => this.toggleAccordion('collapseB', e)}>click here</a></li>*/}
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>

            <div className="section-accordion" id="section-accordion">
              <Container>
                <Row>
                  <Col xs={12}>
                    {showCalendar && (
                      <>
                        <h2 className="py-3">ONLINE BOOKINGS</h2>
                        <ul className="keyList">
                          <li>
                            <div className="keyList__key">KEY:</div>
                          </li>
                          <li>
                            <div className="keyList__available">AVAILABLE</div>
                          </li>
                          <li>
                            <div className="keyList__limited">LIMITED</div>
                          </li>
                          <li>
                            <div className="keyList__sold-out">SOLD OUT</div>
                          </li>
                        </ul>

                        {this.loadEvents(this.props.events.allEventsJson.edges)}
                      </>
                    )}
                    <div className="section-accordion__wrap">
                      <button
                        className={`big section-accordion__wrap-btn accordion-btn ${collapses.collapseA === true ? "active" : ""}`}
                        onClick={(e) => this.toggleAccordion("collapseA", e)}
                      >
                        Contact
                      </button>
                      <Collapse
                        isOpen={collapses.collapseA}
                        onEntering={this.onEntering}
                        onEntered={this.onEntered}
                        onExiting={this.onExiting}
                        onExited={this.onExited}
                        className={`section-accordion__wrap-content accordion-content has-inner a ${collapses.collapseA === true ? "active" : ""} sunderland`}
                      >
                        <div className="section-accordion__wrap-content-inner">
                        <p>Please visit&nbsp;<a href="https://www.atgtickets.com/venues/kings-theatre-glasgow/info/" target="_blank" rel="noopener noreferrer">here</a>&nbsp;for more details.</p>
                        </div>
                      </Collapse>
                      <button
                        className={`big section-accordion__wrap-btn accordion-btn ${collapses.collapseD === true ? "active" : ""}`}
                        onClick={(e) => this.toggleAccordion("collapseD", e)}
                      >
                        Your Visit
                      </button>
                      <Collapse
                        isOpen={collapses.collapseD}
                        onEntering={this.onEntering}
                        onEntered={this.onEntered}
                        onExiting={this.onExiting}
                        onExited={this.onExited}
                        className={`section-accordion__wrap-content accordion-content has-inner d ${collapses.collapseD === true ? "active" : ""} sunderland`}
                      >
                        <div className="section-accordion__wrap-content-inner">
                        <p>Click&nbsp;<a href="https://www.atgtickets.com/venues/kings-theatre-glasgow/your-visit/" target="_blank" rel="noopener noreferrer">here</a>&nbsp;to see more options with how you can make your visit to King's Theatre even more special.</p>
                        </div>
                      </Collapse>

                      <button
                        className={`big section-accordion__wrap-btn accordion-btn ${collapses.collapseB === true ? "active" : ""}`}
                        onClick={(e) => this.toggleAccordion("collapseB", e)}
                      >
                        Access
                      </button>
                      <Collapse
                        isOpen={collapses.collapseB}
                        className={`section-accordion__wrap-content accordion-content has-inner b ${collapses.collapseB === true ? "active" : ""}`}
                      >
                        <div className="section-accordion__wrap-content-inner">
                        <p>Please visit&nbsp;<a href="https://www.atgtickets.com/access/" target="_blank" rel="noopener noreferrer">here</a>&nbsp;for more details.</p>
                        </div>
                      </Collapse>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </>
        )}

        {phase === "registration" && (
          <div className="section-sign-up">
            <Container>
              <Row>
                <Col xs={12}>
                  <div className="section-sign-up__wrap">
                    <h2 className={`h3`}>
                      SIGN UP NOW AND GET ACCESS TO THE PRESALE <br />
                      REGISTER NOW
                    </h2>
                    <SignUpForm
                      className="sign-up-form"
                      location="/glasgow/"
                      locationName="glasgow"
                      phase={phase}
                      thankYouPage={true}
                    />
                    <p className="small-copy">
                      By submitting your email address, you are agreeing to
                      receive marketing emails about The Book of Mormon. You can
                      opt out of receiving these emails at any time.
                      <br />
                      To see full details of our privacy policy{" "}
                      <Link to={`/glasgow/privacy-policy/`}>click here</Link>
                      .
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}
        {phase === "presale" && (
          <div className="section-sign-up">
            <Container>
              <Row>
                <Col xs={12}>
                  <div className="section-sign-up__wrap orange-border">
                    <h3 className="mb-2">
                      THE MORMONS ARE COMING TO NOTTINGHAM
                    </h3>
                    <div className="narrow">
                      <p>&nbsp;</p>
                      <p>Rescheduled performances will now take place at</p>
                      <p>&nbsp;</p>
                      <p className="big orange text-uppercase">
                        <strong>Theatre Royal, Glasgow</strong>
                      </p>
                      <p>&nbsp;</p>
                      <p className="big orange text-uppercase">
                        <strong>16 August – 10 September 2022</strong>
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        Sign up here to be the first to hear when tickets are
                        released!
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        Ticketholders for the previous run do not need to do
                        anything. The theatre will contact you regarding the
                        next steps.
                      </p>
                    </div>
                    <SignUpForm
                      className="sign-up-form"
                      location="/glasgow/"
                      locationName="glasgow"
                      phase={phase}
                      thankYouPage={true}
                    />
                    <p className="small-copy">
                      By submitting your email address, you are agreeing to
                      receive marketing emails about The Book of Mormon. You can
                      opt out of receiving these emails at any time.
                      <br />
                      To see full details of our privacy policy{" "}
                      <Link to={`/glasgow/privacy-policy/`}>click here</Link>
                      .
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}
        {phase === "open" && (
          <div className="section-show-info">
            <Container>
              <Row>
                <Col xs={12}>
                  <div className="section-show-info__wrap">
                    <div className="row">
                      <div className="section-show-info__wrap-map">
                        <h2 className="d-lg-none">Show Info</h2>
                        <div className={`map glasgow`}></div>
                      </div>

                      <div className="section-show-info__wrap-content">
                        <div className="content">
                          <h2 className="d-none d-lg-block">Show Info</h2>
                          <h3>13 - 30 MAY 2025</h3>
                          <p>
                          297 Bath St, <br /> Glasgow G2 4JN
                          </p>

                          <a
                            className="btn btn-google-maps"
                            href="https://maps.app.goo.gl/MNp6wW8LCGX4aAhW9"
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            <span className="sr-only">
                              Glasgow Empire Map
                            </span>
                          </a>
                          <br />
                          <h3>PERFORMANCE TIMES</h3>
                          <p>
                            <strong>Monday - Saturday 7.30pm</strong>
                          </p>
                          <p>
                            <strong>Friday & Saturday 2.30pm</strong>
                          </p>
                          <br />
                          <h3 className="mt-2">Running Time</h3>
                          <p>2 hours 20 minutes, including interval</p>
                          <br />
                          <p>
                            <strong>Parental advisory:</strong> Explicit
                            language.
                          </p>
                          <p>
                            <strong>Please note:</strong>&nbsp;This production
                            contains strobe lighting, flashing lights and
                            gunshots.&nbsp;
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </>
    );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        allEventsJson {
          edges {
            node {
              siteId
              items {
                url
                start
                title
                className
                active
                type
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <Glasgow
        events={data}
        multiLang={props.multiLang}
        phase={props.phase}
        siteId={props.siteId}
        language={props.language}
      />
    )}
  />
);
