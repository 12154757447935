import React, {Component} from 'react'
import ReactPlayer from 'react-player'
import {Link} from "gatsby";
import subMobileImg from 'images/logos/sub-mob.png'
import starburstImg from 'images/buttons/starburst.png'
import clickHereImg from 'images/buttons/click-here.png'


class VideoHeader extends Component {
    constructor(props) {
        super(props)

        this.state = {
            playing: false,
            device: 'lg'
        };

    }

    componentDidMount() {
        if (window.innerWidth > 991) {
            this.checkVideo();
        } else if (window.innerWidth < 992 && window.innerWidth > 575 ) {
            this.checkVideoMd();
        } else {
            this.checkVideoSm();
        }

        window.addEventListener('resize', this.checkVideos)
    }

    checkVideos = () => {
        if (window.innerWidth > 991 && this.state.device !== 'lg') {
            this.setState({device: 'lg'})
            this.checkVideo();
        }
        if (window.innerWidth < 992 && window.innerWidth > 575 && this.state.device !== 'md') {
            this.setState({device: 'md'})
            this.checkVideoMd();
        }
        if (window.innerWidth < 576 && this.state.device !== 'sm') {
            this.setState({device: 'sm'})
            this.checkVideoSm();
        }
    }

    checkVideo = () => {
        this.video.play();
        setTimeout(() => {
            if (this.video.paused) {
                // this.video.play()
                this.setState({playing: false})
            } else {
                // this.video.pause()
                this.setState({playing: true})
            }
        }, 1000);
    }

    checkVideoMd = () => {
        this.videoMd.play();
        setTimeout(() => {
            if (this.videoMd.paused) {
                // this.video.play()
                this.setState({playing: false})
            } else {
                // this.video.pause()
                this.setState({playing: true})
            }
        }, 1000);
    }

    checkVideoSm = () => {
        this.videoSm.play();
        setTimeout(() => {
            if (this.videoSm.paused) {
                // this.video.play()
                this.setState({playing: false})
            } else {
                // this.video.pause()
                this.setState({playing: true})
            }
        }, 1000);
    }

    render() {
        return (
            <>
                <div className="d-none d-lg-block" style={{width: '100%', height: '100%'}}>
                    <video playsInline muted loop ref={video => this.video = video}
                           style={{
                               opacity: this.state.playing ? "1" : "0",
                               transition: "all .5s ease",
                               width: '100%',
                               height: '100%'
                           }}>
                        <source
                            src="https://player.vimeo.com/external/347713385.hd.mp4?s=0b23ad3dfa6e1777430c47609be1593c0a39f355&profile_id=175"
                            type="video/mp4"/>
                    </video>
                </div>

                <div className="d-none d-sm-block d-lg-none" style={{width: '100%', height: '100%'}}>
                    <video playsInline muted loop ref={videoMd => this.videoMd = videoMd}
                           style={{
                               opacity: this.state.playing ? "1" : "0",
                               transition: "all .5s ease",
                               width: '100%',
                               height: '100%'
                           }}>
                        <source
                            src="https://player.vimeo.com/external/348606682.sd.mp4?s=da3ecf07bdf6a74feafdf6e42189ea159569a1b6&profile_id=165"
                            type="video/mp4"/>
                    </video>
                </div>

                <div className="d-block d-sm-none" style={{width: '100%', height: '100%'}}>
                    <video playsInline muted loop ref={videoSm => this.videoSm = videoSm}
                           style={{
                               opacity: this.state.playing ? "1" : "0",
                               transition: "all .5s ease",
                               width: '100%',
                               height: '100%'
                           }}>
                        <source
                            src="https://player.vimeo.com/external/348606636.sd.mp4?s=0dd10da11df92a355e93e58cddfdd13bf5450a9e&profile_id=165"
                            type="video/mp4"/>
                    </video>
                </div>
            </>
        )
    }
}

export default VideoHeader;