import React, {Component} from 'react'
import {Row, Col} from 'reactstrap'
import Moment from 'react-moment';

class Article extends Component {
    renderOrder = (key) => {
        const article = this.props.articles[key];

        if (!article) return null;

        return (
            <Row key={key} className="article__row">
                <Col xs={12} sm={12} md={9} className="article__cols">
                    {article.attr !== '' &&
                    <h3>{article.attr}</h3>
                    }
                    <h2>{article.title}</h2>
                    <h4>
                        <Moment format="MMM DD YYYY">
                            {article.date}
                        </Moment>
                    </h4>
                    <div className="bio">
                        <div dangerouslySetInnerHTML={{__html: article.post}}/>
                    </div>
                    {article.url ?
                        <a href={article.url} target="_blank" rel="noreferrer noopener"
                           className="btn btn-orange">{article.words}</a>
                        : ""
                    }

                </Col>
                <Col xs={12} sm={12} md={3}>
                    {article.url ?
                        <ul className="social">
                            <li>
                                <a href={`https://www.facebook.com/sharer.php?u=${article.url}`}
                                   target="_blank" rel="noreferrer noopener">
                                    <div className="share fb"></div>
                                    <span className="d-inline">Share</span>

                                </a>
                            </li>
                            <li>
                                <a href={`https://twitter.com/home?status=${article.url}`}
                                   target="_blank" rel="noreferrer noopener">
                                    <div className="share tw"></div>
                                    <span className="d-inline">Tweet</span>
                                </a>
                            </li>
                        </ul>
                        : ""
                    }
                </Col>
            </Row>
        );
    };

    render() {
        const articlesIds = Object.keys(this.props.articles);
        return (
            <div className="article">
                {articlesIds.map(this.renderOrder)}
            </div>
        )
    }
}

export default Article;