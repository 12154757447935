import React, {Component} from 'react'
import {Container, TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap'
import SpinnerHeader from 'components/SpinnerHeader'
import castImg from 'images/hero/cast.jpg'
import creativeImg from 'images/hero/creative.jpg'
import Cast from 'components/Pages/ShowPage/Cast'
import Creative from 'components/Pages/ShowPage/Creative'
import classnames from 'classnames';

import casts from 'data/casts'
import creatives from 'data/creatives'
import production from 'data/production'
import {Link} from "gatsby";
import Production from './ShowPage/Production'

class ShowPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            casts: [],
            creatives: [],
            production: [],
            activeTab: 'cast-tab'
        }
    }

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    };

    loadCasts = () => {
        this.setState({
            casts: casts
        });
    };


    loadCreatives = () => {
        this.setState({
            creatives: creatives
        });
    };

    loadProduction = () => {
        this.setState({
            production: production
        });
    };

    componentWillMount() {
        this.loadCreatives();
        this.loadCasts();
        this.loadProduction();
    };

    componentWillUnmount() {
        this.setState({
            casts: [],
            creatives: [],
            production: []
        });
    };

    render() {
        const {creatives, casts, production} = this.state;
        const {siteId, language, multiLang} = this.props;

        if (casts.length === 0 || creatives.length === 0 || production.length === 0)
            return true;

        let castsByLocation;
        let creativesByLocation;
        let productionByLocation;

        if (siteId !== 'london') {
            castsByLocation = typeof casts[siteId] === 'undefined' ? casts['other'][0].items : casts[siteId][0].items;
            creativesByLocation = typeof creatives[siteId] === 'undefined' ? creatives['new'][0].items : creatives[siteId][0].items;
            productionByLocation = typeof production[siteId] === 'undefined' ? production['other'][0].items : production[siteId][0].items;
        } else {
            castsByLocation = typeof casts[siteId] === 'undefined' ? casts['london'][0].items : casts[siteId][0].items;
            creativesByLocation = typeof creatives[siteId] === 'undefined' ? creatives['london'][0].items : creatives[siteId][0].items;
            productionByLocation = typeof production[siteId] === 'undefined' ? production['london'][0].items : production[siteId][0].items;
        }

        let activeTab = this.state.activeTab;

        // if (siteId !== 'london') {
        //     activeTab = 'creative-tab';
        // }

        let rootUrl = `/${siteId}/`;

        if (multiLang) {
            rootUrl = `/${siteId}/${language}/`;
        }

        return (
            <>
                <div>
                    <Container>
                        {/* {siteId === 'london' ?
                            <SpinnerHeader/>
                            :
                            <Link to={rootUrl} className={'subPages__no-link'}>
                                <div className="section-intro">

                                </div>
                            </Link>
                        } */}

                            <Link to={rootUrl} className={'subPages__no-link'}>
                                <div className="section-intro">

                                </div>
                            </Link>

                        <div className="subPages__topper">
                            <h1>The Show</h1>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({active: activeTab === 'cast-tab'})}
                                        onClick={() => {
                                            this.toggleTab('cast-tab');
                                        }}
                                    >
                                        Cast
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({active: activeTab === 'creative-tab'})}
                                        onClick={() => {
                                            this.toggleTab('creative-tab');
                                        }}
                                    >
                                        Creative
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({active: activeTab === 'production-tab'})}
                                        onClick={() => {
                                            this.toggleTab('production-tab');
                                        }}
                                    >
                                        Production
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                    </Container>

                    <div className="subPages__hero">
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="cast-tab">
                                <img id="pic1" src={castImg} alt="The Show"/>
                            </TabPane>
                            <TabPane tabId="creative-tab">
                                <img id="pic2" src={creativeImg} alt="The Show"/>
                            </TabPane>
                            <TabPane tabId="production-tab">
                                <img id="pic3" src={creativeImg} alt="The Show"/>
                            </TabPane>
                        </TabContent>

                    </div>

                    <Container>
                        <div className="subPages__content">
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="cast-tab">
                                    <Cast
                                        casts={castsByLocation}
                                    />
                                </TabPane>
                                <TabPane tabId="creative-tab">
                                    <Creative
                                        creatives={creativesByLocation}
                                    />
                                </TabPane>
                                <TabPane tabId="production-tab">
                                    <Production
                                        production={productionByLocation}
                                    />
                                </TabPane>
                            </TabContent>
                        </div>
                    </Container>
                </div>
            </>
        )
    }
}

export default ShowPage
