import React from 'react'

const Production = ({ production }) => {
    const [prod, orch] = production;
    return (
        <div className="prod">
            <div className="prod__list">
                <h2>Production</h2>
                <div className="prod__list__wrapper">
                    {
                        prod.production.map((i, x) => (
                            <SingleItem data={i} key={x} />
                            ))
                        }
                </div>
            </div>
            <div className="prod__list orch">
                <h2>Orchestra</h2>
                <div className="prod__list__wrapper">
                    {
                        orch.orchestra.map((i, x) => (
                            <SingleItem data={i} key={x} />
                            ))
                        }
                </div>
            </div>
        </div>
    )
}


const SingleItem = ({ data }) => (
    <div className="prod--item">
        {
            data.longer ? (<><h3>{data.role} -</h3><span>{data.name}</span></>) : (<h3>{`${data.role} -`}<span>{data.name}</span></h3>)
        }

    </div>
)


export default Production;