import React from 'react'
import {Link} from 'gatsby'
import {Row, Col} from 'reactstrap'

const Privacy = (props) => (
    <>
        <Link to={`/${props.audience}`}>Back to Homepage</Link>
        <br/>
        <br/>
        <Row>
            <Col xs={12} sm={6}>
                <h3>Introduction</h3>
                <p>This website, controlled by THE UK MISSION LTD, provides marketing information about The Book of Mormon
                    London. THE UK MISSION LTD is the Data Controller, for the purposes of Data Protection Law, when your
                    personal information is processed as a result of your visit to this website.</p>
                <p>We are committed to providing you with relevant information about, and control over, how we process your
                    personal information.</p>
                <p>This policy explains what personal information we may record, why we do so, how you may control the use
                    of your information and how we protect your information. Details of your legal rights and how to
                    exercise them are also set out below.</p>
                <p>This policy was last updated on 24/05/2018. Any future changes to our policy will be reflected here.</p>
                <h3>Contacting us</h3>
                <p>Questions, comments and requests regarding this privacy policy are welcomed and should be addressed by
                    email to <a href="mailto:mypersonaldata@soniafriedman.com">mypersonaldata@soniafriedman.com</a> or by
                    post to Data Protection Officer, The UK Mission Ltd, c/o Sonia Friedman Production, 65 Chandos Place,
                    London WC2N 4HG.</p>
                <h3>Why we process your personal information</h3>
                <p>We process your personal information gathered on this website in order to tailor our marketing to your
                    needs and to improve our website for future customers. The specific purposes for which we may process
                    information, and the lawful basis on which we do so in each case, are set out below. </p>
                <h3>Tailoring this website to your needs</h3>
                <p>We want to make our website easy to personalise. We use preference cookies to store your preferences,
                    such as website language selection, so that you do not need to make the same selections every time you
                    visit our website. These cookies, stored by your browser on your device, will retain your preference
                    choices for 365 days, or sooner if you chose to delete them. We record your consent to store cookies
                    when you first visit the site and click on the cookie notice, and we provide details of how to change
                    your settings <Link to={`/cookie-privacy/`}>here</Link></p>
                <h3>Contacting you with marketing emails to keep you informed about our show(s)</h3>
                <p>We want to help you to hear more about our show. We provide facilities on this site for you to sign up to
                    receive email newsletters to learn more about the The Book of Mormon. We will send such newsletters by
                    e-mail on the basis of your specific consent when you supply your email details, which you may withdraw
                    at by clicking on the unsubscribe link which we will include in each email. Your information, and
                    details of when you consented to receive marketing emails, will be stored securely in our marketing
                    system. We will not share this data with third parties for their own use, though we may engage
                    appropriate suppliers to send emails on our behalf.</p>
                <h3>Providing "ticket lottery"; facilities</h3>
                <p>We run ticket lotteries to provide fair access to last minute tickets. We provide facilities to enter a
                    lottery to gain access to book tickets at £20. We will process your information on the basis of specific
                    consent given when you enter the lottery, and those selected will be advised how to book tickets. We
                    will not share this data with third parties for their own use, though we may engage suppliers to
                    administer the lottery on our behalf. This data, including your selected performances and when you
                    applied, will stored securely in our marketing system and will be retained for 7 days and then deleted.
                    Resulting ticket sales will be processed by Delfont Mackintosh Ltd. Ticket lottery terms and conditions
                    apply.</p>
                <h3>Improving the effectiveness of our website</h3>
                <p>We want to make sure that our website enables people to learn about the show and to book tickets and that
                    the site meets the needs of our customers and is easy to use. To do this, we need to understand how
                    people use this site, where they spend time and which pages they find less useful. We use Google
                    Analytics to understand how you and other visitors use our website, for example recording in what order
                    pages are visited and how long is spent on each, so that we may improve the website in future. Google
                    Analytics privacy policy may be found <a target="_blank" rel="noreferrer noopener" href="https://policies.google.com/privacy">here</a>. We record
                    your consent to store site analytics cookies when you first visit the site and click on the cookie
                    notice. The lawful basis on which we process these analytics cookies is our legitimate interest in
                    improving our website as we believe that the use of such cookies is not intrusive.</p>

            </Col>

            <Col xs={12} sm={6}>
                <h3>Targeting and measuring the effectiveness of our digital marketing</h3>
                <p>We know that many people visit a show site several times to learn about the show and dates when tickets
                    are available before deciding to book tickets. We want to make sure that our digital advertisements on
                    other websites and social media are seen by people likely to be interested in the show, including by
                    visitors to this site who have not yet booked. We also want to understand the effectiveness of our
                    advertising in encouraging visits to the website and ticket bookings so that we may improve it in future
                    for example by reviewing the choice of advertising design and the websites and advertising networks used
                    to reach possible customers. Achieving this may involve showing different versions of advertisements to
                    different customers to measure the responses generated by each. We may use available browsing history,
                    including visits to this website, to identify people to whom we wish to show advertisements on websites
                    operated by third parties and to record how many visitors are referred to our site from other sites.</p>
                <p>We use Sizmek advertising tracking cookies to achieve this and process these cookies on the basis of
                    legitimate interest. We believe that showing relevant advertisements is in your interest as well as ours
                    and we do not believe that such personalisation is generally considered intrusive. We respect any
                    decision not to participate in personalised advertising, in which case third party sites may show you
                    non-personalised advertising and you may still see advertisements for our show. We record your consent
                    to store advertising tracking cookies when you first visit the site and click on the cookie notice.
                    Details of how to change your settings to opt out of personalised advertising may be found <Link
                        to={`/cookie-privacy/`}>here</Link>. We use Sizmek to track our advertising. Details
                    of how long your data is stored and how transfers outside the EEA are protected may be found in their <a target="_blank" rel="noreferrer noopener"
                        href="https://www.sizmek.com/privacy-policy/">Privacy Policy</a>. Our tracking cookie information is
                    not shared with third parties for their own use, though we may use it to reconcile advertising costs
                    with the publishers of third party sites.</p>
                <h3>Your rights</h3>
                <p>We want to be transparent about how we process your data and to ensure that you can exercise your legal
                    rights:</p>
                <ul>
                    <li>To be informed - via this Privacy Policy which sets out how we collect and process data</li>
                    <li>To access your data - please contact <a
                        href="mailto:mypersonaldata@soniafriedman.com">mypersonaldata@soniafriedman.com</a> if you wish to
                        confirm what data we hold about you
                    </li>
                    <li>To have your data corrected - please contact <a
                        href="mailto:mypersonaldata@soniafriedman.com">mypersonaldata@soniafriedman.com</a> if you feel that
                        data held is incorrect and want this rectified
                    </li>
                    <li>To have processing of your data ceased - please contact <a
                        href="mailto:mypersonaldata@soniafriedman.com">mypersonaldata@soniafriedman.com</a> if you wish us
                        to cease processing your data without erasing it
                    </li>
                    <li>To have your data erased - please contact <a
                        href="mailto:mypersonaldata@soniafriedman.com">mypersonaldata@soniafriedman.com</a> to request
                        deletion of data that we hold about you
                    </li>
                    <li>To data portability - we process data only to personalise our services to you so do not consider it
                        appropriate to pass your data to other organisations. Some banks and utilities offer facilities to
                        switch accounts, but this is not applicable to our business.
                    </li>
                    <li>To object to processing of your data on the basis of legitimate interest or for statistical purposes
                        - please contact <a
                            href="mailto:mypersonaldata@soniafriedman.com">mypersonaldata@soniafriedman.com</a>, use the
                        unsubscribe link in any email we send you, or amend your cookie settings
                    </li>
                </ul>
                <h3>Complaints</h3>
                <p>To exercise all relevant rights, queries of complaints please in the first instance contact <a
                    href="mailto:mypersonaldata@soniafriedman.com">mypersonaldata@soniafriedman.com</a></p>
                <p>You can contact the Information Commissioners Office on 0303 123 1113 or via email <a target="_blank" rel="noreferrer noopener"
                    href="https://ico.org.uk/global/contact-us/">https://ico.org.uk/global/contact-us/</a> or at
                    the Information Commissioner's Office, Wycliffe House, Water Lane, Wilmslow,</p>
            </Col>
        </Row>
        <Link to={`/${props.audience}`}>Back to Homepage</Link>
    </>
)
export default Privacy