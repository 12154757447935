import React, {Component} from 'react';
import {Container, Row, Col, Collapse} from 'reactstrap'
import SignUpForm from 'components/SignUpForm'
import {Link} from "gatsby";
import Calendar from 'components/Calendar'
import {StaticQuery, graphql} from "gatsby"
import moment from 'moment'
import star from "images/star.svg"

import tickX from 'data/tickx'
import booknowSml from 'images/booknow-sml.jpg'

// import tickX from 'data/tickx'

class Hull extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showCalendar: false,
            collapses: {
                collapseA: true,
                collapseB: false,
                collapseC: false,
                collapseD: false,
            },
            // tickX: tickX[1]
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({showCalendar: true})
        }, 1000)

        if (window.tx) {
            window.tx({
                widgetId: "BoMhull",
                configId: "meZttWSd",
                selector: "#tickx-widget"
            });
        }
    }

    // loadEvents = (events) => {
    //     let futureEvents = [];

    //     for (var key in events) {
    //         if (events[key].node.siteId === 'hull') {
    //             for (var eventKey in events[key].node.items) {
    //                 if (moment().diff(events[key].node.items[eventKey].start, 'hours') <= 0 && events[key].node.items[eventKey].active) {
    //                     futureEvents.push(events[key].node.items[eventKey]);
    //                 }
    //             }
    //         }
    //     }

    //     return (
    //         <Calendar
    //             events={futureEvents}
    //             defaultDate={moment('2025-3-1', 'YYYY-MM-DD')}
    //             visibleRange={{
    //                 start: moment('2025-1-1', 'YYYY-MM-DD'),
    //                 end: moment('2025-3-31', 'YYYY-MM-DD')
    //             }}
    //             validRange={{
    //                 start: moment('2025-1-1', 'YYYY-MM-DD'),
    //                 end: moment('2025-3-31', 'YYYY-MM-DD')
    //             }}
    //         />
    //     )

    // };

    toggleAccordion = (id, e) => {
        let collapses = {...this.state.collapses};
        const collapse = id;

        for (var key in collapses) {
            if (collapses.hasOwnProperty(key) && collapse !== key) {
                collapses[key] = false;
            }
        }

        collapses[collapse] = true;

        this.setState({collapses});
    };

    render() {
        const { phase, siteId } = this.props;
        // const { tickX } = this.state;
        // const {phase} = this.props;
        const {showCalendar} = this.state;

        const collapses = {...this.state.collapses};

        return (
            <>
                <div className="section-intro">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                {phase === 'registration' &&
                                <h1 className="text-center">THE MORMONS ARE COMING TO Hull</h1>
                                }

                                {phase === 'open' &&
                                <h1>How to buy your tickets for Hull</h1>
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>

                {phase === 'open' &&
                <>
                    <div className="section-intro section-sign-up">
                        <Container>
                            <Row>
                                <Col xs={12}>

                                    <div className="section-intro__wrapper">
                                        <div className="section-intro__wrapper-header">
                                            <h2>TICKET TIPS</h2>
                                        </div>
                                        <div className="section-intro__wrapper-body">
                                            <h3>WE KNOW IT CAN BE HARD SOMETIMES FINDING THE RIGHT TICKET SO HERE ARE A
                                                FEW HANDY TIPS:</h3>

                                            <ul>
                                                <li>SIGN-UP <a href="#footer">HERE</a> to receive latest news including
                                                    priority seat
                                                    releases
                                                </li>
                                                <li>For a wider range of prices and seats, try midweek performances</li>
                                                {/*<li>For access performances <a href="#section-accordion" onClick={(e) => this.toggleAccordion('collapseB', e)}>click here</a></li>*/}
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="section-accordion" id="section-accordion">
                        <Container>
                            <Row>
                                <Col xs={12}>
                                    {/* {showCalendar &&
                                        <div>
                                            <h2 className='py-3'>ONLINE BOOKINGS</h2>
                                            <ul className="keyList">
                                                <li>
                                                    <div className="keyList__key">KEY:</div>
                                                </li>
                                                <li>
                                                    <div className="keyList__sold-out">SOLD OUT</div>
                                                </li>
                                                <li>
                                                    <div className="keyList__limited">LIMITED</div>
                                                </li>
                                                <li>
                                                    <div className="keyList__available">AVAILABLE</div>
                                                </li>
                                            </ul>

                                            {this.loadEvents(this.props.events.allEventsJson.edges)}
                                            <div id="tickx-widget"></div>
                                        </div>
                                    } */}
                                    <div className="section-accordion__wrap">
                                        {/* <button
                                            className={`section-accordion__wrap-btn accordion-btn tickX ${collapses.collapseA === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseA', e)}>
                                            ONLINE BOOKINGS
                                        </button>
                                        <Collapse
                                            isOpen={collapses.collapseA}
                                            onEntering={this.onEntering}
                                            onEntered={this.onEntered}
                                            onExiting={this.onExiting}
                                            onExited={this.onExited}
                                            className={`section-accordion__wrap-content accordion-content tickX a ${collapses.collapseA === true ? 'active' : ''} ${siteId}`}> */}

                                            {/* <div className="table">
                                                <a className="table-row" href={`/edinburgh/tickets/?configId=${tickX['edinburgh'][0]['configId']}`}>
                                                    <div className="table-cell">1</div>
                                                    <div className="table-cell">
                                                        <div dangerouslySetInnerHTML={{ __html: tickX['edinburgh'][0]['copy'] }} />
                                                    </div>
                                                    <div className="table-cell">
                                                        <div className={`btn-buy GTM-BOMLiverpooolPerformanceDate`} id="versaID-edinburgh-PerformanceDate" />
                                                    </div>
                                                </a>
                                                <a className="table-row" href={`/edinburgh/tickets/?configId=${tickX['edinburgh'][1]['configId']}`}>
                                                    <div className="table-cell">2</div>
                                                    <div className="table-cell">
                                                        <div dangerouslySetInnerHTML={{ __html: tickX['edinburgh'][1]['copy'] }} />
                                                    </div>
                                                    <div className="table-cell">
                                                        <div className={`btn-buy GTM-BOMLiverpooolUnder30`} id="versaID-edinburgh-Under30" />
                                                    </div>
                                                </a>
                                                <a className="table-row" href={`/edinburgh/tickets/?configId=${tickX['edinburgh'][2]['configId']}`}>
                                                    <div className="table-cell">3</div>
                                                    <div className="table-cell">
                                                        <div dangerouslySetInnerHTML={{ __html: tickX['edinburgh'][2]['copy'] }} />
                                                    </div>
                                                    <div className="table-cell">
                                                        <div className={`btn-buy GTM-BOMLiverpooolBestSeats`} id="versaID-edinburgh-BestSeats" />
                                                    </div>
                                                </a>
                                            </div> */}

                                        {/* </Collapse> */}
                                        <button id="collapseA"
                                                className={`section-accordion__wrap-btn accordion-btn ${collapses.collapseA === true ? 'active' : ''}`}
                                                onClick={(e) => this.toggleAccordion('collapseA', e)}>
                                                
                                            Official Box Office
                                        </button>
                                        <Collapse
                                            isOpen={collapses.collapseA}
                                            onEntering={this.onEntering}
                                            onEntered={this.onEntered}
                                            onExiting={this.onExiting}
                                            onExited={this.onExited}
                                            className={`section-accordion__wrap-content accordion-content tickX a ${collapses.collapseA === true ? 'active' : ''} ${siteId}`}>

                                            <div className="table">
                                            {tickX[7].hull.map((button, i ) => {
                                                    return (
                                                        <a className="table-row"
                                                        href={`/hull/tickets/?flow=${button.configId}`}>
                                                         <div className="table-cell">{i + 1}</div>
                                                         <div className="table-cell">
                                                             <div
                                                                 dangerouslySetInnerHTML={{__html: button.copy }}/>
                                                                        <span
                                                            dangerouslySetInnerHTML={{__html: button.sub }}></span>
                                                          </div>
                                                         <div className="table-cell">
                                                             <div className={`btn-buy GTM-BOMLondonIncAvail`}
                                                                  id="versaID-London-IncAvail" alt="" src={booknowSml}/>
                                                         </div>
                                                     </a>
                                                    )
                                                })}  
                                            </div>
                                        </Collapse>

                                        <button
                                            className={`section-accordion__wrap-btn accordion-btn tickX ${collapses.collapseB === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseB', e)}>
                                            PHONE BOOKINGS
                                        </button>
                                        <Collapse isOpen={collapses.collapseA}
                                                  className={`section-accordion__wrap-content accordion-content d ${collapses.collapseB === true ? 'active' : ''}`}>
                                                    <p>Box Office: 01482 300 306.<br />Telephone lines are open Monday to Saturday 9am to 5pm.</p>
                                                    <p>For more information about contacting the venue, please visit&nbsp;<a href="https://www.hulltheatres.co.uk/contact-us" target="_blank" rel="noopener noreferrer">here</a>.</p>
                                            {/* <p><strong>BOOK BY PHONE</strong></p>

                                            <p>Telephone Booking* <a href="tel:03330096690">03330 096 690</a></p>
                                            <p className="small-copy">*Calls charged at standard geographic rates and count towards any inclusive minutes in the same way as calls to 01 and 02 numbers.</p> */}

                                        </Collapse>

                             

                                        <button
                                            className={`section-accordion__wrap-btn accordion-btn tickX ${collapses.collapseC === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseC', e)}>
                                            Access Information
                                        </button>
                                        <Collapse isOpen={collapses.collapseC}
                                                  className={`section-accordion__wrap-content accordion-content has-inner b ${collapses.collapseC === true ? 'active' : ''}`}>
                                            <div className="section-accordion__wrap-content-inner">
<p>For information on access,&nbsp;<a href="https://www.hulltheatres.co.uk/HullTheatres/hull-new-theatres/hull-new-theatre-access-facilities" target="_blank" rel="noopener noreferrer">click here</a></p>
                                            </div>
                                        </Collapse>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </>
                }

                {phase === 'registration' &&
                <div className="section-sign-up">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-sign-up__wrap">
                                    <h2 className={`h3`}>SIGN UP NOW AND GET ACCESS TO THE PRESALE <br/>REGISTER NOW</h2>
                                    <SignUpForm
                                        className="sign-up-form"
                                        location="/hull/"
                                        locationName="hull"
                                        phase={phase}
                                        thankYouPage={true}
                                    />
                                    <p className="small-copy">
                                        By submitting your email address, you are agreeing to receive marketing
                                        emails about The Book of Mormon. You can opt out of receiving
                                        these emails at any time.
                                        <br/>To see full details of our privacy policy <Link
                                        to={`/hull/privacy-policy/`}>click here</Link>.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }
                {phase === 'presale' &&
                <div className="section-sign-up space-below">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-sign-up__wrap orange-border">
                                    <h3 className="mb-2">THE MORMONS ARE COMING TO EDINBURGH</h3>
                                    <div className="narrow">
                                        <p>&nbsp;</p>
                                        <p>Performances will take place at</p>
                                        <p>&nbsp;</p>
                                        <p className="big orange text-uppercase"><strong>King’s Theatre, Edinburgh</strong></p>
                                        <p>&nbsp;</p>
                                        <p className="big orange text-uppercase"><strong>6 – 23 January 2022</strong></p>
                                        <p>&nbsp;</p>
                                        <p>Sign up here to be the first to hear when tickets are released!</p>
                                    </div>
                                    <SignUpForm
                                        className="sign-up-form"
                                        location="/edinburgh/"
                                        locationName="edinburgh"
                                        phase={phase}
                                        thankYouPage={true}
                                    />
                                    <p className="small-copy">
                                        By submitting your email address, you are agreeing to receive marketing
                                        emails about The Book of Mormon. You can opt out of receiving
                                        these emails at any time.
                                        <br/>To see full details of our privacy policy <Link
                                        to={`/edinburgh/privacy-policy/`}>click here</Link>.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }
                {phase === 'open' &&
                <div className="section-show-info">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-show-info__wrap">
                                    <div className="row">
                                        <div className="section-show-info__wrap-map">
                                            <h2 className="d-lg-none">Show Info</h2>
                                            <div className={`map hull`}>
                                            </div>
                                        </div>

                                        <div className="section-show-info__wrap-content">
                                            <div className="content">
                                                <h2 className="d-none d-lg-block">Show Info</h2>
                                                <h3>4 - 22 March 2025</h3>
                                                <p>Kingston square <br/>
                                                Hull <br />
                                                HU1 3HF
                                                </p>

                                                <a className="btn btn-google-maps"
                                                   href="https://www.google.com/maps/place/Hull+New+Theatre/@53.748422,-0.334914,17z/data=!4m6!3m5!1s0x4878be3b558edd6f:0x5b89431da7ce673f!8m2!3d53.7474889!4d-0.3373598!16zL20vMGYycWdw?hl=en&entry=ttu"
                                                   target="_blank"
                                                   rel="noreferrer noopener">
                                                    <span className="sr-only">Royal Parade</span>
                                                </a>

                                                <h3>PERFORMANCE TIMES</h3>
                                                <p><strong>PERFORMANCE TIMES<br /></strong>Monday - Saturday 7.30pm</p>
                                                <p>Friday and Saturday 2.30pm</p>
                                                {/* <p>Mon – Sat 7.30pm</p>
                                                <p>Fri & Sat 2.30pm</p> */}

                                                <h3 className="mt-2">Running Time</h3>
                                                
                                                <p>2 hours 20 minutes, including interval</p>
                                                <br />
                                                <p><strong>Parental advisory:</strong> Explicit language.</p>
<p><strong>Please note:</strong>&nbsp;This production contains strobe lighting, flashing lights and gunshots.&nbsp;</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }
                {/*<div className={`coronaMsg ${!this.state.coronaMessageOpen && 'd-none'}`}>*/}

                {/*    <div className="coronaMsg--content">*/}

                {/*        <p>Under the direction of the UK Government, theatre shows playing to more than **** people will suspend all performances immediately in support of the health and well-being of the theatregoing public, as well as those who work in the theatre industry. Performances will commence the week of ** ***** ****</p>*/}

                {/*        <p>Those holding tickets for performances until ** ***** **** should contact their point of purchase for refunds and exchanges.</p>*/}

                {/*        <span className="coronaMsg--closeBtn" role="button" tabIndex={0} onKeyPress={this.closeCoronaMessage} onClick={this.closeCoronaMessage}>X</span>*/}

                {/*    </div>*/}
                {/*</div>*/}
            </>
        );
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
        query {
            allEventsJson {
                edges {
                    node {
                        siteId
                        items {
                            url
                            start
                            title
                            className
                            active
                            type
                        }
                    }
                }
            }
        }`}
        render={data => (
            <Hull events={data}
                     multiLang={props.multiLang}
                     phase={props.phase}
                     siteId={props.siteId}
                     language={props.language}
            />
        )}
    />
)