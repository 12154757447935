import React, {Component} from 'react';
import {Container, Row, Col, Collapse} from 'reactstrap'
import SignUpForm from 'components/SignUpForm'
import {Link} from "gatsby";
import Calendar from 'components/Calendar'
import {StaticQuery, graphql} from "gatsby"
import moment from 'moment'
import star from "images/star.svg"

class Dublin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showCalendar: true,
            collapses: {
                collapseA: true,
                collapseB: false,
                collapseC: false,
                collapseD: false,
            },
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({showCalendar: true})
        }, 1000)
    }

    loadEvents = (events) => {
        let futureEvents = [];

        for (var key in events) {
            if (events[key].node.siteId === 'dublin') {
                for (var eventKey in events[key].node.items) {
                    if (moment().diff(events[key].node.items[eventKey].start, 'hours') <= 0 && events[key].node.items[eventKey].active) {
                        futureEvents.push(events[key].node.items[eventKey]);
                    }
                }
            }
        }

        return (
            <Calendar
                events={futureEvents}
                defaultDate={moment('2025-04-01', 'YYYY-MM-DD')}
                visibleRange={{
                    start: moment('2025-04-01', 'YYYY-MM-DD'),
                    end: moment('2025-05-31', 'YYYY-MM-DD')
                }}
                validRange={{
                    start: moment('2025-04-01', 'YYYY-MM-DD'),
                    end: moment('2025-05-31', 'YYYY-MM-DD')
                }}
            />
        )

    };

    toggleAccordion = (id, e) => {
        let collapses = {...this.state.collapses};
        const collapse = id;

        for (var key in collapses) {
            if (collapses.hasOwnProperty(key) && collapse !== key) {
                collapses[key] = false;
            }
        }

        collapses[collapse] = true;

        this.setState({collapses});
    };

    render() {
        const {phase} = this.props;
        const {showCalendar} = this.state;

        const collapses = {...this.state.collapses};

        return (
            <>
                <div className="section-intro">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                {phase === 'registration' &&
                                <h1 className="text-center">THE MORMONS ARE COMING TO DUBLIN</h1>
                                }

                                {phase === 'open' &&
                                <h1>How to buy your tickets for DUBLIN</h1>
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>

                {phase === 'open' &&
                <>
                    <div className="section-intro section-sign-up">
                        <Container>
                            <Row>
                                <Col xs={12}>

                                    <div className="section-intro__wrapper">
                                        <div className="section-intro__wrapper-header">
                                            <h2>TICKET TIPS</h2>
                                        </div>
                                        <div className="section-intro__wrapper-body">
                                            <h3>WE KNOW IT CAN BE HARD SOMETIMES FINDING THE RIGHT TICKET SO HERE ARE A
                                                FEW HANDY TIPS:</h3>

                                            <ul>
                                                {/*<li>FINAL WEEKS now on sale</li>*/}
                                                <li>SIGN-UP <a href="#footer">HERE</a> to receive latest news including
                                                    priority seat
                                                    releases
                                                </li>
                                                <li>For a wider range of prices and seats, try midweek performances</li>
                                                {/*<li>For access performances <a href="#section-accordion" onClick={(e) => this.toggleAccordion('collapseB', e)}>click here</a></li>*/}
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="section-accordion" id="section-accordion">
                        <Container>
                            <Row>
                                <Col xs={12}>
                                {showCalendar &&
                                        <>
                                            <h2 className='py-3'>ONLINE BOOKINGS</h2>
                                            <ul className="keyList">
                                                    <li>
                                                        <div className="keyList__key">SEATS FROM:</div>
                                                    </li>
                                                    <li>
                                                        <div className="keyList__available">AVAILABLE</div>
                                                    </li>
                                                    <li>
                                                        <div className="keyList__limited">LIMITED</div>
                                                    </li>
                                                    <li>
                                                        <div className="keyList__sold-out">SOLD OUT</div>
                                                    </li>
                                                </ul>

                                            {this.loadEvents(this.props.events.allEventsJson.edges)}
                                        </>
                                    }
                                    <div className="section-accordion__wrap">
                                        <button
                                            className={`big section-accordion__wrap-btn accordion-btn ${collapses.collapseA === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseA', e)}>
                                            Contact
                                        </button>
                                        <Collapse
                                            isOpen={collapses.collapseA}
                                            onEntering={this.onEntering}
                                            onEntered={this.onEntered}
                                            onExiting={this.onExiting}
                                            onExited={this.onExited}
                                            className={`section-accordion__wrap-content accordion-content has-inner a ${collapses.collapseA === true ? 'active' : ''} dublin`}>
            <div className="section-accordion__wrap-content-inner">
            <p>Monday &ndash; Friday<br />10:00 AM &ndash; 6:00 PM</p>
<p>Call: +353 (0) 1 677 7999</p>
<p>Email:&nbsp;<a href="mailto:justask@bgetheatre.ie" target="_blank" rel="noopener noreferrer">justask@bgetheatre.ie</a></p>

                                            </div>
                                         
                                        </Collapse>

                                        <button
                                            className={`big section-accordion__wrap-btn accordion-btn ${collapses.collapseD === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseD', e)}>
                                            Box Office:
                                        </button>
                                        <Collapse isOpen={collapses.collapseD}
                                                  className={`section-accordion__wrap-content accordion-content d ${collapses.collapseD === true ? 'active' : ''}`}>
                                            <p>Monday &ndash; Friday<br />11:00 AM &ndash; 6:00 PM</p>
                                            <p><strong>Credit card payments only</strong></p>

                                        </Collapse>

                                        <button
                                            className={`big section-accordion__wrap-btn accordion-btn ${collapses.collapseC === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseC', e)}>
                                            Groups & Access: 

                                        </button>
                                        <Collapse isOpen={collapses.collapseC}
                                                  className={`section-accordion__wrap-content accordion-content c ${collapses.collapseC === true ? 'active' : ''}`}>

<p>PHONE NUMBER</p>
<p>+353 (0) 1 677 7770</p>
<p>Monday - Friday<br />09:00 - 17:30</p>
<p>EMAIL ADDRESS</p>
<p><a href="mailto:groups@bgetheatre.ie" target="_blank" rel="noopener noreferrer">groups@bgetheatre.ie</a></p>


                                        </Collapse>

                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </>
                }

                {phase === 'registration' &&
                <div className="section-sign-up">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-sign-up__wrap">
                                    <h2 className={`h3`}>SIGN UP NOW AND GET ACCESS TO THE PRESALE <br/>REGISTER NOW
                                    </h2>
                                    <SignUpForm
                                        className="sign-up-form"
                                        location="/dublin/"
                                        locationName="dublin"
                                        phase={phase}
                                        thankYouPage={true}
                                    />
                                    <p className="small-copy">
                                        By submitting your email address, you are agreeing to receive marketing
                                        emails about The Book of Mormon. You can opt out of receiving
                                        these emails at any time.
                                        <br/>To see full details of our privacy policy <Link
                                        to={`/dublin/privacy-policy/`}>click here</Link>.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }
                {phase === 'presale' &&
                <div className="section-sign-up">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-sign-up__wrap orange-border">
                                    <h3>BORD GÁIS ENERGY THEATRE, DUBLIN<br/>
                                        <img src={star} className="star" alt="" title=""/> <span className="orange">DATE CHANGE</span>
                                        <img src={star} className="star" alt="" title=""/></h3>
                                    <div className="narrow">
                                        <p>&nbsp;</p>
                                        <p>Due to the ongoing Covid-19 restrictions, all performances at Bord Gáis
                                            Energy Theatre
                                            (9&nbsp;December&nbsp;2020&nbsp;-&nbsp;3&nbsp;January&nbsp;2021) have been
                                            rescheduled to </p>
                                        <p>&nbsp;</p>
                                        <p className="big orange text-uppercase"><strong>WEDNESDAY 3 NOVEMBER – SATURDAY
                                            4 DECEMBER 2021</strong></p>
                                        <p>&nbsp;</p>
                                        <p>Ticketholders for the previous run do not need to do anything. The theatre
                                            will contact you regarding the next steps.</p>
                                        <p>&nbsp;</p>
                                        <p>Non-ticket holders wishing to book for the new dates are advised to <br
                                            className="d-none d-md-inline-block"/><strong>sign up for priority
                                            booking</strong></p>
                                    </div>
                                    <SignUpForm
                                        className="sign-up-form"
                                        location="/dublin/"
                                        locationName="dublin"
                                        phase={phase}
                                        thankYouPage={true}
                                    />
                                    <p className="small-copy">
                                        By submitting your email address, you are agreeing to receive marketing
                                        emails about The Book of Mormon. You can opt out of receiving
                                        these emails at any time.
                                        <br/>To see full details of our privacy policy <Link
                                        to={`/dublin/privacy-policy/`}>click here</Link>.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }
                {phase === 'open' &&
                <div className="section-show-info">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-show-info__wrap">
                                    <div className="row">
                                        <div className="section-show-info__wrap-map">
                                            <h2 className="d-lg-none">Show Info</h2>
                                            <div className={`map dublin`}>
                                            </div>
                                        </div>

                                        <div className="section-show-info__wrap-content">
                                            <div className="content">
                                                <h2 className="d-none d-lg-block">Show Info</h2>
                                                <h3>FROM 1 APRIL - 3 MAY 2025</h3>
                                                <p>BORD GÁIS ENERGY THEATRE<br/>
                                                    GRAND CANAL SQUARE, DOCKLANDS, DUBLIN, IRELAND</p>

                                                <a className="btn btn-google-maps"
                                                   href="https://maps.app.goo.gl/BwfyLqv9yuwXsDKx8"
                                                   target="_blank"
                                                   rel="noreferrer noopener">
                                                    <span className="sr-only">BORD GÁIS ENERGY THEATRE Map</span>
                                                </a>

                                                <h3>PERFORMANCE TIMES</h3>
                                                <p className="text-uppercase">Monday – Saturday 7.30pm</p>
                                                <p className="text-uppercase">Friday & Saturday 2.30pm</p>

                                                


                                                <p>&nbsp;</p>

                                                <h3 className="mt-2">Running Time</h3>
                                                <p>2 hours 20 minutes, including interval</p>
                                                <br />
                                                <p><strong>Parental advisory:</strong> Explicit language.</p>
<p><strong>Please note:</strong>&nbsp;This production contains strobe lighting, flashing lights and gunshots.&nbsp;</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }
            </>
        );
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
        query {
            allEventsJson {
                edges {
                    node {
                        siteId
                        items {
                            url
                            start
                            title
                            className
                            active
                            type
                        }
                    }
                }
            }
        }`}
        render={data => (
            <Dublin events={data}
                    multiLang={props.multiLang}
                    phase={props.phase}
                    siteId={props.siteId}
                    language={props.language}
            />
        )}
    />
)