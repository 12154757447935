/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_content_delivery_bucket": "bom-mailchimp-user-check-staging",
    "aws_content_delivery_bucket_region": "eu-west-2",
    "aws_content_delivery_url": "https://db5a53r35pe7q.cloudfront.net",
    "aws_cloud_logic_custom": [
        {
            "name": "bomMailchimpUserCheckAPI",
            "endpoint": "https://pwur7pcaob.execute-api.eu-west-2.amazonaws.com/staging",
            "region": "eu-west-2"
        }
    ]
};


export default awsmobile;
