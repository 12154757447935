import React, {Component} from 'react';
import {Container, Row, Col, Collapse} from 'reactstrap'
import SignUpForm from 'components/SignUpForm'
import {Link} from "gatsby";
import Calendar from 'components/Calendar'
import {StaticQuery, graphql} from "gatsby"
import moment from 'moment'
import star from "images/star.svg"

class Manchester extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showCalendar: true,
            collapses: {
                collapseA: true,
                collapseB: false,
                collapseC: false,
                collapseD: false,
            }
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({showCalendar: true})
        }, 1000)
    }

    loadEvents = (events) => {
        let futureEvents = [];

        for (var key in events) {
            if (events[key].node.siteId === 'manchester') {
                for (var eventKey in events[key].node.items) {
                    if (moment().diff(events[key].node.items[eventKey].start, 'hours') <= 0 && events[key].node.items[eventKey].active) {
                        futureEvents.push(events[key].node.items[eventKey]);
                    }
                }
            }
        }

        return (
            <Calendar
                events={futureEvents}
                defaultDate={moment('2024-09-11', 'YYYY-MM-DD')}
                visibleRange={{
                    start: moment('2024-09-11', 'YYYY-MM-DD'),
                    end: moment('2024-10-06', 'YYYY-MM-DD')
                }}
                validRange={{
                    start: moment('2024-09-11', 'YYYY-MM-DD'),
                    end: moment('2024-10-06', 'YYYY-MM-DD')
                }}
            />
        )

    };

    toggleAccordion = (id, e) => {
        let collapses = {...this.state.collapses};
        const collapse = id;

        for (var key in collapses) {
            if (collapses.hasOwnProperty(key) && collapse !== key) {
                collapses[key] = false;
            }
        }

        collapses[collapse] = true;

        this.setState({collapses});
    };

    render() {
        const {phase, siteId} = this.props;
        const {showCalendar} = this.state;

        const collapses = {...this.state.collapses};

        // if (Object.keys(tickX).length === 0)
        //     return true;

        // console.log(tickX)

        return (
            <>
                <div className="section-intro">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                {phase === 'registration' &&
                                <h1 className="text-center">THE MORMONS ARE COMING TO MANCHESTER</h1>
                                }

                                {phase === 'open' &&
                                <h1>How to buy your tickets for MANCHESTER</h1>
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>

                {phase === 'open' &&
                <>
                    <div className="section-intro section-sign-up">
                        <Container>
                            <Row>
                                <Col xs={12}>

                                    <div className="section-intro__wrapper">
                                        <div className="section-intro__wrapper-header">
                                            <h2>TICKET TIPS</h2>
                                        </div>
                                        <div className="section-intro__wrapper-body">
                                            <div className="section-intro__wrapper-body brown">
                                                {/* <p>Due to the current uncertainties surrounding Covid-19 the 2020 season at the Manchester Playhouse has had to be postponed.</p>
                                                <p>&nbsp;</p>
                                                <p>Thankfully, we have been able to reschedule for 9 – 26 June 2021 and are delighted to be able to announce that remaining unsold seats for 2021 are now on sale.</p>
                                                <p>&nbsp;</p>
                                                <p>Please note: All existing 2020 ticketholders will be contacted by the theatre and arrangements made to exchange their bookings into 2021.</p> */}
                                                <h3>WE KNOW IT CAN BE HARD SOMETIMES FINDING THE RIGHT TICKET SO HERE
                                                    ARE A
                                                    FEW HANDY TIPS:</h3>

                                                <ul>
                                                    {/*<li>FINAL WEEKS now on sale</li>*/}
                                                    <li>SIGN-UP <a href="#footer">HERE</a> to receive latest news
                                                        including
                                                        priority seat
                                                        releases
                                                    </li>
                                                    <li>For a wider range of prices and seats, try midweek
                                                        performances
                                                    </li>
                                                    {/*<li>For access performances <a href="#section-accordion" onClick={(e) => this.toggleAccordion('collapseB', e)}>click here</a></li>*/}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="section-accordion" id="section-accordion">
                        <Container>
                            <Row>
                                <Col xs={12}>
                                {showCalendar &&
                                        <>
                                            <h2 className='py-3'>ONLINE BOOKINGS</h2>
                                            <ul className="keyList">
                                                <li>
                                                    <div className="keyList__key">KEY:</div>
                                                </li>
                                                <li>
                                                    <div className="keyList__sold-out">SOLD OUT</div>
                                                </li>
                                                <li>
                                                    <div className="keyList__limited">LIMITED</div>
                                                </li>
                                                <li>
                                                    <div className="keyList__available">AVAILABLE</div>
                                                </li>
                                            </ul>

                                            {this.loadEvents(this.props.events.allEventsJson.edges)}
                                        </>
                                    }
                                    <div className="section-accordion__wrap">
                                        {/* <button
                                            className={`section-accordion__wrap-btn accordion-btn tickX ${collapses.collapseA === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseA', e)}>
                                            ONLINE BOOKINGS
                                        </button>
                                        <Collapse
                                            isOpen={collapses.collapseA}
                                            onEntering={this.onEntering}
                                            onEntered={this.onEntered}
                                            onExiting={this.onExiting}
                                            onExited={this.onExited}
                                            className={`section-accordion__wrap-content accordion-content tickX a ${collapses.collapseA === true ? 'active' : ''} ${siteId}`}> */}
{/* 
                                            <div className="table">
                                                <a className="table-row"
                                                   href={`/manchester/tickets/?configId=${tickX['manchester'][0]['configId']}`}>
                                                    <div className="table-cell">1</div>
                                                    <div className="table-cell">
                                                        <div
                                                            dangerouslySetInnerHTML={{__html: tickX['manchester'][0]['copy']}}/>
                                                    </div>
                                                    <div className="table-cell">
                                                        <div className={`btn-buy GTM-BOMManchesterPerformanceDate`}
                                                             id="versaID-manchester-PerformanceDate"/>
                                                    </div>
                                                </a>
                                                <a className="table-row"
                                                   href={`/manchester/tickets/?configId=${tickX['manchester'][1]['configId']}`}>
                                                    <div className="table-cell">2</div>
                                                    <div className="table-cell">
                                                        <div
                                                            dangerouslySetInnerHTML={{__html: tickX['manchester'][1]['copy']}}/>
                                                    </div>
                                                    <div className="table-cell">
                                                        <div className={`btn-buy GTM-BOMManchesterUnder30`}
                                                             id="versaID-manchester-Under30"/>
                                                    </div>
                                                </a>
                                                <a className="table-row"
                                                   href={`/manchester/tickets/?configId=${tickX['manchester'][2]['configId']}`}>
                                                    <div className="table-cell">3</div>
                                                    <div className="table-cell">
                                                        <div
                                                            dangerouslySetInnerHTML={{__html: tickX['manchester'][2]['copy']}}/>
                                                    </div>
                                                    <div className="table-cell">
                                                        <div className={`btn-buy GTM-BOMManchesterBestSeats`}
                                                             id="versaID-manchester-BestSeats"/>
                                                    </div>
                                                </a>
                                            </div> */}
                                        {/* </Collapse> */}

                                        <button
                                            className={`section-accordion__wrap-btn accordion-btn tickX ${collapses.collapseA === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseA', e)}>
                                            PHONE BOOKINGS
                                        </button>
                                        <Collapse isOpen={collapses.collapseA}
                                                  className={`section-accordion__wrap-content accordion-content tickX has-inner d ${collapses.collapseA === true ? 'active' : ''}`}>
                                            <div className="section-accordion__wrap-content-inner">
                                            <p>Group Bookings*</p>
                                            <p>0207 206 1174</p>
                                            <br />
                                            <p>Box Office Opening Hours</p>
                                            <p>90 minutes prior to performance commencing</p>
                                            <p>*Calls to 02 &amp; 03 numbers are charged at standard geographic rate included in any call plan minutes.</p>
                                                {/* <p><strong>BOOK BY PHONE</strong></p>
                                                <p>&nbsp;</p>
                                                <p>Telephone Booking*</p>
                                                <p>0844 871 7615</p>
                                                <p>&nbsp;</p>
                                                <p>Group Bookings**</p>
                                                <p>0207 206 1174</p>
                                                <p>&nbsp;</p>
                                                <p>Box Office Opening Hours</p>
                                                <p>90 minutes prior to performance commencing</p>
                                                <p>*Calls cost 7p per minute, plus your phone company's access charge</p>
                                                <p>**Calls to 02 &amp; 03 numbers are charged at standard geographic rate included in any call plan minutes.</p> */}
                                            </div>
                                        </Collapse>

                                        <button
                                            className={`section-accordion__wrap-btn accordion-btn tickX ${collapses.collapseC === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseC', e)}>
                                            VIP PACKAGES
                                        </button>
                                        <Collapse isOpen={collapses.collapseC}
                                                  className={`section-accordion__wrap-content accordion-content tickX has-inner c ${collapses.collapseC === true ? 'active' : ''}`}>
                                            <div className="section-accordion__wrap-content-inner">
                                            <p><strong>THE AMBASSADOR LOUNGE</strong></p>
                                            <p>The Ambassador Lounge includes a special range of bespoke packages for you, giving you the opportunity to make your visit even more memorable. Please note, the Ambassador Lounge is only accessed via steps. <a href="https://www.atgtickets.com/venues/palace-theatre-manchester/your-visit/">Find out more.</a></p>
                                                {/* <p>Make your visit even more special...</p>
                                                <p>&nbsp;</p>
                                                <p><strong>THE AMBASSADOR LOUNGE</strong></p>
                                                <p>The Ambassador Lounge includes a special range of bespoke packages
                                                    for you, giving you the opportunity to make your visit even more
                                                    memorable. Please note, the Ambassador Lounge is only accessed via
                                                    steps.</p>
                                                <p>&nbsp;</p>
                                                <p>Contact the box office for more information <a
                                                    href="tel:0844 871 7615">0844 871 7615*</a>*</p>
                                                <p>&nbsp;</p>
                                                <p>*Calls cost 7p per minute, plus your phone company's access charge</p>
                                                <br />
                                                <p>ATG Access Line: 0333 009 5399</p>
                                                <p>TypeTalk / Textphone 18001 0333 009 5399*&nbsp;</p>
                                                <p>*Open 9am to 8pm Monday to Sunday, Bank Holidays may vary.</p> */}
                                            </div>
                                        </Collapse>

                                        <button
                                            className={`section-accordion__wrap-btn accordion-btn tickX ${collapses.collapseB === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseB', e)}>
                                            ACCESS PERFORMANCES
                                        </button>
                                        <Collapse isOpen={collapses.collapseB}
                                                  className={`section-accordion__wrap-content accordion-content tickX has-inner b ${collapses.collapseB === true ? 'active' : ''}`}>
                                            <div className="section-accordion__wrap-content-inner">

                                            <p>ATG Access Line</p>
                                            <p>0333 009 5399</p>
                                            <br />
                                            <p>TypeTalk / Textphone</p>
                                            <p>18001 0333 009 5399*&nbsp;</p>
                                            <p>*Open 9am to 8pm Monday to Sunday, Bank Holidays may vary.</p>
                                                {/* <p><strong>Access Booking Line</strong></p>
                                                <p>&nbsp;</p>
                                                <p><a href="tel:08009126971">0800 912 6971</a></p>
                                                <p>&nbsp;</p>
                                                <p><strong>Typetalk Number</strong></p>
                                                <p>&nbsp;</p>
                                                <p>18001 0800 912 6971</p>
                                                <p>&nbsp;</p>
                                                <p>Click <a
                                                    href="https://www.atgtickets.com/venues/manchester-playhouse/info/"
                                                    target="_blank"
                                                    rel="noreferrer noopener">here</a> for venue access information and
                                                    access detail.
                                                </p> */}
                                            </div>
                                        </Collapse>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </>
                }

                {phase === 'registration' &&
                <div className="section-sign-up">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-sign-up__wrap">
                                    <h2 className={`h3`}>SIGN UP NOW AND GET ACCESS TO THE PRESALE <br/>REGISTER NOW
                                    </h2>
                                    <SignUpForm
                                        className="sign-up-form"
                                        location="/manchester/"
                                        locationName="manchester"
                                        phase={phase}
                                        thankYouPage={true}
                                    />
                                    <p className="small-copy">
                                        By submitting your email address, you are agreeing to receive marketing
                                        emails about The Book of Mormon. You can opt out of receiving
                                        these emails at any time.
                                        <br/>To see full details of our privacy policy <Link
                                        to={`/manchester/privacy-policy/`}>click here</Link>.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }
                {phase === 'presale' &&
                <div className="section-sign-up space-below">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-sign-up__wrap orange-border">
                                    <h3>Manchester Playhouse<br/>
                                        <img src={star} className="star" alt="" title=""/> <span className="orange">DATE CHANGE</span>
                                        <img src={star} className="star" alt="" title=""/></h3>
                                    <div className="narrow">
                                        <p>&nbsp;</p>
                                        <p>Due to the ongoing Covid-19 restrictions, all performances at Manchester
                                            Playhouse (17 June - 4 July 2020) have been rescheduled to</p>
                                        <p>&nbsp;</p>
                                        <p className="big orange text-uppercase"><strong>WEDNESDAY 9 – SATURDAY 26 JUNE
                                            2021</strong></p>
                                        <p>&nbsp;</p>
                                        <p>Ticketholders for the 2020 run do not need to do anything. The theatre will
                                            contact you regarding the next steps.</p>
                                        <p>&nbsp;</p>
                                        <p>Non-ticket holders wishing to book for the 2021 run are advised to <br
                                            className="d-none d-md-inline-block"/><strong>sign up for priority
                                            booking</strong></p>
                                    </div>
                                    <SignUpForm
                                        className="sign-up-form"
                                        location="/manchester/"
                                        locationName="manchester"
                                        phase={phase}
                                        thankYouPage={true}
                                    />
                                    <p className="small-copy">
                                        By submitting your email address, you are agreeing to receive marketing
                                        emails about The Book of Mormon. You can opt out of receiving
                                        these emails at any time.
                                        <br/>To see full details of our privacy policy <Link
                                        to={`/manchester/privacy-policy/`}>click here</Link>.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }
                {phase === 'open' &&
                <div className="section-show-info">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-show-info__wrap">
                                    <div className="row">
                                        <div className="section-show-info__wrap-map">
                                            <h2 className="d-lg-none">Show Info</h2>
                                            <div className={`map manchester`}>
                                            </div>
                                        </div>

                                        <div className="section-show-info__wrap-content">
                                            <div className="content">
                                                <h2 className="d-none d-lg-block">Show Info</h2>
                                                <h3>11 September - 5 October 2024</h3>
                                                <p>PALACE THEATRE<br/>
                                                    97 Oxford Street, Manchester M1 6FT</p>

                                                <a className="btn btn-google-maps"
                                                   href="https://goo.gl/maps/CKpXgJhQgLr"
                                                   target="_blank"
                                                   rel="noreferrer noopener">
                                                    <span className="sr-only">Palace Theatre Map</span>
                                                </a>

                                                 <h3>PERFORMANCE TIMES</h3>
                                                <p><strong>Monday - Saturday 7.30pm</strong></p>
                                                <p><strong>Friday & Saturday 2.30pm (excluding Fri 13 Sep)</strong></p>

                                                <h3 className="mt-2">Running Time</h3>
                                                <p>2 hours 20 minutes, including interval</p>
                                                <br />
                                                <p><strong>Parental advisory:</strong> Explicit language.</p>
<p><strong>Please note:</strong>&nbsp;This production contains strobe lighting, flashing lights and gunshots.&nbsp;</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }
            </>
        );
    }

}

export default (props) => (
<StaticQuery
query= {
    graphql
`
        query {
            allEventsJson {
                edges {
                    node {
                        siteId
                        items {
                            url
                            start
                            title
                            className
                            active
                            type
                        }
                    }
                }
            }
        }`
}

render=
{
    data => (
        <Manchester events={data}
                    multiLang={props.multiLang}
                    phase={props.phase}
                    siteId={props.siteId}
                    language={props.language}
        />
    )
}
/>
)