import React, {Component} from "react"
import ArticlePage from 'components/Pages/ArticlePage'
import Helmet from "react-helmet";

class ArticleTemplate extends Component {
  render() {
    const data = this.props.data
    const {siteversion, languageCode} = this.props
    
    return (
      <React.Fragment>
        {/* <Helmet>
          <link rel="alternate" hrefLang={languageCode} href={`https://thebookofmormonmusical.com/${data.siteId}/`}/>
        </Helmet> */}
        
        <ArticlePage
          title={data.title}
          phase={data.phase}
          siteversion={siteversion}
          siteId={data.siteId}
          language={data.language}
          multiLang={data.multiLang}
        />
      </React.Fragment>
    )
  }
}

export default ArticleTemplate